.page-overlay {
    // defined elsewhere

    &--top {
        z-index: 4 !important;
    }
}

.alert {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 4;

    background-color: #ffff;

    -webkit-box-shadow: 1px 15px 24px -14px #42445a;
    -moz-box-shadow: 1px 15px 24px -14px #42445a;
    box-shadow: 1px 15px 24px -14px #42445a;

    border-radius: var(--panel-radius);

    height: 190px;

    @media only screen and (max-width: 950px)  {
        width: 85%;     
    }  

    @media only screen and (min-width: 951px)  {
        width: 450px;
    }  


    &__text {

        &__spacer {
            margin-left: 5%;
            margin-right: 5%;

            margin-top: 10px;
            margin-bottom: 15px;

            border-bottom: 1px solid var(--spacer-color);
            border-top: 1px solid #ffff;
        }

        &--head {
            margin-top: 15px;

            font-size: 20px;
            font-weight: 600;
        }

        span {
            margin-left: auto;
            margin-right: auto;

            width: min-content;
            white-space: nowrap;

            display: block;    
        }
    }

    &__button-section {
        display: flex;
        justify-content: center;

        margin-top: 30px;

        button {
            border-radius: var(--panel-radius);
            padding: 10px 20px;

            --yelow: #ffca43;
            border: 2px solid var(--yelow);
            background-color: var(--yelow);
            color: rgb(26, 26, 33);

            cursor: pointer;

            font-size: 18px;
            font-weight: 500;

            -webkit-user-select: none;

            &:hover {
                border: 2px solid #fac130;
                background-color: #fac130;
            }
        }
    }
}