.data-panel {
    
    &__open-test {

        &__question {

            &__question-number {
                display: flex;
                margin-top: 50px;
                margin-bottom: 30px;
    
                h4 {
                    margin: 0px;
                    margin-left: 2.5%;
    
                    font-size: 18px;
                    font-weight: 600;
    
                    position: relative;
                    
                    &.underlined {
                        &::before{
                            content: '';
                            position: absolute;
                            left: 0;
                            bottom: -10px;
                            background-color: var(--highlited-color);
                            height: 2px;
                            box-sizing: border-box;
                            width: 50px;
                        }
                    }
                }

                @media only screen and (max-width: 950px)  {
                    padding-top: 30px;
                }
            }

            &__question-data {
                margin-top: 20px;

                &__text {
                    margin: 0px 20px;
                    line-height: 22px;
                    color: #3a3a3a;
                    font-size: 16px;
                }
    
    
                &__img-wrapper {
                    margin-top: 20px;
                    display: flex;
    
                    img {
                        max-width: 535px;
                        margin-left: auto;
                        margin-right: auto;
                        width: 95%;
                        height: auto;
    
                        pointer-events: none;
                        user-select: none;
                    }
                }
            }
        }

        &__anwser {

            &__headline {
                margin-left: 2.5%;
                font-size: 18px;
                font-weight: 600;

                margin-top: 40px;
                margin-bottom: 20px;

                &.mainTest {
                    margin-left: 2.5vw;
                }
            }

            &__data {
                margin-left: 2.5%;
                margin-right: 2.5%;

                position: relative;

                min-height: 200px !important;

                &__anwser {
                    padding: 5px 5px;
                    line-height: 22px;
                    opacity: 0.45;
                    
                    &--active {
                        color: #3a3a3a;
                        opacity: 1.0;
                    }
                }

                &__blur {
                    position: absolute; 
                    top: 0; 
                    left: 0;

                    width: 100%;
                    height: 100%;

                    -webkit-backdrop-filter: blur(4px);
                    backdrop-filter: blur(4px);

                    @media only screen and (min-width: 951px)  {
                        display: flex;
                        align-items: center;
                    }  

                    &__result-button {

                        width: min-content;
                        height: min-content;
                        white-space:nowrap;
    
                        @media only screen and (min-width: 951px)  {
                            margin: auto auto;
                        }  

                        @media only screen and (max-width: 950px)  {
                            margin: 0 auto;
                            position: relative;
                        }  

                        font-size: 18px;
                        font-weight: 500;

                        border-radius: var(--panel-radius);
                        padding: 10px 20px;

                        -webkit-box-shadow: 8px 8px 16px -13px #42445a;
                        -moz-box-shadow: 8px 8px 16px -13px #42445a;
                        box-shadow: 8px 8px 16px -13px #42445a;

                        cursor: pointer;
        
                        -webkit-user-select: none; /* Safari */        
                        -moz-user-select: none; /* Firefox */
                        -ms-user-select: none; /* IE10+/Edge */
                        user-select: none; /* Standard */
        
                        &:active {
                            transform: scale(0.98);
                        }

                        &--hidden {
                            opacity: 0.0;
                        }

                        &--filled {
                            --yelow: #ffca43;
                            border: 2px solid var(--yelow);
                            background-color: var(--yelow);
                            color: rgb(26, 26, 33);

                            @media only screen and (max-width: 950px)  {
                                top: 33%;
                            }  

                            &:hover {
                                background-color: #fac130 !important;
                            }
                        }
                        
                        &--border-only {
                            border: 2px solid var(--highlited-color);
                        
                            @media only screen and (max-width: 950px)  {
                                margin: 0 auto;
                                position: relative;
                                top: 48%;
                            }  

                            &:hover {
                                border-color: #2c8894;
                            }
                        }
                    }
                }
            }

            &__opened {
                margin-left: 2.5%;
                margin-right: 2.5%;
            }
        }

        &__buttons-spacer {
            margin-left: 5.5%;
            margin-right: 5.5%;
            border-bottom: 1px solid var(--spacer-color);

            &--pre {
                margin-top: 30px;
            }

            &--post {
                margin-bottom: 0px; 
                
            }
        }

        &__nav-buttons {
            margin: 20px 7% 0px 7%;

            display: flex;
            justify-content: space-between;

            &__button {
                width: 105px;
                border-radius: var(--panel-radius);
                border: 2px solid var(--highlited-color);

                display: flex;
                justify-content: center;

                cursor: pointer;
        
                -webkit-user-select: none; /* Safari */        
                -moz-user-select: none; /* Firefox */
                -ms-user-select: none; /* IE10+/Edge */
                user-select: none; /* Standard */

                &:hover {
                    border-color: #2c8894;
                }

                &:active {
                    transform: scale(0.98);
                }


                &--inactive {
                    color: #c5c5c5 !important;
                    border-color: #d2d2d2 !important;

                    &:hover {
                        border-color: #d2d2d2 !important;
                    }

                    &:active {
                        transform: scale(1.0);
                    }
                }

                &--prev {
                    padding: 10px 20px 5px 17px;
                }

                &--next {
                    padding: 10px 14px 5px 20px;

                    .material-symbols-outlined {
                        left: 7px;
                    }
                }

                .material-symbols-outlined {
                    position: relative;
                    bottom: 3px;
                }

            }
        }
    }
}