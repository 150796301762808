.resetPassword-panel {
    &__message {

        margin-left: var(--left-margin);
        margin-top: 40px;

        font-weight: 500;
        font-size: 16px;


        &__main-text {
            margin-bottom: 30px;
            line-height: 22px;

            
            // &--good {
            //     color: #41aab8;
            // }

            // &--bad {
            //     color: #b70f0f;
            // }

        }

        &__button {
            width: 85%;

            border-radius: var(--panel-radius);
            padding: 12px 15px 12px 15px;

            margin-top: 15px;
            border: solid 1px;
            border-radius: 5px;

            cursor: pointer;
            text-align: center;

            color: white;

            -webkit-user-select: none; /* Safari */
            -ms-user-select: none; /* IE 10 and IE 11 */
            user-select: none; /* Standard syntax */

            background-color: var(--used-color);
            border-color: var(--used-color);

            &:active {
                transform: scale(0.99);
            }

            &--good {
                --used-color: #40aab8;


                &:hover {
                    --used-color: #419197;
                }

            }

            &--bad {
                --used-color: #b70f0f;

                &:hover {
                    --used-color: #a70b0b;
                }
            }
        }
    }
}