
.artificial-navbar {
    height: 72px ;
    margin-bottom: 20px;

    background-color: #ffff;

    display: flex;
    justify-content: space-between;
    width: 100%;


    &__logo-wrapper {

        width: 182px;
        cursor: pointer;
    }

    &__menu {
        display: flex;

        @media only screen and (min-width: 1696px)  {
            display: none !important;
        }

        &__wrapper {
            @media only screen and (max-width: 950px) {
                display: none !important;
            }

            &__button {
                border-radius: var(--panel-radius);
                margin: 15px 0px;
                margin-right: 20px;
                padding: 10px 20px;
                border: 2px solid #dbdbdb;
                color: #c8c8c8;
                font-weight: 500;

                cursor: not-allowed;
            }
        }

        &__language {

            span {
                color: rgb(196, 196, 196) !important;  
            }

            cursor: not-allowed !important;

            @media only screen and (max-width: 950px) {
                display: none !important;
            }
        }

        &__user-panel {

            cursor: not-allowed !important;

            span {
                color: rgb(196, 196, 196) !important;  
            }
            
            img {
                cursor: not-allowed !important;
                // filter: invert(93%) sepia(1%) saturate(14%) hue-rotate(263deg) brightness(116%) contrast(72%);
            }

            &__main-nav-button-mobile {
                span {
                    color: rgb(196, 196, 196) !important;   
                    background-color: rgb(196, 196, 196) !important;
                }
            }
        }

    }
}

#content-name {

    .preview-data-subtext {
        margin-top: 10px;
        font-size: 19px;
        font-weight: 400;
    
        line-height: 26px;
    
        b {
            color: #40aab8;
            font-weight: 600;
        }
    }

    svg {
        width: 24px;
        height: 24px;

        position: relative;
        top: 4px;
        right: 3px;

        transform: rotate(-180deg);
        margin-right: 10px;

        fill: #40aab8;
        cursor: pointer;
        user-select: none;

        @media only screen and (min-width: 951px) {
            display: none;
        }
    }

    // .material-symbols-outlined {
    //     position: relative;
    //     top: 4px;

    //     color: #40aab8;

    //     font-variation-settings:
    //     'FILL' 0,
    //     'wght' 400,
    //     'GRAD' 0,
    //     'opsz' 24;


    //     cursor: pointer;
    //     user-select: none;

    //     @media only screen and (min-width: 951px) {
    //         display: none;
    //     }
    // }
}

.preview-data-panel {
    &--inactive {
        color: #c8c8c8 !important;

        cursor: default;

        .material-symbols-outlined {
            color: rgb(196, 196, 196) !important;

            cursor: not-allowed !important;

            &:hover {

            }

            &:active {
                transform: scale(1.0) !important;
            }
        }
    }
}

.preview-data-space__data {
    --inactive-color: #c1c1c1;

    &--inactive {
        color: rgb(196, 196, 196) !important;
        cursor: not-allowed;
        

        &:hover {
            background-color: #f0f0f0 !important;
            color: #c8c8c8 !important;
        }

        .material-symbols-outlined {
            color: rgb(196, 196, 196) !important;
            position: relative;
            top: -1px;
        }

    }

}

.preview-navigation-panel {
    &__chapter {


        &__buy-course {
            margin-top: 21px;
            display: flex;
            justify-content: center;

            padding-top: 19px;
            padding-bottom: 15px;

            font-size: 18px;
            font-weight: 500;

            background-color: var(--yellow-color);

            cursor: pointer;

            &:hover {
                background-color: var(--yellow-hover-color);
            }

            &:active {
                transform: scale(0.98);
            }   


            &__price {
                margin-left: 10px;
                top: 3px;
                // position: relative;
                // font-size: 14px !important;

                &.full-price {
                    color: #3a3a3a;
                    font-size: 16px;    
                    text-decoration: line-through;                
                }

                &.discounted {

                }
            }
        }

        &__back-to-course-preview {
            margin-top: 21px;
            // margin-bottom: 21px;
            display: flex;
            justify-content: center;

            font-size: 18px;
            font-weight: 500;



            cursor: pointer;

            -webkit-box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 1);
            -moz-box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 1);
            box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 1);

            svg {
                margin-right: 10px;
                position: relative;
                top: -3px;

                fill: #3a3a3a;

                transition: fill 0.3s ease;
            }

            &--yellow {

                padding-top: 18px;
                padding-bottom: 14px;
                background-color: var(--yellow-color);
    
                &:hover {
                    background-color: var(--yellow-hover-color);
                }
    
            }

            &--normal {
                background-color: #fafafa;
                border: 1px solid #3a3a3a;

                padding-top: 17px;
                padding-bottom: 13px;

                @media only screen and (min-width: 951px)  {
                    transition: border-color 0.3s ease;
                    transition: background-color 0.3s ease;
                    transition: color 0.3s ease;
    
                    &:hover {
                        color: #ffff;
                        font-weight: 500;
    
                        border-color: var(--valid-color);
                        background-color: var(--valid-color);
    
                        svg {
                            fill: #ffff !important;   
                        }
                    }
    
                }
            }



            &:active {
                transform: scale(0.98);
            }
        }

    }
}