.checkout {

    &__stripe-box {

        &__title {
            font-weight: 600;
            font-size: 26px;

            margin-bottom: 15px;

            .material-symbols-outlined {
                font-variation-settings:
                'FILL' 0,
                'wght' 400,
                'GRAD' 0,
                'opsz' 48;

                margin-right: 10px;
                transform: scale(1.25);
                position: relative;
                top: 3px;
            }

            display: flex;
            justify-content: space-between;

            &__mobile-close-button {

                cursor: pointer;

                .material-symbols-outlined {
                    font-variation-settings:
                    'FILL' 0,
                    'wght' 400,
                    'GRAD' 0,
                    'opsz' 48;
    
                    transform: scale(1.35);
                    position: relative;
                    top: 3px;
                    left: 13px;

                    opacity: 0.2;

                    transition: opacity 0.3s ease; 
                }

                &:hover {
                    .material-symbols-outlined {
                        opacity: 1.0;
                    }
                }

                &:active {
                    transform: scale(0.95);
                }
            }
        }

        &__infomertial {
            color: #3a3a3a;
            line-height: 20px;

            margin-bottom: 20px;

            padding-bottom: 15px;
            border-bottom: 1px solid var(--spacer-color);

            padding-top: 15px;
            border-top: 1px solid var(--spacer-color);

        }

        #payment-button-wrapper {
            margin-top: 20px;
            padding-top: 20px;
            border-top: 1px solid var(--spacer-color);

        }

        #submit {

            justify-content: center;
            width: min-content;
            white-space: nowrap;

            border-radius: 5px;
            font-size: 16px;
            font-weight: 500;

            border: solid 1px;
            cursor: pointer;

            // border-color: #40aab8;
            color: white;
            background-color: #40aab8;


            @media only screen and (max-width: 950px) {
                width: 100%;
                padding-top: 15px;
                padding-bottom: 15px;
            }
        
            @media only screen and (min-width: 951px) {
                width: 80%;
                margin-left: 10%;
                padding-top: 12px;
                padding-bottom: 12px;
            }

            &:hover {
                background-color: #389ca9;
            }

            &:active {
                transform: scale(0.98);
            }
        }

        .mobile-spacer {
            @media only screen and (max-width: 950px) {
                padding-bottom: 60px;
            }
        }
    }

}
