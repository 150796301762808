.form {
    &__checkbox {
        display: flex;
        margin-bottom: 20px;
  
        .container {
          display: block;
          position: relative;
  
          padding-left: 30px;
          margin-bottom: 12px;
  
          cursor: pointer;
          font-size: 22px;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
  
          input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
          }
  
          .checkmark {
            position: absolute;
            left: 0;
    
            border-radius: 5px;
            border: 1px solid rgb(161, 161, 161);
  
            @media only screen and (min-width: 951px)  {
              top: 5px;
              height: 18px;
              width: 18px;
            }
  
            @media only screen and (max-width: 950px)  {
              top: 7px;
              height: 28px;
              width: 28px;
            }
  
            &--filled {
              background-color: #41b0be;
            }
    
            &--red {
              border-color: #b70f0f;
              background-color: #b70f0f13;
            }
          } 
  
          svg {
           position: absolute;
 
            fill: #ffff;

              @media only screen and (max-width: 950px)  {
  
                width: 24px;
                height: 24px;

                top: 11px !important;
                left: 3px !important;
              }

              @media only screen and (min-width: 951px)  {
                width: 20px;
                height: 20px;

                top: 5px;
                left: 0px;
              }
          }

        }
  
        &__text {
  
          line-height: 16px;
          font-size: 14px;
          font-weight: 400;

          @media only screen and (max-width: 950px)  {
            padding-left: 15px;
  
            font-weight: 500 !important;
            line-height: 22px;
          }

          @media only screen and (min-width: 951px)  {
            padding-right: 27px;

            // width: 302px;
          }

          b {
            color: #40aab8;
            font-weight: 500;
          }
  
          a {
            color: #40aab8;
          }
        }
      }
}