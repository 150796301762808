.shop {
    --main-margin: 5%;
    --main-radius: 17px;

    --panel-margin: 21px;

    @media only screen and (max-width: 1405px)  {
        --course-panel-width: 750px;
    }

    @media only screen and (min-width: 1406px)  {
        --course-panel-width: 1112px;
    }

    &__headline {

        background-color: #ffff;


        border-bottom: 1px solid var(--spacer-color);


        @media only screen and (max-width: 950px)  {
            // padding-top: 72px;

            padding-top: 102px;
        }
    
        @media only screen and (min-width: 951px)  {
            padding-top: 141px;
            padding-bottom: 50px;
        }

        &__panel {
      
            @media only screen and (min-width: 1209px) and (max-width: 1405px)  {
                --left-width: calc(410px + var(--panel-margin)) ;
            }


            @media only screen and (min-width: 1570px) {
                --left-width: calc(410px + var(--panel-margin)) ;
            }


            @media only screen and (max-width: 1059px)  {
                --total-panel-width: var(--course-panel-width);
                --buffer: 0px;
            }

            @media only screen and (min-width: 1060px) and (max-width: 1208px) {
                --total-panel-width: calc(100% - 2 * var(--panel-margin));
                --buffer: 7px;
            }

            @media only screen and (min-width: 1209px) and (max-width: 1405px) {
                --total-panel-width: calc(var(--left-width) + var(--course-panel-width));
                --buffer: 12px;
            }

            @media only screen and (min-width: 1406px) and (max-width: 1569px){
                --total-panel-width: calc(100% - 2 * var(--panel-margin));
                --buffer: 5px;
            }

            @media only screen and (min-width: 1570px) {
                --total-panel-width: calc(var(--left-width) + var(--course-panel-width));
                --buffer: 12px;
            }

            width: calc(var(--total-panel-width) - var(--buffer));
            margin-left: calc(( 100% - var(--total-panel-width) ) * 0.5);

            border-radius: var(--main-radius);

            text-align: center;

            font-size: 35px;
            font-weight: 600;


            padding-top: 32px;
            padding-bottom: 32px;

            color: #110e37;
            background-color: rgb(215, 232, 234);

            &__colored-text {
                // font-family: "Domine", serif;
                color: #8f2c5f;

                // font-size: 38px;
            }
        }
    }

    &__body {

        @media only screen and (max-width: 1059px)  {

        }

        @media only screen and (min-width: 1060px)  {
            display: flex;
        }

        @media only screen and (max-width: 950px)  {
             
            margin-top: 0px;
        }

        @media only screen and (min-width: 951px)  {
            margin-top: 40px;
        }


        margin-bottom: 100px;

        margin-left: auto;
        margin-right: auto;

        justify-content: center;

        &__nav-panel {


            @media only screen and (max-width: 950px)  {
             
                width: 100%;
            }

            @media only screen and (min-width: 951px)  {
                width: calc(100% - var(--panel-margin) * 2 - var(--course-panel-width));

                margin-left: var(--panel-margin);
                margin-right: var(--panel-margin);
    
            }

            @media only screen and (min-width: 951px) and (max-width: 1059px)  {
                width: var(--course-panel-width);
                margin-left: auto;
                margin-right: auto;

                display: flex;
                justify-content: space-between;

                margin-bottom: 40px;
            }

            @media only screen and (min-width: 1060px)  {
                max-width: 410px;
                min-width: 270px;
            }


            &__filters {


                height: min-content;
                
                border-radius: var(--panel-radius);
                background-color: #ffff;

                -webkit-box-shadow: 8px 8px 16px -16px #42445a;
                -moz-box-shadow: 8px 8px 16px -16px #42445a;
                box-shadow: 8px 8px 16px -16px #42445a;

                @media only screen and (max-width: 950px)  {
                    width: 100%;
                    margin-left: auto;
                    margin-right: auto;

                    margin-bottom: 40px;
                }

                @media only screen and (min-width: 951px) and (max-width: 1059px)  {
                    width: 300px;
                }

                &__headline {


                    @media only screen and (max-width: 950px)  {
                        padding: 20px 30px 12px 30px;
                    }
                
                    @media only screen and (min-width: 951px)  {
                        padding: 30px 30px 15px 30px;
                    }

                    display: flex;
                    justify-content: space-between;

                    border-bottom: 1px solid var(--spacer-color);

                    &__filters {
                        font-size: 20px;
                        font-weight: 500;
                    }

                    &__reset-filters {

                        @media only screen and (max-width: 950px)  {
                            display: none;
                        }                

                        
                        cursor: pointer;

                        color: rgb(216, 78, 78);

                        &:hover {
                            color: rgb(210, 34, 34);
                        }

                        &:active {
                            transform: scale(0.98);
                        }
        
                        &__text {
                            margin-left: 5px;
                            position: relative;
                            top: -7px;
                        }
                    }

                    &__mobile-toggle {

                        @media only screen and (min-width: 951px)  {
                            display: none;
                        }    

                        span {
                            transition: transform 0.3s ease-in-out;

                            &.active {
                                transform: rotate(-90deg);
                            }

                            &.inactive {
                                transform: rotate(90deg);
                            }
                        }   

                        cursor: pointer;

                        .material-symbols-outlined {
                            font-variation-settings:
                            'FILL' 0,
                            'wght' 400,
                            'GRAD' 0,
                            'opsz' 24;
                        }
                    }
                }

                &__sections {
                    padding-bottom: 20px;

                    &.inactive {

                    @media only screen and (max-width: 950px)  {
                        display: none;
                    }    
                    }

                    .disabled {
                        .shop__body__nav-panel__filters__sections__section__box {
                            cursor: not-allowed !important;
                        }

                        color: #b7b7b7 !important;
                    }

                    .enabled {

                    }
                    
                    &__headline {
                        margin-top: 25px;
                        margin-bottom: 20px;
                        padding-left: 30px;

                        font-weight: 500;
                        font-size: 18px;
                    }

                    &__section {
                        padding-left: 30px;
                        margin-bottom: 15px;
                        display: flex;

                        &__box {
                            width: 20px;
                            height: 20px;

                            border: 2px solid var(--spacer-color);
                            border-radius: 8px;

                            cursor: pointer;

                            transition: background-color 0.1s ease;

                            &:active {
                                transform: scale(0.95);
                            }

                            .material-symbols-outlined {
                                font-variation-settings:
                                'FILL' 0,
                                'wght' 500,
                                'GRAD' 0,
                                'opsz' 48;

                                transform: scale(0.8);
                                position: relative;
                                left: -1px;
                                top: -1px;
                                
                                color: #ffff;
                            }

                            &--active { 
                                border: 1px solid rgb(161, 161, 161);
                                width: 22px;
                                height: 22px;

                                background-color: #40aab8;

                            }
                        }

                        &__text { 
                            position: relative;
                            top: 3px;

                            margin-left: 20px;
                        }
                    }

                }
            }

            &__bundles-info { 

                @media only screen and (max-width: 950px)  {
                    display: none;
                }                

                @media only screen and (min-width: 951px) and (max-width: 1059px)  {
                    width: calc(100% - 300px - 20px);

                    height: 316px;
                }
        
                @media only screen and (min-width: 1060px)  {
                    margin-top: 29px;
                    width: 100%;        

                    padding-top: 30px;
                    padding-bottom: 30px;

                    //test
                    height: 298px;
                }


                border-radius: var(--panel-radius);
                background-color: #0d0d2b;

                -webkit-box-shadow: 8px 8px 16px -16px #42445a;
                -moz-box-shadow: 8px 8px 16px -16px #42445a;
                box-shadow: 8px 8px 16px -16px #42445a;

                // display: flex;
                justify-content: center;
                text-align: center;

                span {
                    padding-top: 180px;
                }

                &__text-section {
                    color: #ffff;
                    font-size: 24px;
                    line-height: 35px;

                    @media only screen and (min-width: 1140px) and (max-width: 1405px)  {
                        font-size: 30px;
                        line-height: 40px;
                    }


                    @media only screen and (min-width: 1500px) {
                        font-size: 30px;
                        line-height: 40px;
                    }
                    
                    font-weight: 600;

                    padding-bottom: 25px;
                    padding-left: 20px;
                    padding-right: 20px;


                    @media only screen and (max-width: 950px)  {
                        padding-top: 20px;
                    }  

                    @media only screen and (min-width: 1060px)  {

                    }

                    @media only screen and (min-width: 951px) and (max-width: 1059px)  {
                        padding-top: 20px;
                        padding-bottom: 20px;
                    }

                    span {

                        &.colored {
                            color: #fb50a3;
                        }
                    }
                }

                &__img-section {
                    display: flex;
                    justify-content: center;
                    width: 100%;

                    height: 200px;
                    img {
                        opacity: 0.95;

                        @media only screen and (max-width: 950px)  {

                            width: 70%;
                            height: auto;
                            padding-bottom: 20px;
    
                            max-width: 280px;
                        }    

                        @media only screen and (min-width: 951px)   {

                            max-width: 300px;
                            width: 80%;
                            height: 100%;
                            object-fit: contain;
                        }
                

                    }
                }
            }

            &__new-courses-info {

                background-color: #0d0d2b;

                @media only screen and (max-width: 1059px)  {
                    display: none;
                }
        
                @media only screen and (min-width: 1060px)  {
                    margin-top: 29px;
                    height: 191px;
                    width: 100%;        
                }

                border-radius: var(--panel-radius);

                -webkit-box-shadow: 8px 8px 16px -16px #42445a;
                -moz-box-shadow: 8px 8px 16px -16px #42445a;
                box-shadow: 8px 8px 16px -16px #42445a;

                display: flex;
                justify-content: center;
                text-align: center;

                span {
                    padding-top: 100px;
                }

                position: relative;

                &__text {
                    color: #ffff;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);

                    z-index: 1;

                    color: #ffff;
                    font-size: 26px;
                    font-weight: 600;
                    line-height: 35px;

                    width: 70%;
                }

                &__img-section {
                    width: 100%;

                    display: flex;
                    justify-content: center;
    
                    img {
                        width: 90%;
                        height: 90%;
    
                        opacity: 0.7;
                    }

                }
            }
        }

        &__courses {

            @media only screen and (max-width: 950px)  {

            }
        
            @media only screen and (min-width: 951px)  {
                width: var(--course-panel-width);
            }

            @media only screen and (max-width: 1059px)  {
                margin-left: auto;
                margin-right: auto;
            }
    
            @media only screen and (min-width: 1060px)  {
                margin-right: var(--panel-margin);
            }

            
            .filler {
                @media only screen and (min-width: 951px)  {
                    width: 352px;
                    height: 419px;
                }
            }

            &__grid {
                         
                @media only screen and (min-width: 951px)  {
                    display: grid;
                    grid-template-rows: repeat(2, auto);               
                }

                @media only screen and (max-width: 1059px)  {
                    grid-gap: 20px 47px;
                }
        
                @media only screen and (min-width: 1060px)  {
                    grid-gap: 20px 5px;        
                }

                @media only screen and (max-width: 1405px)  {
                    grid-template-columns: repeat(2, auto); 
                }
            
                @media only screen and (min-width: 1406px)  {
                    grid-template-columns: repeat(3, auto); 
                }

                .selling-course-panel {

                    @media only screen and (max-width: 950px)  {
                        margin-bottom: 15px;
                    }
                
                    @media only screen and (min-width: 951px)  {
                        margin-right: 0px !important;
                    }
                }

                .selling-course-panel__valid-time {
                    display: none;
                }
            }

            &__navigation {

                --nav-margin: 30px;

                margin-top: 30px;

                padding-top: 30px;

                border-top: 1px solid var(--spacer-color);

                display: flex;
                justify-content: space-between;

                @media only screen and (max-width: 950px)  {
                    margin-left: var(--main-margin);
                    margin-right: var(--main-margin);
                }
            
                @media only screen and (min-width: 951px)  {
                    padding-left: 3%;
                    padding-right: 3%;
                    margin-left: var(--nav-margin);
                    margin-right: var(--nav-margin);
                }

                &__button {
                    border: 2px solid var(--spacer-color);
                    border-radius: var(--panel-radius);

                    cursor: pointer;
                    
                    &__text {
                        position: relative;


                        @media only screen and (min-width: 951px)  {
                            top: -7px;                    
                        }


                        &--desktop {
                            @media only screen and (max-width: 950px)  {
                                display: none;
                            }
                        }

                        &--mobile {
                            @media only screen and (min-width: 951px)  {
                                display: none;
                            }
                        }
                    }

                    &--active {
                        border-color: #40aab8;


                        &:hover {
                            border-color: #35929e;
                        }

                        &:active {
                            transform: scale(0.98);
                        }
                    }

                    &--left {

                        @media only screen and (max-width: 950px)  {
                            padding: 10px 15px 10px 15px;
                        }
                        
                        @media only screen and (min-width: 951px)  {
                            padding: 10px 20px 5px 17px;
                        }

                        .shop__body__courses__navigation__button__text {
                            margin-left: 3px;
                        }
                    }

                    &--right {

                        @media only screen and (max-width: 950px)  {
                            padding: 10px 15px 10px 15px;
                        }
                    
                        @media only screen and (min-width: 951px)  {
                            padding: 10px 12px 5px 20px;   
                        }

                        .shop__body__courses__navigation__button__text {
                            margin-right: 7px;
                        }
                    }

                    .material-symbols-outlined {
                        @media only screen and (max-width: 950px)  {
                            display: none;
                        }
                    }
                }

                &__pages-points {
                    display: flex;
                    position: relative;
                    top: 9px;

                    &__point {

                        padding: 5px;
                        font-size: 18px;
                    }
                }

            }
        }

        &__new-courses-info-mobile {
            background-color: #ffff;
            margin-left: auto;
            margin-right: auto;

            border-radius: var(--panel-radius);

            height: 180px;

            -webkit-box-shadow: 8px 8px 16px -16px #42445a;
            -moz-box-shadow: 8px 8px 16px -16px #42445a;
            box-shadow: 8px 8px 16px -16px #42445a;

            position: relative;
            background-color: #0d0d2b;

            &__text {
                color: #ffff;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);

                text-align: center;

                z-index: 1;

                color: #ffff;
                font-size: 26px;
                font-weight: 600;
                line-height: 35px;

                width: 70%;
            }

            &__img-section {
                width: 100%;
                height: 100%;

                display: flex;
                justify-content: center;

                img {
                    width: 90%;
                    height: 90%;

                    opacity: 0.7;
                }
            }

            @media only screen and (max-width: 950px)  {
                margin-left: 21px;
                margin-right: 21px;

                margin-top: 50px;
            }


            @media only screen and (min-width: 951px) and (max-width: 1059px)  {
                margin-top: 60px;

                width: var(--course-panel-width) ;


            }
    
            @media only screen and (min-width: 1060px)  {
                display: none;
            }
        }
    }
}