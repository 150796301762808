.main-page {


    &__cookie-panel {


        &__background {
            width: 100%;
            height: 100%;

            z-index: 4;

            // position: absolute;
            position: fixed;;

            left: 0;
            top: 0;
            background-color: rgba(0, 0, 0, 0.405);
            cursor: default;
            -webkit-backdrop-filter: blur(2px);
        }

        &__panel {

            @media only screen and (max-width: 950px)  {
                width: 95vw;

                bottom: calc(0dvh + 10px);
                left: 50%;
                transform: translate(-50%, 0dvh);
            }
            
            @media only screen and (min-width: 951px)  {
                --panel-height: 315px;
                width: 600px;
                height: var(--panel-height);
                top: calc(50vh - 0.5 * var(--panel-height));
            }
    
            border-radius: var(--panel-radius);
            background-color: #ffff;
            z-index: 5;
    
            position: fixed;
            left: 0;
            right: 0;
            margin: 0 auto;
    
            -webkit-box-shadow: 6px 6px 24px -4px rgba(66, 68, 90, 1);
            -moz-box-shadow: 6px 6px 24px -4px rgba(66, 68, 90, 1);
            box-shadow: 6px 6px 24px -4px rgba(66, 68, 90, 1);
    
            &__headline {
                // justify-content: center;
                text-align: center;
                font-size: 20px;
                font-weight: 600;

                padding-top: 30px;

                @media only screen and (max-width: 950px)  {
                    padding-left: 25px;
                    padding-right: 25px;

                    margin-bottom: 10px;
                }

                    .mobile-hidden {
                        @media only screen and (max-width: 950px)  {
                            display: none;
                        }
                    }
    
                    .desktop-hidden {
                        @media only screen and (min-width: 951px)  {
                            display: none;
                        }
                    }
                
                
            
            }

            &__spacer {
                height: 1px;
                width: 200px;

                background-color: var(--spacer-color);

                margin-top: 15px;
                margin-bottom: 15px;

                margin-left: auto;
                margin-right: auto;

                @media only screen and (max-width: 950px)  {
                    display: none;
                }
            }

            &__text {
                padding-left: 25px;
                padding-right: 25px;

                line-height: 19px;

                text-align: center;

                .mobile-hidden {
                    @media only screen and (max-width: 950px)  {
                        display: none;
                    }
                }

                .desktop-hidden {
                    @media only screen and (min-width: 951px)  {
                        display: none;
                    }
                }

                b {
                    font-weight: 600;
                    // color: rgb(46, 146, 159);
                }
            }

            &__buttons {

                padding-left: 30px;
                padding-right: 30px;

                margin-top: 30px;


                @media only screen and (max-width: 950px)  {
                    padding-bottom: 30px;
                }
                
                @media only screen and (min-width: 951px)  {
                    display: flex;
                    justify-content: center;

                    width: calc(100% - 60px );
                }
        

                &__button {
            

                    user-select: none;
                    cursor: pointer;

                    // border-radius: var(--panel-radius);
                    border-radius: 15px;

                    @media only screen and (max-width: 950px)  {
                        width: calc(100% - 4px);

                        text-align: center;
                    }
                    
                    @media only screen and (min-width: 951px)  {
                        padding-top: 15px;
                        padding-bottom: 5px;
                        padding-left: 20px;
                        padding-right: 20px;
                        border: 2px solid #ffff;
                    }

                    font-size: 16px;
                    font-weight: 500;
                    
                    transition: transform 0.3s ease-in-out;

                    &:hover {
                        transform: scale(1.02);
                    }

                    &--full {
                        border: 2px solid var(--yellow-color);
                        background-color: var(--yellow-color);

                        &:hover {
                            background-color: #ffbe1a !important;
                            border-color: #ffbe1a !important;
                        }


                        @media only screen and (max-width: 950px)  {
                            padding-top: 12px;
                            padding-bottom: 11px;   
                        }

                        @media only screen and (min-width: 951px)  {
    
                        }
                    }

                    &--empty {
                        
                        border: 2px solid rgb(26, 26, 33);

                        display: flex;
                        span {
                            display: block;
                        }


                        @media only screen and (max-width: 950px)  {
                            justify-content: center;

                            padding-top: 13px;
                            padding-bottom: 7px;   

                            margin-bottom: 20px;
                        }
                        
                        @media only screen and (min-width: 951px)  {
                            margin-right: 40px;
                            padding-right: 15px;
    
                        }

                        svg  {
                            margin-left: 5px;

                            position: relative;
                            
                            transform: scale(0.75);
                            top: -3px;                            
                        }

                    }
                }


            }


            &__settings {
                text-align: center;


                @media only screen and (max-width: 950px)  {
                    // margin-top: 30px;
                    // margin-bottom: 20px;

                    display: none;
                }
                
                @media only screen and (min-width: 951px)  {
                    margin-top: 38px;

                    // padding-bottom: 20px;
                }

                opacity: 0.4;

                &:hover {
                    opacity: 0.5;
                }

                cursor: pointer;

                .material-symbols-outlined {
                    position: relative;
                    margin-left: 7px;
                    top: 5px;

                    font-variation-settings:
                    'FILL' 0,
                    'wght' 300,
                    'GRAD' 0,
                    'opsz' 24;

                    font-size: 20px;
                }
            }
        }

        &__advanced-settings-panel {

            @media only screen and (max-width: 950px)  {
                width: 100%;

                border-radius: 0px;
                background-color: #ffff;

                height: 100vh;
            

                top: 0;
                left: 0;
                position: fixed;
            }

            @media only screen and (min-width: 951px)  {
                width: 800px;
             
                max-height: 90vh;


                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);

                position: fixed;
            }
    
            border-radius: var(--panel-radius);
            z-index: 5;
    


            background-color: #ffff;
    
            -webkit-box-shadow: 6px 6px 24px -4px rgba(66, 68, 90, 1);
            -moz-box-shadow: 6px 6px 24px -4px rgba(66, 68, 90, 1);
            box-shadow: 6px 6px 24px -4px rgba(66, 68, 90, 1);
    
            &__headline {
                position: relative;
                display: flex;

                border-bottom: 1px solid var(--spacer-color);
                
                &__spacer {
                    width: 1px;
                    height: 55px;

                    background-color: var(--spacer-color);
                }

                &__button {
                    width: 50%;

                    cursor: pointer;
                    user-select: none;


                    text-align: center;


                    @media only screen and (max-width: 950px)  {
                        font-size: 15px;

                        display:table;

                        padding-left: 10px;
                        padding-right: 10px;

                        span {
                            display:table-cell;
                            vertical-align:middle;
                        }

                    }
        
                    @media only screen and (min-width: 951px)  {
                        font-size: 18px;
                        padding-top: 12px;
                    }

                    &--left {
                        @media only screen and (min-width: 951px)  {
                            border-top-left-radius: var(--panel-radius);
                        }
                    }  

                    &--right {
                        @media only screen and (min-width: 951px)  {
                            border-top-right-radius: var(--panel-radius);
                        }
                     
                    }

                    &--active {
                        background-color: var(--yellow-color);
                        font-weight: 500;
                    }

                    &--inactive {

                        font-weight: 400;
                        background-color: #ffff;
                        // border-bottom: 2px solid rgb(26, 26, 33);
                    }


                    .material-symbols-outlined {
                        font-variation-settings:
                        'FILL' 0,
                        'wght' 400,
                        'GRAD' 0,
                        'opsz' 24;

                        position: relative;
                        margin-left: 10px;
                        transform: scale(0.9);
                        top: 5px;

                        @media only screen and (max-width: 950px)  {
                            display: none;
                        }
                    }

                }
            }

            &__data {

                margin-top: 0px;
                padding-top: 0px;

                background-color: #f0eeee;
            
                border-bottom: 1px solid var(--spacer-color);

                padding-top: 25px;
                padding-left: 25px;
                padding-right: 25px;


                @media only screen and (max-width: 950px)  {
                    // max-height: calc(100vh - 56px);
                    height: calc(100dvh - 158px);
                }

                @media only screen and (min-width: 951px)  {
                    max-height: calc(80vh - 142px);
                }
                
                overflow-y: auto;

                &__sections {
                    
                    &__section {

                        padding-bottom: 20px;

                        margin-bottom: 30px;
                        border-bottom: 1px solid #d8d8d8;


                        h3 {
                            margin-top: 0px;
                            font-size: 16px;
                            margin-bottom: 10px;
                        }

                        span {
                            font-size: 15px;
                        }

                        &__description {


                            @media only screen and (max-width: 950px)  {
                                position: relative;
                            }

                            @media only screen and (min-width: 951px)  {
                                position: relative;
                                display: flex;
                            }

                            &__text {

                                @media only screen and (min-width: 951px)  {
                                    padding-right: 20px;
                                    margin-right: 25px;
    
                                    border-right: 1px solid #d8d8d8;
                                }
                            }

                            &__toggle {


                                @media only screen and (max-width: 950px)  {
                                    margin-top: 15px;
                                }
    
                                @media only screen and (min-width: 951px)  {
                                    display: flex;
                                    justify-content: center;
                                    align-items:center;
                                }

                                input {

                                    
                                    outline: none;
                                    height: 25px;
                                    width: 50px;
                                    position: relative;
                                    top: 1px;
                                    margin: 0px;
                
                                    border-radius: calc( var(--panel-radius) - 3px);
                
                                    -webkit-appearance: none;
                                    

                                    box-shadow: inset 0 0 3px rgba(0,0,0,0.2);
                
                                    cursor: pointer;
                                    transition: 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
                
                                    &.active {
                                        &:checked{
                                            background: var(--highlited-color);
                    
                                        }
                                    }

                                    &.inactive {
                                        background-color: #d8d8d8;
                                    }


                                    &:checked{

                                        &:before{
                                            left: 23px;
                                        }
                                    }
                
                                    &:before{
                                        position: absolute;
                                        content: "";
                                        left: 0;
                                        height: 100%;
                                        width: 27px;
                
                                        box-shadow: 1px 1px 4px rgba(0,0,0,0.2);
                                        border-radius: calc( var(--panel-radius) - 3px);
                                        
                                        background: #fcfcfc;
                                        
                                        transform: scale(0.80);
                                        transition: left 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
                                    }
                                }    
                            }
                        }

                        &__toggle {
                            margin-top: 15px;

                            span {
                                font-size: 15px;
                                font-weight: 600;
                            }

                            cursor: pointer;

                            .material-symbols-outlined {
                                position: relative;

                                margin-left: 8px;

                                font-variation-settings:
                                'FILL' 0,
                                'wght' 600,
                                'GRAD' 0,
                                'opsz' 24;

                                &.active {
                                    transform: rotate(-90deg);
                                    top: 2px;                                
                                }


                                &.inactive {
                                    transform: rotate(90deg);
                                    top: 3px;
                                }
                            }

                        }

                        .array-wrapper {
                            @media only screen and (max-width: 950px)  {
                                display: flex;
                                flex-wrap: no-wrap;
                                overflow-x: scroll;
                                // width: 750px;
                            }
                        }

                        &__array {



                            @media only screen and (max-width: 950px)  {
                                width: 750px;
                            }

                            @media only screen and (min-width: 951px)  {
                                width: 100%;
                            }

                            margin-top: 15px;
                            margin-left: 0px;                            
                            text-align: left;

                            border: 1px solid #d8d8d8;
                            border-radius: 15px; 

                            .headline {

                                padding-left: 15px;
                                padding-top: 10px;
                                padding-bottom: 7px;



                                border-bottom: 1px solid #c7c7c7;

                                background-color: #d8d8d8;

                                border-top-left-radius: 15px;
                                border-top-right-radius: 15px;


                                position: relative;
                                display: flex;


                                font-size: 15px;
                                font-weight: 500;

                                &__name {

                                    &.w1 {
                                        width: 105px;
                                    }

                                    &.w2 {
                                        width: 105px;
                                    }

                                    &.w3 {
                                        width: 105px;
                                    }

                                    &.w4 {
                                        width: 115px;
                                    }

                                    &.w5 {

                                    }
                                
                                }

                            }

                            .array-row {
                                display: flex;


                                padding-left: 15px;

                                padding-top: 6px;
                                padding-bottom: 6px;

                                font-size: 14px;

                                &--dark {
                                    background-color: #e3e3e3;

                                }

                                &--light {

                                }

                                &--last {
                                    border-bottom-left-radius: 15px;
                                    border-bottom-right-radius: 15px;
                                }

                                &__name {

                                    &.w1 {
                                        width: 105px;
                                    }

                                    &.w2 {
                                        width: 105px;
                                    }

                                    &.w3 {
                                        width: 105px;
                                    }

                                    &.w4 {
                                        width: 115px;
                                    }

                                    &.w5 {

                                    }
                                }
                            }
                        }

                    }

                }

                &__about-cookies {

                    margin-bottom: 25px;

                    span {
                        display: block;

                        margin-bottom: 15px;

                        font-size: 15px;
                    }
                }
            }

            &__buttons {
                position: relative;
                display: flex;

                padding-left: 30px;
                padding-right: 30px;


                @media only screen and (max-width: 950px)  {
                    padding-left: 20px;
                    padding-right: 20px;
                }

                @media only screen and (min-width: 951px)  {
                    padding-left: 30px;
                    padding-right: 30px;
                }



                margin-top: 20px;
                margin-bottom: 20px;

                justify-content: space-between;

                &__left-group {

                    position: relative;
                    display: flex;

                    &__button {
                        
                        &.mobile-hidden {
                            @media only screen and (max-width: 950px)  {
                                display: none;
                            }                            
                        }

                        @media only screen and (max-width: 950px)  {
                            font-size: 15px;
                            text-align: center;

                            padding-top: 10px;
                            padding-bottom: 6px;
                            padding-left: 16px;
                            padding-right: 16px;
                        }
        
                        @media only screen and (min-width: 951px)  {
                            font-size: 16px;

                            padding-top: 14px;
                            padding-bottom: 10px;
                            padding-left: 20px;
                            padding-right: 20px;
                        }

                        border: 2px solid rgb(26, 26, 33);

                        user-select: none;
                        cursor: pointer;

    
                        // border-radius: var(--panel-radius);
                        border-radius: 15px;
    
                        font-weight: 500;
                        
                        transition: transform 0.3s ease-in-out;
    
                        &:hover {
                            transform: scale(1.02);
                        }

                        margin-right: 20px;
                    }

                }

                &__button {

                    @media only screen and (max-width: 950px)  {
                        font-size: 15px;
                        text-align: center;


                        padding-top: 10px;
                        padding-bottom: 6px;
                        padding-left: 16px;
                        padding-right: 16px;
                    }
    
                    @media only screen and (min-width: 951px)  {
                        font-size: 16px;

                        padding-top: 14px;
                        padding-bottom: 10px;
                        padding-left: 20px;
                        padding-right: 20px;
                    }

                    border: 2px solid var(--yellow-color);
                    background-color: var(--yellow-color);

                    user-select: none;
                    cursor: pointer;

                    // border-radius: var(--panel-radius);
                    border-radius: 15px;


                    font-weight: 500;
                    
                    transition: transform 0.3s ease-in-out;

                    &:hover {
                        transform: scale(1.02);
                    }

                    &:hover {
                        background-color: #ffbe1a !important;
                        border-color: #ffbe1a !important;
                    }
                }
            }
        }

    }

    &__cookie {
        position: fixed;
    
        background-color: #ffff;
    

        @media only screen and (max-width: 950px)  {
            bottom: 12px;
            left: 12px;
        }
    

        @media only screen and (min-width: 951px)  {
            bottom: 20px;
            left: 20px;
        }
    
        z-index: 3;
    
    
        width: 50px;
        height: 50px;
        border-radius: 35px;
    
    
        -webkit-box-shadow: 8px 8px 24px -12px rgba(66, 68, 90, 1);
        -moz-box-shadow: 8px 8px 24px -12px rgba(66, 68, 90, 1);
        box-shadow: 8px 8px 24px -12px rgba(66, 68, 90, 1);
    
        cursor: pointer;
    
        transition: transform 0.2s ease-in-out;
    
        &:hover {
          transform: scale(1.05);
        } 
    
        &:hover + .main-page__cookie-text {
    
          opacity: 1;
        }
    
        background-color: #bcdfe2;

        svg {
            fill: #ffff;
            position: relative;
            top: 8px;
            left: 7px;
        }
      }
    
      &__cookie-text {
        position: fixed;
    
        background-color: #ffff;
    
        bottom: 22px;
        left: 90px;
    
        background-color: #ffff;
    
        padding-left: 15px;
        padding-right: 15px;
    
        padding-top: 13px;
        padding-bottom: 11px;
    
        border-radius: 15px;
    
        font-size: 17px;
    
        z-index: 3;
    
        opacity: 0;
    
        -webkit-box-shadow: 1px 1px 10px -4px rgba(66, 68, 90, 1);
        -moz-box-shadow: 1px 1px 10px -4px rgba(66, 68, 90, 1);
        box-shadow: 1px 1px 10px -4px rgba(66, 68, 90, 1);
    
        cursor: pointer;
    
        transition: opacity 0.2s ease-in-out;
     
        @media only screen and (max-width: 950px) {
          display: none;
        }
    
      }
}