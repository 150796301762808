body {
    width: 100%;
}


.html-not-scrollable {
    position: static !important;
    // overflow-x: visible !important;
    overflow: hidden !important;
}

.body-not-scrollable {
    position: relative;
    margin: 0 !important;
    height: 100% !important;
    overflow: hidden !important;
}

#wide-menu {    
    min-height: 200px;
    width: 420px;

    @media only screen and (max-width: 1695px) {
        display: none;
    }
}

.content-name-preview-lesson {
    @media only screen and (max-width: 950px)  {
        margin-top: 50px !important;
    }
}

#content-name {
    font-size: 24px;
    font-weight: 500;

    div {
        &.skeleton {
            display: inline-block;
            height: 24px;
            width: 30%;
            margin-left: auto;
        }
    }
    

    @media only screen and (max-width: 950px)  {
        width: 90%;

        margin-left: auto;
        margin-right: auto;

        // margin-top: 50px;
        margin-top: 122px;
        margin-bottom: 20px;

    }

    @media only screen and (min-width: 951px)  {
        padding-left: calc( (100% - 420px) * 0.025);
        padding-top: 20px;
        padding-bottom: 20px;

        margin-right: 21px;
        margin-bottom: 21px;
        border-bottom: 1px solid rgb(229, 229, 229);
    }

    @media only screen and (min-width: 1320px) and (max-width: 1361px) {
        margin-left: calc((100% - 1320px) * 0.5);
        padding-left: calc( (100% - (378px + 42px + 850px) ) * 0.5);
    }

    @media only screen and (min-width: 1362px) and (max-width: 1695px) {
        margin-left: 21px;
        padding-left: calc( (100% - (378px + 42px + 850px) ) * 0.5 );
    }

    @media only screen and (min-width: 1696px) {
        margin-left: 21px;
        padding-left: calc( (50% - (378px + 21px + 425px)));
    }
}

#content-panel {
    margin-bottom: 20px;

    @media only screen and (min-width: 951px)  {
        display: flex;
        justify-content: center;
    }

    #data-panel-wrapper {
        width: 100%;

        // 382
        // 420
        @media only screen and (min-width: 951px)  {
            max-width: 850px;
        }

        &.main-test {
            @media only screen and (min-width: 951px)  {
                max-width: 1270px !important;
            }
        }

        #data-panel {
            min-height: 200px;
            width: 100%;
            background-color: rgb(255, 255, 255);

            padding-bottom: 50px;

            @media only screen and (max-width: 950px)  {
                margin-bottom: 20px;

                -webkit-box-shadow: 0px 8px 23px -20px rgba(66, 68, 90, 1);
                -moz-box-shadow: 0px 8px 23px -20px rgba(66, 68, 90, 1);
                box-shadow: 0px 8px 23px -20px rgba(66, 68, 90, 1);
            }

            @media only screen and (min-width: 951px)  {
                max-width: 850;
                
                border-top-right-radius: var(--panel-radius);
                border-bottom-right-radius: var(--panel-radius);   


                -webkit-box-shadow: 8px 8px 16px -16px rgba(66, 68, 90, 1);
                -moz-box-shadow: 8px 8px 16px -16px rgba(66, 68, 90, 1);
                box-shadow: 8px 8px 16px -16px rgba(66, 68, 90, 1);
            }

            @media only screen and (min-width: 1275px)  {
                border-radius: var(--panel-radius);
                // border-top-left-radius: var(--panel-radius);
                // border-top-right-radius: var(--panel-radius);
            }

            &__nav {
                color: #3a3a3a;

                line-height: 24px;

                margin-left: auto;
                margin-right: auto;
        
                padding: 12px 2.5% 8px 2.5%;
        
                border-bottom: var(--spacer-width) solid var(--spacer-color);

                -webkit-user-select: none; /* Safari */
                -ms-user-select: none; /* IE 10 and IE 11 */
                user-select: none; /* Standard syntax */

                cursor: default;

                .material-symbols-outlined {
                    position: relative;
                    top: 3px;
                    padding: 0px 4px;
                }

                svg {
                    width: 16px;
                    height: 16px;
                
                    position: relative;
                    top: -4px;
                    padding: 0px 4px;

                    fill: #3a3a3a;
                }

                @media only screen and (max-width: 600px)  {
                    display: none;
                }

                // @media only screen and (min-width: 1695px) {
                //     display: none;
                // }

                span {
                    position: relative;
                    bottom: 4px;
                }

                &__gray-text {
                    color: rgb(207, 212, 213);
                }

                .skeleton {
                    position: relative !important;
                    top: 5px !important;
                }
            }

            &__wide-nav {
                @media only screen and (max-width: 1694px) {
                    display: none
                }

                @media only screen and (min-width: 1695px) {
                    margin-left: auto;
                    margin-right: auto;
                    justify-content: center;
                    border-bottom: var(--spacer-width) solid var(--spacer-color);

                    font-size: 18px;
                    font-weight: 600;
                    text-align: center;
                    padding: 15px 0px;

                    -webkit-user-select: none; /* Safari */
                    -ms-user-select: none; /* IE 10 and IE 11 */
                    user-select: none; /* Standard syntax */

                    cursor: default;
                }
            }
        
            &__title {
                font-size: 18px;
                font-weight: 600;
                padding-left: calc(2.5%);

                display: flex;
                span {
                    display: block;
                }
            
                .material-symbols-outlined {
                    font-variation-settings:
                    'FILL' 0,
                    'wght' 400,
                    'GRAD' 0,
                    'opsz' 48;

                    position: relative;
                    bottom: 1px;
                    
                    margin: auto 5px auto 0px;

                    color: var(--highlited-color);

                    cursor: pointer;
    
                    -webkit-user-select: none; /* Safari */        
                    -moz-user-select: none; /* Firefox */
                    -ms-user-select: none; /* IE10+/Edge */
                    user-select: none; /* Standard */
            
                    &:hover {
                        color: var(--button-border-only-hover-color);
                    }

                    &:active {
                        transform: scale(0.98);
                    }
                }

                &__text {
                    line-height: 23px;
                }

                @media only screen and (max-width: 600px)  {
                    padding-top: 20px;
                    padding-bottom: 20px;
                    border-bottom: var(--spacer-width) solid var(--spacer-color);
                }
    
                @media only screen and (min-width: 601px)  {
                    margin-top: 40px;
                }

                @media only screen and (min-width: 1696px) {
                    // margin-top: 0px;
                    // padding-top: 50px;
                    // font-size: 20px;

                    // .material-symbols-outlined {
                    //     font-variation-settings:
                    //     'FILL' 0,
                    //     'wght' 500,
                    //     'GRAD' 0,
                    //     'opsz' 48;
                    // }

                    display: none;
                }
            }
        
            &__description-title {
                font-weight: 600;

                padding-left: calc(2.5%);
                margin-bottom: 25px;
            }
        
            &__description {
                color: #3a3a3a;
                line-height: 22px;
                width: 95%;
                margin-left: auto;
                margin-right: auto;
        
                @media only screen and (max-width: 950px)  {
                    padding-bottom: 40px;
                }

                div {
                    &.skeleton {
                        display: inline-block;
                        width: 100%;
                        height: 16px;
                    }
                }
            }
        }
    }
    
    #navigation-panel {
        @media only screen and (min-width: 951px)  {
            min-width: 420px;
        }
    }

    .navigation-panel {
        min-height: 200px;
    
        @media only screen and (min-width: 1198px) {
            min-width: 420px;
        }

        &__scalable {
            @media only screen and (min-width: 951px) and (max-width: 1197px)  {
                min-width: 325px;
            }
        }

        &__lesson-additions-scalable {
            @media only screen and (min-width: 951px) and (max-width: 1050px)  {
                min-width: 382px !important;
            }
        }
    }  
}

#bottom-separator {
    padding-top: 20px;
    padding-bottom: 60px;

    margin-right: 21px;
    margin-top: 21px;

    @media only screen and (min-width: 951px)  {
        border-top: 1px solid rgb(229, 229, 229);
    }

    @media only screen and (min-width: 1320px) and (max-width: 1361px) {
        margin-left: calc((100% - 1320px) * 0.5);
    }

    @media only screen and (min-width: 1362px) {
        margin-left: 21px;
    }
}

.chapter {
    margin-left: auto;
    margin-right: auto;

    -webkit-box-shadow: 8px 8px 16px -16px rgba(66, 68, 90, 1);
    -moz-box-shadow: 8px 8px 16px -16px rgba(66, 68, 90, 1);
    box-shadow: 8px 8px 16px -16px rgba(66, 68, 90, 1);

    width: 90%;
    border-radius: var(--panel-radius);
    background-color: rgb(255, 255, 255);


    @media only screen and (max-width: 950px)  {
        margin-top: 39px;
    }

    &--main {
        margin-bottom: 21px; 
    }

    &--additional-space {
        margin-top: 21px;

        padding-bottom: 15px;
    }

    &__small-name {
     
        text-align: center;
        padding-top: 10px;
        padding-bottom: 10px;

        font-size: 18px;
    }


    &__row {
        display: flex;

        padding-left: 10px;
        padding-right: 10px;

        justify-content: space-between;

        border-top: var(--spacer-width) solid var(--spacer-color);
        // border-bottom: var(--spacer-width) solid var(--spacer-color);
        
        margin-bottom: 15px;
        padding-top: 10px;

        &__box {
            // padding-left: 20px;
            // padding-right: 20px;

            cursor: pointer;

            width: calc(33%);

            padding-top: 13px;
            padding-bottom: 13px;

            border-radius: var(--panel-radius);

            &:hover {
                background-color: var(--highlited-light-color);

            }

            span {
                display: block;

                text-align: center;

                &.material-symbols-outlined {
                    margin-bottom: 10px;

                    
                }
            }
        }
    }


    &__bottom-spacer {
        margin-left: 20px;
        margin-right: 20px;

        margin-bottom: 15px;

        border-top: var(--spacer-width) solid var(--spacer-color);
    }

    &__data-space {
        border-top: var(--spacer-width) solid var(--spacer-color);
        padding: 10px 0px; 
        
        &--no-padding {
            padding: 0px;
        }
        
        &__wrapper {
            padding: 0px 20px;
            padding-bottom: 13px;
            margin: 0px 10px;
            
            border-radius: var(--panel-radius);

            cursor: pointer;
        
            -webkit-user-select: none; /* Safari */        
            -moz-user-select: none; /* Firefox */
            -ms-user-select: none; /* IE10+/Edge */
            user-select: none; /* Standard */

            &:hover {
                background: var(--highlited-light-color);
                color: #1a1a21;
            }

            &--highlited {
                border-radius: var(--panel-radius);
                background-color: var(--highlited-color);
                color: #ffffff;

                .chapter__data-space__params__parameter  {
                    color: #ffffff !important; 
                }

                &:hover {
                    background-color: var(--highlited-color);
                    color: #ffffff;

                    .chapter__data-space__data {
                        background-color: var(--highlited-color);
                        color: #ffffff;
                    }
                }

                .material-symbols-outlined {
                    position: relative;
                    top: 14px;
                }
            }

            &--active {
                background-color: var(--highlited-color);
            }

            &--wide {
                margin-top: 5px;
                margin-bottom: 5px;
            }
            
            .chapter__data-space__data {
                padding-left: 0px;
                padding-right: 0px;
                margin-left: 0px;
                margin-right: 0px;

                div {
                    &.skeleton {
                        display: inline-block;
                        height: 22px;
                        width: 30%;
                    }
                }
            }

        }

        &__params {
            display: flex;

            &--hidden {
                display: none;
            }

            &__parameter {
                font-size: 14px;
                color: #3a3a3a;

                margin-left: 30px;

                &--left {
                    margin-left: 35px;
                    margin-right: 10px;
                }

                span {
                    color: #6a6a6a;    
                }

            }
        }

        &__course-params {
            padding-top: 5px;

            display: flex;
            justify-content: space-between;

            margin-left: 35px;
            margin-right: 10px;

            font-size: 14px;
            color: #3a3a3a;

            position: relative;

            bottom: 3px;
        
            &__time, &__lessons {
                // border-top: 1px solid #efefef;
                // padding-top: 5px;
                div {
                    &.skeleton {
                        display: inline-block;
                        height: 14px;
                    }
                }
            }

        }

        &__data {
            position: relative;
            display: flex;
            padding: 0px 20px; 
            padding-top: 18px;

            @media only screen and (max-width: 950px) {
                padding-bottom: 18px;
            }

            @media only screen and (min-width: 951px) {
                padding-bottom: 8px;              
            }

            margin: 0px 10px;

            border-radius: var(--panel-radius);

            cursor: pointer;
        
            -webkit-user-select: none; /* Safari */        
            -moz-user-select: none; /* Firefox */
            -ms-user-select: none; /* IE10+/Edge */
            user-select: none; /* Standard */

            &.skeleton {
                margin-bottom: 1px;
            }

            &:hover {
                background: var(--highlited-light-color);
                color: #1a1a21;
            }

            .text {
                &--artifically-positioned {
                    position: absolute;
                    top: 38px;

                    @media only screen and (min-width: 1051px)  {
                        left: 72px;
                    }

                    @media only screen and (max-width: 1050px)  {
                        left: 38px;
                    }
                }
            }

            &--go-back-button {
                    padding-bottom: 8px !important;
            }

            &--extended {
                padding-bottom: 28px !important;
                
                
                .icon {
                    top: 6px;
                }
                .stamp {
                    position: relative;
                    top: 11px;

                }
            }

            &--stamp-icon {
            
                padding-top: 18px !important;
                padding-bottom: 8px !important;

                // margin: 0px 10px;
            }

            &--highlited {
                background-color: var(--highlited-color) !important;
                color: #ffffff !important;
            }

            &--lite-highlight {
                background-color: var(--highlited-light-color);
                color: #1a1a21;
            }

            &--iconless {
                padding-bottom: 18px;
            }

            &--chapter {
                padding-bottom: 0px;
            }


            &--equal-mobile-padding {
                @media only screen and (max-width: 950px) {
                    padding-top: 18px !important;
                    padding-bottom: 18px !important;
                }
            }

            &--clickable {
                &:hover {
                    background-color: var(--button-full-hover-color) !important;
                    color: #eeeeee;

                    .icon {
                        color: #eeeeee;
                    }

                    &.skeleton {
                        background-color: transparent;
                        background-color: transparent;                
                    }
                }

                &:active {
                    transform: scale(0.99);
                }
            }

            &--additional-responsive {

                @media only screen and (min-width: 951px) and (max-width: 1050px)  {
                    height: 28px;
                }

                .stamp {
                    @media only screen and (max-width: 380px) {
                       display: none !important;
                    }
                }
            }

            &--not-clickable {
                cursor: default;
                
                &:hover {
                    background: #ffffff;
                    color: #1a1a21;
                }
            }

            &--disabled {
                cursor: default;

                
                color: #bfbfbf !important;
                
                &:hover {
                    background: #ffffff;
                }
            }

            &--wide-panel {
                margin: 0px;
                padding: 18px 30px 8px 30px;

                @media only screen and (max-width: 950px) {
                    padding-bottom: 18px !important;
                }
    
                @media only screen and (min-width: 951px) {
                    padding-bottom: 8px !important;              
                }

                border-radius: 0px;

                &:hover {
                    background-color: var(--highlited-light-color);
                    border-left: 1px solid var(--spacer-color);
                }

                .text {
                    @media only screen and (max-width: 950px) {
                        padding-right: 10px;

                        // height: 18px;
                        height: auto;
                    }
                }

                .icon {
                    @media only screen and (max-width: 950px) {
                        height: 18px;
                    }
                }
            }

            &--inactive {
                color: rgb(196, 196, 196) !important;
                cursor: not-allowed !important;

                .material-symbols-outlined {
                    color: rgb(196, 196, 196) !important;
                }

                &:hover {
                    background-color: #f0f0f0 !important;
                    color: #c8c8c8 !important;

                    .material-symbols-outlined {
                        background-color: #f0f0f0 !important;
                    }
                }
            }

            .icon {
                width: min-content;
                margin-right: 10px;

                position: relative;
                bottom: 3px;

                &--centered {
                    top: 11px; // from 9 px
                    right: 5px;
                }

                &--centered-arrow {
                    left: 4px;

                    display: inline !important;
                }

                &--reversed {
                    transform: scale(1.2) rotate(180deg) !important;
                    bottom: 7px;
                    right: 1px;
                }

                &--scaled {
                    transform: scale(1.2);
                }

                &--bold {
                    margin-top: 0px;
                    margin-right: 0px;

                    .material-symbols-outlined {
                        font-variation-settings:
                        'FILL' 0,
                        'wght' 400,
                        'GRAD' 0,
                        'opsz' 48;
                    }
                }

                &--spacer {
                    padding-right: 10px;
                    margin-right: 10px !important;
                }

                &--artificially-centered {
                    left: 3px;
                }

                &--additional-responsive {
                    @media only screen and (max-width: 1050px)  {
                        display: none;
                    }
                }
            }

            .text {
                width: 100%;

                &--bold {
                    font-weight: 500;
                }

                &.skeleton__main-navigation {
                    height: 14px;
                    display: inline-block;
                    color: transparent;
                    background-color: transparent;
                }
            }

            .stamp {
                width: min-content;

                &--hidden {
                    display: none;
                }

                svg {
                    width: 25px;
                    height: 25px;

                    position: relative;
                    left: 2px;
                    top: -3px;
                }

                .opened-eye {
                   
                }

                &--active {
                    svg {
                        filter: brightness(0) invert(1);
                    }
                }
            }

        }

        &__opened-data {
            background-color: #fafafa; 
            border-left: 1px solid var(--spacer-color);
            padding-bottom: 20px;

            .chapter__data-space__data { 
                &:hover {
                    cursor: default;
                    color: #1a1a21;
                    background-color: #fafafa;
                }

            }

            &__lessons-list {
                margin: 0px 10px 0px 33px;
                padding: 0px 10px 0px 10px;
        
                border-left: 2px solid var(--highlited-color);
    
                &__lesson {
                    display: flex;
                    justify-content: space-between;
                    padding: 18px 10px 18px 20px;
                    border-radius: var(--panel-radius);

                    cursor: pointer;
        
                    -webkit-user-select: none; /* Safari */        
                    -moz-user-select: none; /* Firefox */
                    -ms-user-select: none; /* IE10+/Edge */
                    user-select: none; /* Standard */
        
                    &:hover {
                        background: var(--highlited-light-color);
                        color: #1a1a21;
                    }
    
                    &--active {
                        padding: 18px 10px 10px 20px;
                        color: #ffffff !important;
                        background-color: var(--highlited-color) !important;
                    }
    
                    .material-symbols-outlined {
                        position: relative;
                        bottom: 3px;
                    }

                    .icon {
                        &--scaled {
                            transform: scale(1.2);
                        }
        
                        &--bold {
                            margin-top: 0px;
                            margin-right: 0px;
        
                            .material-symbols-outlined {
                                font-variation-settings:
                                'FILL' 0,
                                'wght' 400,
                                'GRAD' 0,
                                'opsz' 48;
                            }
                        }
                    }
                }
            }
        }


        &__spacer {
            width: 100%;
            border-bottom: 1px solid var(--spacer-color);
        }

        &__FAQ-section {
            padding-top: 8px;
            padding-bottom: 8px;

            margin: 0px 10px;

            &__text {
                color: #3a3a3a;
                margin-bottom: 20px;
                line-height: 22px;

                padding: 15px 10px 0px 20px;
            }

            &__buttons {
                display: flex;

                justify-content: center;

                &__button {
                    height: min-content;
                    margin: 15px 20px;
                    padding: 10px 20px;

                    border-radius: var(--panel-radius);
                    border: 2px solid var(--highlited-color);

                    cursor: pointer;
        
                    -webkit-user-select: none; /* Safari */        
                    -moz-user-select: none; /* Firefox */
                    -ms-user-select: none; /* IE10+/Edge */
                    user-select: none; /* Standard */
    
                    &:active {
                        transform: scale(0.98);
                    }
                
                    &--left {                 
                        background-color: var(--highlited-color);
                        color:#ffffff;

                        &:hover {
                            border-color: var(--button-full-hover-color);
                            background-color: var(--button-full-hover-color);
                        }
                    }

                    &--right {
                        &:hover {
                            border-color: var(--button-border-only-hover-color);
                        }
                    }
                }
            }
        }
    }
}