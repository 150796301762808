.data-panel {
    
    &__notes {


        @media only screen and (max-width: 950px)  {
            // width: 100% !important;
        }

        @media only screen and (min-width: 951px) and (max-width: 1050px)  {
            max-width: calc(100vw - 382px) !important;
        }

        @media only screen and (min-width: 1051px) and (max-width: 1270px) {
            max-width: calc(100vw - 420px) !important;
        }

        @media only screen and (min-width: 1271px) {
            max-width: 850px !important;
        }


        &__button {
            margin-top: 5px;
            margin-left: 20px;

            border-radius: 12px;

            font-size: 16px;

            border: 1px solid var(--yellow-color);
            background-color: var(--yellow-color);

            padding-bottom: 9px;
            padding-left: 12px;

            padding-right: 10px;

            font-weight: 500;

            cursor: pointer;

            &:hover {
                background-color: rgb(255, 194, 41);
                border: 1px solid rgb(255, 194, 41);
            }

            .material-symbols-outlined {
                position: relative;

                top: 7px;
                padding-left: 5px;
            

                font-weight: 500;

                font-variation-settings:
                'FILL' 0,
                'wght' 400,
                'GRAD' 0,
                'opsz' 24
                

            }
        }
        

        &__fullscreen {

            &__panel {

                @media only screen and (max-width: 950px)  {
                    width: 100%;
                }

                @media only screen and (min-width: 950px)  {
                    width: 900px;
                }

                height: 100vh;
                
                background-color: #ffff;

                z-index: 5;

                position: fixed;
                top: 0; 
                left: 0; 
                right: 0; 
                margin-inline: auto; 

                &__menu {

                    &__back {

                        margin-top: 30px;
                        margin-left: 23px;

                        font-size: 20px;
                        font-weight: 600;

                        cursor: pointer;

                        .material-symbols-outlined {

                            cursor: pointer;
                            font-variation-settings:
                            'FILL' 0,
                            'wght' 400,
                            'GRAD' 0,
                            'opsz' 24;

                            position: relative; 
                            top: 6px;

                            color: #2c8894;
                        }
                    }
                }
            }

            &__background {
                width: 100%;
                height: 100%;
    
                z-index: 4;
    
                // position: absolute;
                position: fixed;;
    
                left: 0;
                top: 0;
                background-color: rgba(0, 0, 0, 0.505);
                cursor: default;
                -webkit-backdrop-filter: blur(3px);
    
            }
        }

        &__headline {
            display: flex;
            margin-top: 50px;
            margin-bottom: 30px;

            h4 {
                margin: 0px;
                margin-left: 2.5%;

                font-size: 18px;
                font-weight: 600;

                position: relative;

                &::before{
                    content: '';
                    position: absolute;
                    left: 0;
                    bottom: -10px;
                    background-color: var(--highlited-color);
                    height: 2px;
                    box-sizing: border-box;
                    width: 20%;
                }

                @media only screen and (max-width: 950px)  {
                    padding-top: 30px;
                }
            }
        }

        &__container-spacer {
            margin-left: 2.5%;
          
            height: 10px;
            position: relative;

            @media only screen and (max-width: 950px)  {
                margin-right: 2.5%;
            }

            @media only screen and (min-width: 951px)  {
                margin-right: calc(2.5% + 35px);

                &:after {
                    content: '';
                    height: 100px; 
                    width: 1px;
                    right: 0;
                    position: absolute;                
                    background-color: var(--spacer-color);
                }
            }            

            &--top {
                border-bottom: 1px solid var(--spacer-color);
                margin-top: 20px;

                &::after {
                    top: 10px;
                }
            }

            &--marginless {
                margin-top: 10px !important;
            }

            &--bottom {
                border-top: 1px solid var(--spacer-color);
                margin-bottom: 15px;

                &::after {
                    top: -100px;
                }
            }

        }

        &__container {
            margin: 0px 2.5%;
            border-left: 1px solid var(--spacer-color);
            overflow-y: scroll;

            position: relative;

            @media only screen and (max-width: 950px)  {
                border-right: 1px solid var(--spacer-color);
                height: 450px;

                &--fullscreen {
                    height: calc(100vh - 82px) !important;
                }
            }

            @media only screen and (min-width: 951px)  {

                height: 600px;

                &--fullscreen {
                    height: calc(100vh - 82px) !important;
                }

                &::-webkit-scrollbar {
                    width: 15px;
                }
            
                &::-webkit-scrollbar-track {
                    background-color: var(--highlited-light-color);
                    box-shadow: inset 0 0 5px var(--spacer-color); 
                    border-radius: var(--panel-radius);
    
                    margin-block: 15px;
                }
                
                &::-webkit-scrollbar-thumb {
    
                    background: var(--highlited-color); 
                    border-radius: 10px;
    
                    &:hover {
                        background: var(--highlited-color); 
                    }
                }

                // for no webkit scrollbars
                scrollbar-color: var(--highlited-color) var(--highlited-light-color);
                scrollbar-width: auto;
            }


            &__data {
                h1 {
                    margin: 0px 20px 0px 20px;
                }

                h2 {
                    margin: 0px 20px 0px 20px;
                }

                h3 {
                    margin: 0px 20px 0px 20px;
                }

                h4 {
                    margin: 0px 20px 0px 20px;
                }

                margin-right: 20px;

                @media only screen and (min-width: 951px)  {
                    border-right: 1px solid var(--spacer-color);
                }

                padding-top: 20px;
                padding-bottom: 20px;

                -webkit-user-select: none;  /* Chrome all / Safari all */
                -moz-user-select: none;     /* Firefox all */
                -ms-user-select: none;      /* IE 10+ */
                user-select: none;          /* Likely future */     


                &__text {
                    margin: 0px 20px;
                    line-height: 22px;
                    color: #3a3a3a;
                    font-size: 16px;
                    text-align: justify;

                }
    
                &__img-wrapper {
                    margin-top: 25px;
                    margin-bottom: 25px;
                    display: flex;
    
                    img {
                        border-radius: 15px;
                        max-width: 535px;
                        margin-left: auto;
                        margin-right: auto;
                        width: 95%;
                        height: auto;
    
                        pointer-events: none;
                        user-select: none;
                    }
                }
            }

        }

        &__download {
            margin-left: 2.5%;
            margin-right: 2.5%;

            &__headline {            
                font-weight: 600;
                color: #1a1a21;;

                user-select: none;

                .material-symbols-outlined {
                    

                    font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 48;
                    position: relative;
        

                    transition: top 0.3s ease-in-out, transform 0.3s ease-in-out;


                    &.inactive {
                        top: 0px;
                        transform: rotate(-90deg);
                    }

                    &.active {
                        top: 12px;
                        transform: rotate(90deg);
                    }

                    margin-right: 10px;

                    cursor: pointer;
                }

                &--big {
                    margin-top: 30px;
                    margin-bottom: 25px;

                    font-size: 18px;
                }
            }

            &__text {
                margin-left: 34px;

                margin-bottom: 25px;
                line-height: 22px;

                color: #c0c0c0;
            }

            &__button {
                width: min-content;
                white-space: nowrap;
                
                margin-top: 25px;
                margin-bottom: 25px;
                padding: 1px 20px 9px 20px;

                border-radius: var(--panel-radius);
                border: 2px solid var(--highlited-color);

                .material-symbols-outlined {
                    position: relative;
                    top: 5px;
                    right: 5px;
                }
            }

            &__every-note {
                color: #3a3a3a;

                @media only screen and (min-width: 951px)  {
                    margin-bottom: 40px;
                }

                &__headline {
                    font-weight: 600;
                    color: #1a1a21;
                    margin-bottom: 25px;
                }
                
                &__link {
                    font-weight: 600;
                    // color: var(--highlited-color);

                    color: #c0c0c0;

                    cursor: pointer;
        
                    -webkit-user-select: none; /* Safari */        
                    -moz-user-select: none; /* Firefox */
                    -ms-user-select: none; /* IE10+/Edge */
                    user-select: none; /* Standard */
    
                    &:hover {
                        // color: #2c8894;
                    }
                }
            }

            &__row {
                justify-content: space-between;
                padding-top: 12px; 
                padding-bottom: 8px;

                 color: #c0c0c0;

                &--mobile-hidden {
                    display: none;
                }

                margin-left: 34px;

                
                display: flex;
                border-right: 1px solid var(--spacer-color);
                border-left: 2px solid #c0c0c0;

                &__description {
                    // color: #3a3a3a;
                    color: #c0c0c0;
                    line-height: 22px;

                    padding-right: 12.5%;
                    padding-left: 15px;
                    width: 100%;
                }

                &__button {
                    display: flex;
                    justify-content: center;

                    position: relative; // REFACTORIZED
                    top: 2px; // REFACTORIZED

                    white-space: nowrap;
                                        
                    @media only screen and (max-width: 950px)  {
                        margin-right: 30px;
                    }
                    
                    @media only screen and (min-width: 951px)  {
                        margin-right: 4.5%;
                    }

                    margin-top: auto;
                    margin-bottom: auto;

                    font-weight: 600;

                    cursor: pointer;
    
                    -webkit-user-select: none; /* Safari */        
                    -moz-user-select: none; /* Firefox */
                    -ms-user-select: none; /* IE10+/Edge */
                    user-select: none; /* Standard */

                    &:hover {
                        // border-color: #2c8894;
                    }
    
                    &:active {
                        // transform: scale(0.98);
                    }

                    .material-symbols-outlined {
                        position: relative;
                        bottom: 4px;
                        left: 15px;                            
                        font-weight: 600;

                        // color: var(--highlited-color);

                        color: #c0c0c0;

                        font-variation-settings:
                        'FILL' 0,
                        'wght' 500,
                        'GRAD' 0,
                        'opsz' 48;
                    }
                }
            }

            &__spacer {
                margin: 10px 2.5% 0px 5%;
                border-bottom: 1px solid var(--spacer-color);
            }
        }

        &__buttons {
            margin-left: 7%;
            margin-right: 7%;
            margin-top: 20px;
            justify-content: space-between;
            display: flex;

            @media only screen and (max-width: 950px)  {
                display: none;
            }

            &__button {
                border-radius: var(--panel-radius);
                border: 2px solid var(--highlited-color);

                width: 162px;
                display: flex;
                justify-content: center;

                cursor: pointer;
        
                -webkit-user-select: none; /* Safari */        
                -moz-user-select: none; /* Firefox */
                -ms-user-select: none; /* IE10+/Edge */
                user-select: none; /* Standard */

                &:hover {
                    border-color: #2c8894;
                }

                &:active {
                    transform: scale(0.98);
                }


                .material-symbols-outlined {
                    position: relative;
                    bottom: 3px;
                }

                &--prev {
                    padding: 10px 20px 5px 17px;
                }

                &--next {
                    padding: 10px 14px 5px 20px;
                
                    .material-symbols-outlined {
                        left: 7px;
                    }
                }

            }
        }
    }
}