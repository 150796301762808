.main-page {

    &__messanger-text {
        position: fixed;
        transition: background-color 0.3s ease-in-out;
    
        bottom: 100px;
        right: 27px;
    
        z-index: 3;
    
        background-color: #ffff;
    
        padding-left: 15px;
        padding-right: 15px;
    
        padding-top: 10px;
        padding-bottom: 10px;
    
        border-radius: 15px;
    
        font-size: 17px;
    
        -webkit-box-shadow: 1px 1px 10px -4px rgba(66, 68, 90, 1);
        -moz-box-shadow: 1px 1px 10px -4px rgba(66, 68, 90, 1);
        box-shadow: 1px 1px 10px -4px rgba(66, 68, 90, 1);
    
    
    
        animation: fadeIn 1s;
        animation-delay: 3s;
        animation-fill-mode: forwards;
    
        opacity: 0;
    
        @media only screen and (max-width: 950px) {
          display: none;
        }
    
        &--inactive {
          animation: fadeOut 1s;
          animation-fill-mode: forwards;
    
        }
    
        @keyframes fadeOut {
          from { opacity: 1; }
          to { opacity: 0; }
        }
    
        @keyframes fadeIn {
          from { opacity: 0; }
          to { opacity: 1; }
    
          0% { transform: translate(1px, 1px) rotate(0deg); }
          10% { transform: translate(-1px, -2px) rotate(-1deg); }
          20% { transform: translate(-3px, 0px) rotate(1deg); }
          30% { transform: translate(3px, 2px) rotate(0deg); }
          40% { transform: translate(1px, -1px) rotate(1deg); }
          50% { transform: translate(-1px, 2px) rotate(-1deg); }
        }
      }
    
      &__messenger {
    
        position: fixed;
    
        // background-color: rgb(191, 212, 215);
    
        background-color: #ffbe1a;
    
        @media only screen and (max-width: 950px)  {
          bottom: 12px;
          right: 15px;

          width: 60px;
          height: 60px;
        }
    

        @media only screen and (min-width: 951px)  {
          bottom: 20px;
          right: 25px;

          width: 65px;
          height: 65px;
        }

   
        z-index: 3;
    
    
        border-radius: 35px;
    
    
        -webkit-box-shadow: 8px 8px 24px -12px rgba(66, 68, 90, 1);
        -moz-box-shadow: 8px 8px 24px -12px rgba(66, 68, 90, 1);
        box-shadow: 8px 8px 24px -12px rgba(66, 68, 90, 1);
    
        cursor: pointer;
    
        transition: transform 0.2s ease-in-out;
    
        &:hover {
          transform: scale(1.05);
        }
    
        &:hover + .main-page__messanger-text {
          background-color: #f3db9f;
    
          opacity: 1;
    
    
          animation: shake 0.8s;
          animation-iteration-count: 1;
        }
    
        @keyframes shake {
          0% { transform: translate(1px, 1px) rotate(0deg); }
          10% { transform: translate(-1px, -2px) rotate(-1deg); }
          20% { transform: translate(-3px, 0px) rotate(1deg); }
          30% { transform: translate(3px, 2px) rotate(0deg); }
          40% { transform: translate(1px, -1px) rotate(1deg); }
          50% { transform: translate(-1px, 2px) rotate(-1deg); }
        }
    
        svg {
          z-index: 4;
          position: relative;
    
          color: #1a1a21;
    
          top: -102px;
          left: -101px;
    
          transform: scale(0.16);

          @media only screen and (max-width: 950px)  {
            top: -104px;
            left: -104px;
      
            transform: scale(0.14);
          }
      
  
          @media only screen and (min-width: 951px)  {
            top: -102px;
            left: -101px;
      
            transform: scale(0.16);
          }
  
        }
    
      }

}