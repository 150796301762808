.course-preview__main-section__left-panel__text-section__about-author {
    &__author {

        @media only screen and (max-width: 950px)  {
        }
    
        @media only screen and (min-width: 951px)  {
            display: flex;
        }

        margin-bottom: 30px;

        &__img-wrapper {
            position: relative;

            // img {
            //     width: 90px;
            //     height: 150px;
        
            //     position: absolute;
            //     top: 0;
            //     bottom: 0;
            //     margin: auto;
            //     border-radius: 10px;

            //     border: 2px solid var(--spacer-color);
            // }
            
            img {

                width: 85px;
                height: 85px;

                border-radius: 90px;

                border: 2px solid var(--spacer-color);
            

                @media only screen and (max-width: 950px)  {
                
                    margin-bottom: 10px;
                }
            
                @media only screen and (min-width: 951px)  {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                }
            }

        }

        &__description {

            position: relative;;

            @media only screen and (max-width: 950px)  {
            }
        
            @media only screen and (min-width: 951px)  {
                margin-left: 110px;
            }


            h2 {
                position: relative;
                margin-bottom: 25px;

                &:before {
                    content: "";
                    position: absolute;
                    left: 0;
                    bottom: -7px;
                    background-color: var(--yellow-color);
                    height: 2px;
                    box-sizing: border-box;
                    width: 60px;
                }                               
            }

            span {
                font-weight: 400;
            }

            &__text {
                display: block;
                margin-bottom: 10px;
            }

            &__bullet-section {
        
                display: flex;
                
                margin-bottom: 3px;

                &__bullet {
                    position: relative;
                    top: 3px;     
                    border: 3px solid var(--yellow-color);

                    height: 6px;
                    width: 6px;
                    border-radius: 50px;
                }

                span {
                    color: #3a3a3a;
                    font-size: 15px;
                    display: block;

                    margin-left: 10px;
                }
            }
        }
    }
}