.FAQ-section__filter {
    border-radius: var(--panel-radius);
    border: 1px solid var(--spacer-color);
    padding: 5px 5px;

    margin: 80px auto 60px auto;

    width: min-content;

    display: flex;

    @media only screen and (max-width: 950px) {
      margin-left: 5%;
      margin-right: 5%;
      width: 90%;
    }

    .material-symbols-outlined {
      position: relative;
      top: 10px;
      margin-left: 10px;

      color: #747474;
    }

    input {
      margin-left: 10px;
      margin-right: 20px;


      @media only screen and (max-width: 950px) {
        width: calc(100% - 70px);
      }

      border: none;
      outline: none;

      height: auto;

      font-size: 18px;
      color: 1a1a21;
    }

    button {
      --yellow-colour: #ffca43;
      background-color: var(--yellow-colour);
      border: none;

      border-radius: var(--panel-radius);

      font-size: 16px;
      font-weight: 500;
      padding: 12px 22px 12px 22px;

      color: rgb(26, 26, 33);

      cursor: pointer;
    
      &:hover {
        background-color: #fac130 !important;
        border-color: #fac130 !important;
      }

      &:active {
        transform: scale(0.98);
      }

    }
}