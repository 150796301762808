.FAQ-section__container__list {
    &__item {
      border-bottom: 1px solid var(--spacer-color);

      button[list-expanded="true"] + .FAQ-section__container__list__item__content {
        opacity: 1;
        max-height: fit-content;
        transition: all 200ms linear;
        will-change: opacity, max-height;
      }

      button {
        position: relative;
        display: block;
        text-align: left;
        width: 100%;
        padding: 1em 0;
        color: #484848;
        font-size: 18px;
        font-weight: 400;
        border: none;
        background: none;
        outline: none;


        .list-title {
          padding: 1em 1.5em 1em 0;

          @media only screen and (max-width: 950px) {
            display: block;
            padding: 0px 40px 0px 0px;
          }

        }

        .icon {
          @media only screen and (max-width: 950px) {
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto 0;
          }

          display: inline-block;
          position: absolute;
          top: 18px;
          right: 0;
          width: 22px;
          height: 22px;
          border: 1px solid;
          border-radius: 22px;

          &::before {
            display: block;
            position: absolute;
            content: "";
            top: 9px;
            left: 5px;
            width: 10px;
            height: 2px;
            background: currentColor;
          }

          &::after {
            display: block;
            position: absolute;
            content: "";
            top: 5px;
            left: 9px;
            width: 2px;
            height: 10px;
            background: currentColor;
          }
        }

        &:hover {
          cursor: pointer;
          color: var(--highlited-color) !important;

          .icon {
            color: var(--highlited-color) !important;
          }
        }
      }

      button[list-expanded="true"] {
        border-bottom: 1px solid #d5d5d5;

        color: var(--highlited-color);

        .icon::after {
          width: 0;
        }
      }

      &__content {
          opacity: 0;
          max-height: 0;
          overflow: hidden;
          transition: opacity 200ms linear, max-height 200ms linear;
          will-change: opacity, max-height;

          @media only screen and (max-width: 950px) {
            width: calc(100% - 30px);
          }
    
          p {
            font-size: 16px;
            font-weight: 300;
            line-height: 22px;
            color: #3a3a3a;
            margin: 2em 0;

          }
        }
    }
  }