.main-page {

    &__how-to-ask-question {

        &__background {
            width: 100%;
            height: 100%;

            z-index: 4;

            // position: absolute;
            position: fixed;;

            left: 0;
            top: 0;
            background-color: rgba(0, 0, 0, 0.405);
            cursor: default;
            -webkit-backdrop-filter: blur(2px);
        }

        &__panel {
            @media only screen and (max-width: 950px)  {
                width: 95vw;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }
            
            @media only screen and (min-width: 951px)  {
                --panel-height: 260px;
                width: 600px;
                height: var(--panel-height);
                top: calc(50vh - 0.5 * var(--panel-height));
            }

            border-radius: var(--panel-radius);
            background-color: #ffff;
            z-index: 5;

            position: fixed;
            left: 0;
            right: 0;
            margin: 0 auto;

            -webkit-box-shadow: 6px 6px 24px -4px rgba(66, 68, 90, 1);
            -moz-box-shadow: 6px 6px 24px -4px rgba(66, 68, 90, 1);
            box-shadow: 6px 6px 24px -4px rgba(66, 68, 90, 1);

            h3 {
                margin-top: 30px;
                width: 100%;
                text-align: center;

            }

            &__text-1, &__text-2 {
                padding-left: 15px;
                padding-right: 15px;
                display: block;
            }

            &__email {
                display: block;
                text-align: center;

                margin-top: 15px;
                margin-bottom: 15px;
                font-size: 16px;
                font-weight: 550;
            }

            &__button-wrapper {
                display: flex;
                justify-content: center;

                button {

                    padding: 10px 17px 10px 17px;

                    background-color: var(--yellow-color);
                    
                    color: #1a1a1a;

                    font-size: 16px;
                    font-weight: 500;
                    border: 0px;

                    border-radius: var(--panel-radius);

                    margin-top: 20px;
                    margin-bottom: 30px;

                    cursor: pointer;

                    &:hover {
                        background-color: var(--yellow-hover-color);
                    }
                }
            }
        }
    }

    &__consultations-panel {
        margin-top: 70px;

        max-width: 1300px;

        @media only screen and (max-width: 950px)  {
            margin-left: 20px;
            margin-right: 20px;
            margin-bottom: 190px;
        }

        @media only screen and (min-width: 951px) and (max-width: 1475px) {
            display: flex;
            margin-left: 5%;
            margin-right: 5%;
            margin-bottom: 110px;
        }

        @media only screen and (min-width: 1476px)  {
            display: flex;
            margin-left: auto;
            margin-right: auto;
            width: 1300px;
        }


        &__top-section {

            @media only screen and (min-width: 951px)  {
                margin-right: 45px;
                
                border-radius: var(--panel-radius);

                // background-color: #ffff;
            }

            &__color-wrapper {
                @media only screen and (min-width: 951px)  {

                    border-radius: var(--panel-radius);

                    padding-top: 15px;
                    padding-bottom: 5px;
                    padding-right: 25px;

                    padding-left: 25px;
                    background-color: rgb(215, 232, 234);
                
                    border-bottom:2px solid rgb(169, 197, 200);

                    -webkit-box-shadow: 8px 8px 24px -18px rgba(66, 68, 90, 1);
                    -moz-box-shadow: 8px 8px 24px -18px rgba(66, 68, 90, 1);
                    box-shadow: 8px 8px 24px -18px rgba(66, 68, 90, 1);
    
                }
            }

            @media only screen and (max-width: 950px)  {

            }
            

            h3 {
                font-size: 37px;
                font-weight: 500;

                margin-bottom: 30px;


                b {
                    @media only screen and (max-width: 950px)  {
                        display: none;
                    }
                }
                

                @media only screen and (max-width: 950px)  {
                    text-align: center;
                }

                @media only screen and (min-width: 951px)  {
                    text-align: left;

                    padding-right: 25px;

                    padding-left: 25px;
                }
            }

            &__text {
                text-align: center;
                font-size: 20px;
                line-height: 28px;

                b {
                    color: #8f2c5f;
                    font-weight: 500;
                    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.17);
                }
            }
        }

        &__chat {

            @media only screen and (max-width: 950px)  {
                margin-top: 25px;
            }

            @media only screen and (min-width: 951px)  {
                margin-top: 55px;
            }

            &__window {

                border-radius: 20px;
                border: 1px solid;
                position: relative;

                margin-bottom: 20px;

                -webkit-box-shadow: 8px 8px 24px -18px rgba(66, 68, 90, 1);
                -moz-box-shadow: 8px 8px 24px -18px rgba(66, 68, 90, 1);
                box-shadow: 8px 8px 24px -18px rgba(66, 68, 90, 1);


                transform: scale(0);
                opacity: 0;
               
                &.visible {
                    transform: scale(1);
                    opacity: 1;
                }

                &.displayed-first {
                    transition: transform 0.4s cubic-bezier(0.68, -0.55, 0.27, 1.55), opacity 0.4s ease;
                }

                &.displayed-second {
                    transition: transform 0.7s cubic-bezier(0.68, -0.55, 0.27, 1.55), opacity 0.7s ease;
                }

                &__text {

                    position: absolute;
                    top: 50px;
                    left: 0px;
                    
                    font-size: 14px;

                    color: #b4b4b4;

                    svg {
                        position: relative;
                        top: 3px;
                        margin-left: 5px;

                        width: 15px;
                        height: 15px;

                        fill: #b4b4b4;
                    }
                }

                &--left {

                    padding: 9px 15px 9px 15px;
                    // left: -30px;

                    width: 210px;
                    text-align: right;

                    border-color: #2a818d;
                    background-color: #31919e;
                    color: #ffff;
                }

                &--right {

                    border-color: #d2d2d2;
                    background-color: #e0e0e0;
                    margin-top: 40px;


                    position: absolute;


                    @media only screen and (max-width: 950px)  {
                        right: 20px;
                    }

                    @media only screen and (min-width: 951px) and (max-width: 1475px)  {
                        right: 5%;
                    }

                    @media only screen and (min-width: 1476px)  {
                        position: relative;
                        right: -475px;
                    }

                    svg {

                        position: absolute;
                        top: -30px;
                        left: 185px;

                        width: 25px;
                        height: 25px;
                    }

                    padding: 12px 15px 12px 15px;
                    width: 180px;
                    text-align: left;

                }
            }
        }

        button {


            // margin-top: 150px;
            margin-bottom: 20px;

            font-size: 16px;

            border: 1px solid var(--spacer-color);
            padding: 4px 15px 4px 15px;
            background-color: #ffff;

            color: #1a1a1a;
  

            display: flex;

            border-radius: var(--panel-radius);
            cursor: pointer;

            &.mobile {
                @media only screen and (min-width: 951px)  {
                    display: none;
                }

                margin-left: auto;
                margin-right: auto;
            }

            &.desktop {
                @media only screen and (max-width: 950px)  {
                    display: none;
                }


                margin-top: 15px;
            }

            svg {
                position: relative;

                fill: #1a1a1a;

                top: 2px;
                margin-left: 7px;
                width: 16px;
                height: 16px;
            }
        }
    }
}