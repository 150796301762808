
.main-page {


    &__blogInfomertial {
        padding-top: 20px;
        padding-bottom: 60px;

        padding-left: var(--margin-main);
        padding-right: var(--margin-main);

        

        background-color: #191935;

        background-image: linear-gradient(175deg, #191935 0%, #191935 31%, #14142b 63%, #13132a 78%, #0a0a16 88%);

        color: #ffff;

        border-top: 2px solid #363678;
        border-bottom: 2px solid #2d2d63;

        display: flex;
        justify-content: space-between;

        position: relative;

        overflow: hidden;

        &__text {

            z-index: 2;

            h2 {


                @media (max-width: 950px) {
                    text-align: center;
                }
        
                @media (min-width: 951px) {

                }

                margin-bottom: 30px;

                .link {
                    font-size: 22px;
                }

                .blog {

                    color: #c92a7c;
                    font-weight: 500;
                    text-shadow: 1px 1px 2px rgba(6, 6, 6, 0.202);
                }

                font-size: 37px;
                font-weight: 500;

                b {
                    
                }
            }

            &__spacer {


                @media (max-width: 950px) {
                    margin-left: auto;
                    margin-right: auto;                    

                    margin-bottom: 30px;
                }
        
                @media (min-width: 951px) {

                }

                width: 50%;
                height: 2px;

                margin-bottom: 15px;

                background-color: #ffff;
            }

            span {

                font-size: 18px;
                line-height: 26px;
                display: block;

                @media (max-width: 950px) {
                    text-align: center;

                    margin-left: 5px;
                    margin-right: 5px;
                }
        
                @media (min-width: 951px) {
                    max-width: 450px;
                }

                b {
                    font-weight: 500;

                    color: #fc2f99;
                    font-weight: 500;
                   
                }
            }

            &__button-wrapper {
                display: flex;

                @media (max-width: 950px) {
                    justify-content: center;
                    width: 100%;

                }
        
                @media (min-width: 951px) {
                    justify-content: left;
                }

            }

            a {
                @media (max-width: 950px) {
                    width: 80%;
                }
        
                @media (min-width: 951px) {
                    
                }
            }

            button {

                @media (max-width: 950px) {
                    width: 100%;
                    
                }


                margin-top: 40px;
                margin-bottom: 0px;

                height: min-content;

                cursor: pointer;

                font-size: 18px;
                font-weight: 500;

                color: #ffff;
                background-color: #191935;
                border-radius: var(--panel-radius);
                border: 2px solid #ffff;
                padding: 12px 20px 12px 20px;


                &:hover {
                    background-color: #8f2c5f;
                    border-color: #8f2c5f;
                }


                
                transition: background-color 0.3s, border-color 0.3s ease;
            }

        }

        img {


            position: absolute;

            max-width: 850px;

            &.infty-right {


                @media (max-width: 950px) {
                    width: 450px;
                    height: auto;

                    transform: rotate(20deg);

                    top: 0px;
                    right: -250px;

                    opacity: 0.35;
                }
        
                @media (min-width: 951px) {
                    width: 60%;
                    transform: rotate(10deg);
                    right: -200px;
                    top: -100px;                    
                }
            }
    

        }

    }
}