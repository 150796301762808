.main-page {

    &__final-test-information {
        justify-content: center;
        display: flex;
    
        font-weight: 500 !important;
    
    
    
        @media only screen and (min-width: 339px) and (max-width: 950px) {
          transform: scale(1.1);
        }
    
        @media only screen and (max-width: 950px) {
          margin-bottom: 70px;
    
          margin-top: 80px;
        }
    
        @media only screen and (min-width: 951px) {
          margin-bottom: 90px;
        }
    
        &__graphic {
          max-width: 800px;
    
          margin-left: auto;
          margin-right: auto;
    
          position: relative;
    
          &__pointer {
            position: absolute;
            z-index: 2;
    
            transition: transform 0.8s cubic-bezier(0.68, -0.55, 0.27, 1.55), opacity 0.8s ease;
            opacity: 0;
            transform: translateX(90px) translateY(6px) rotate(280deg);

            &.visible {

                opacity: 1;

                @media only screen and (max-width: 950px) {
                    transform: translateX(0) translateY(0) rotate(25deg);
                }
              
                @media only screen and (min-width: 951px) {
                    transform: translateX(0) translateY(0) rotate(320deg);
                }
            }
    
            @media only screen and (max-width: 950px) {
              font-size: 45px;
              right: 5px;
              bottom: 30px;
    
            }
        
            @media only screen and (min-width: 951px) {
              right: -102px;
              bottom: 5px;
              font-size: 55px;
            
            }
          }
    
          &__text {
    
            display: flex;
    
            &__main {
    
    
              position: relative;
              display: block;
    
              z-index: 1;
              font-weight: 500px !important;
    
              text-align: left;
    
              @media only screen and (max-width: 950px) {
                font-size: 28px;
                line-height: 38px;
    
                left: -5px;
              }
    
              @media only screen and (min-width: 951px) {
                font-size: 35px;
                line-height: 45px;
                left: -133px;
    
                padding-left: 15px;
              }
            }
    
    
            &__spacer {
    
              @media only screen and (max-width: 950px) {
                display: none
              }
    
              position: relative;
    
              left: -140px;
              top: 10px;
    
              display: block;
    
              height: 160px;
    
              border-radius: 90px;
              width: 4px !important;
    
              background-color: var(--yellow-color);
            }
          }
    
          b {
            font-weight: 500;
            color: var(--highlited-color);
            text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.202);
          }
    
          &__test {
    
            position: absolute;
            z-index: 0;
    
    
            display: grid;
            gap: 4px;
    
            &.mobile {
              top: -10px;
              left: 125px;
              transform: scale(0.8);
    
              grid-template-columns: repeat(3, 1fr);
              @media only screen and (min-width: 951px) {
                display: none;
              }
            }
    
            &.desktop {
    
              left: 74px;
              top: 2px;
    
              grid-template-columns: repeat(7, 1fr);
              @media only screen and (max-width: 950px) {
                display: none;
    
              }
            }
    
    
    
    
            &__box {
              width: 53px;
              height: 38px;
          
              border-radius: var(--panel-radius);
      
              border: 2px solid var(--highlited-color);
             
              display: flex;
              justify-content: center;
              text-align: center;

              transition: 
                border-color 1s ease, 
                background-color 1s ease, 
                color 1s ease;
    
                @media only screen and (max-width: 950px) {

                }
        
                @media only screen and (min-width: 951px) {
        
                }

              span {
                font-size: 16px;
    
                width: auto;
                height: auto;
                margin: 0 auto;
                padding: 10px;
                position: relative;
              }
    
              &.w {
                border: 2px solid #fafafa;
                color: #fafafa;
              }
    
              &.l {
                border: 2px solid #d7eff2;
                color: #e6e6e6;
              }

              &.active-yellow {
                border: 2px solid var(--yellow-color) !important;

                color: #1a1a21 !important;

                @media only screen and (min-width: 951px) {
                  background-color: #fff7e3 !important;
    
                }
              }
    
              &.y {
                border: 2px solid #d7eff2;
                color: #e6e6e6;
              }
            }
          }
    
        }
    
      }
}