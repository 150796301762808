
.Logo {
    padding: 0px 0px;
    margin: 18px 0px;
    margin-left: 20px;

    .cls-1 {
    fill: #00777a;
    }
    .cls-2 {
    fill: url(#linear-gradient);
    }
    .cls-3 {
    fill: url(#linear-gradient-2);
    }
    .cls-4 {
    fill: url(#linear-gradient-3);
    }
    .cls-5 {
    fill: url(#linear-gradient-4);
    }
    .cls-6 {
    fill: url(#linear-gradient-5);
    }
}


