.main-page {

    &__NewDesktopIntroduction {

        margin-top: 210px;
        margin-bottom: 160px;



        display: flex;

        @media only screen and (max-width: 950px) {
            display: none;  
        }

        @media only screen and (max-width: 1584px)  {
            margin-left: calc(var(--main-panel-margin) + 5%);
            margin-right: calc(var(--main-panel-margin) + 5%);    

        }

        @media only screen and (min-width: 1585px)  {
            max-width: 1321px;

            margin-left: auto;
            margin-right: auto;

            padding-left: 55px;
        }

        &__left-section {

            width: 50%;

            max-width: 605px;

            
            

            h1 {
                font-size: 40px;
                line-height: 47px;
                font-weight: 500;

                b {
                    font-weight: 650;
                }
            }


            h2 {
                font-size: 22px;
                line-height: 30px;

                font-weight: 400;


                b {
                    font-size: 16px;
                    font-weight: 450;
                }
            }

            span {
                display: block;
                margin-right: 20px;
                font-size: 20px;
                line-height: 28px;
            }


            &__bullets {

                margin-top: 30px;

                &__bullet {

                    display: flex;
                    margin-bottom: 15px;

                    position: relative;

                    svg {

                        position: relative;
                        top: 3px;

                        height: 19px;
                        width: 19px;

                        fill: rgb(22, 16, 0);
                        // fill: #fafafa;

                        background-color: var(--yellow-color);

                        border-radius: 90px;

                        padding: 1px;
                    }

             
                    &__text {

                        // margin-left: 20px;
                        font-size: 20px !important;
                    }

                    &__icon {
                        font-size: 25px !important;
                    }
                }
            }

            &__buttons {

                margin-top: 55px;
                position: relative;

                .go-to-shop {
                    font-weight: 500;
                    background-color: var(--yellow-color);
                
                    border-color: var(--yellow-color);
                }

                .find-out-more {
                    font-weight: 450;
                    background-color: #fafafa;
                    margin-right: 20px;

                    
                }

                a {
                    cursor: pointer;
                    border-radius: var(--panel-radius);

                    border: 2px solid;
                    font-size: 18px;
                    padding: 12px 24px;
                    text-decoration: none;
                    color: black;
                }

                button {
                    cursor: pointer;
                    border-radius: var(--panel-radius);

                    border: 2px solid;
                    font-size: 18px;
                    padding: 12px 24px;
                }

                &__guarantee {
                    position: absolute;
                    bottom: -45px;
                    left: calc(179px + 10px);

                    width: 197px;

                    color: #9d9d9d;

                    span {
                        font-size: 16px;
                        line-height: 19px;

                        display: inline;
                    }

                    svg {
                        position: relative;

                        left: -21px;
                        top: 2px;

                        width: 16px;
                        height: 16px;
                        fill: #9d9d9d;

                        cursor: pointer;
                    }

                }
            }
        }

        &__right-section {
            width: 50%;

            
            // display: flex;

            position: relative;

            img {

                margin-left: 10%;
                margin-right: 0%;
                width: 90%;
                height: auto;
            }

            &__feature-wrapper {
                display: flex;
                margin-top: 60px;
                // margin-bottom: 0;

                position: relative;
            }

            &__buttons {


                position: absolute;
                bottom: calc(50% - 22px);
          
                justify-content: space-between;
                width: 80%;
                margin-left: 15%;
                margin-right: 5%;
                display: flex;
          
                svg {
                  width: 30px;
                  height: 30px;
          
                  fill: #fafafa;
                }
          
                button {
                  border: 1px solid #40aab8;
          
                  background-color: rgba(70, 168, 181, 0.889);
          
                  border-radius: 50px;
          
                  padding-top: 9px;
                  padding-bottom: 6px;
          
                  padding-left: 9px;
                  padding-right: 9px;
      
                  &.inactive {
                      background-color: rgba(203, 207, 208, 0.889);
                      border-color: rgba(203, 207, 208, 0.889);
                  }
                }
          
                &__button-left {
                  svg {
                    transform: rotate(180deg);
                    position: relative;
          
                    left: -2px;
                  }
          
                }
          
                &__button-right {
                  svg {
                    position: relative;
          
                    left: 2px;
                  }
                }
            }

            &__subtitle {
                margin-top: 10px;
                font-size: 22px;

                color: #9d9d9d;
                text-align: center;

                position: relative;
                margin-bottom: 0px;
                
                width: 80%;
                margin-left: 15%;
                margin-right: 5%;

            }
        }
    }
}