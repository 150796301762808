.data-panel {
    &__course {

        &__headline {
            margin-left: 2.5%;
            margin-top: 50px;

             
            font-size: 16px;
            margin-bottom: 0px;
            font-weight: 600;
            
        }

        &__video {
            padding-top: 0px !important;
        }

        &__attributes {
            margin: 15px 2.5% 20px 2.5%;
            border-bottom: 1px solid var(--spacer-color);
            display: flex;
            padding-bottom: 23px;

            @media only screen and (max-width: 950px)  {
                display: none;
            }


            &__section {
                display: flex;
                justify-content: space-between;
                width: 50%;

                &__element {
                    width: 100%;

                    display: flex;
                    justify-content: center;

                    color: #3a3a3a;
                    font-size: 14px;

                    white-space:nowrap;

                    span {
                        &.skeleton {
                            display: inline-block;
                            height: 28px;
                            width: 30%;
                        }
                    }

                    &__number {
                        font-size: 16px;
                        font-weight: 600;
                        color: var(--highlited-color);

                        margin-right: 5px;
                    }
                }
                
            }
        }

        &__description {
            margin: 15px 2.5% 0px 2.5%;

            div {
                &.skeleton {
                    display: inline-block;
                }
            }

            &__title {
                font-weight: 600;
                margin-bottom: 25px;

                div {
                    &.skeleton {
                        display: inline-block;
                        height: 20px;
                        width: 15%;
                    }
                }
            }

            &__text {
                color: #3a3a3a;
                line-height: 22px;

                div {
                    &.skeleton {
                        display: inline-block;
                        height: 16px;
                        width: 100%;
                    }
                }
            }
        }

        // &__materials {
        //     margin: 0px 2.5%;

        //     div {
        //         &.skeleton {
        //             display: inline-block;
        //         }
        //     }

        //     &__headline {
        //         font-size: 18px;
        //         font-weight: 600;
        //         margin-bottom: 25px;

        //         div {
        //             &.skeleton {
        //                 display: inline-block;
        //                 height: 18px;
        //                 width: 30%;
        //             }
        //         }
        //     }

        //     &__data {
        //         &__row {
        //             justify-content: space-between;
        //             padding-top: 10px;
        //             padding-bottom: 10px;

        //             &--mobile-hidden {
        //                 display: none;
        //             }

        //             @media only screen and (min-width: 951px)  {
        //                 display: flex;
        //                 border-right: 1px solid var(--spacer-color);
        //             }


        //             &__description {
        //                 border-left: 2px solid var(--highlited-color);
        //                 color: #3a3a3a;
        //                 line-height: 22px;

        //                 padding-left: 15px;
        //                 padding-right: 12.5%;

        //                 @media only screen and (min-width: 951px)  {
        //                     width: 100%;
        //                 }

        //                 @media only screen and (max-width: 950px)  {
        //                     width: calc(100% - 30px) !important;
        //                     margin-bottom: 45px;
        //                 }

        //                 div {
        //                     &.skeleton {
        //                         display: inline-block;
        //                         height: 16px;
        //                         width: 100%;
        //                     }
        //                 }
        //             }

        //             &__button {
        //                 display: flex;
        //                 justify-content: center;

        //                 margin-right: 4.5%;
        //                 white-space: nowrap;
                        
        //                 margin-top: auto;
        //                 margin-bottom: auto;

        //                 cursor: pointer;

        //                 &:hover {
        //                     @media only screen and (min-width: 951px)  {
        //                         transition-duration: 200ms;
        //                         transform: translate(7px);    
        //                     }
    
                            
        //                     .material-symbols-outlined {
        //                         color: #2c8894 !important;
        //                     }
        //                 }

        //                 @media only screen and (min-width: 951px)  {
        //                     font-weight: 600;
        //                 }

        //                 @media only screen and (max-width: 950px)  {
        //                     margin: 20px auto 0px auto;

        //                     padding: 10px 0px;
        //                     min-width: 148px;
        //                     max-width: 148px;
        //                     height: min-content;

        //                     border-radius: var(--panel-radius);
        //                     border: 2px solid var(--highlited-color);

        //                     .material-symbols-outlined {
        //                         display: none;
        //                     }
        //                 }

        //                 .material-symbols-outlined {
        //                     position: relative;
        //                     bottom: 3px;
        //                     left: 5px;                            
        //                     font-weight: 600;

        //                     color: var(--highlited-color);

        //                     font-variation-settings:
        //                     'FILL' 0,
        //                     'wght' 400,
        //                     'GRAD' 0,
        //                     'opsz' 48;
        //                 }
        //             }
        //         }

        //         &__spacer {
        //              margin: 10px 2.5% 10px 5%;
        //             border-bottom: 1px solid var(--spacer-color);

        //             &--mobile-hidden {
        //                 @media only screen and (max-width: 950px)  {
        //                     display: none;
        //                 }
        //             }

        //             &--desktop-hidden {
        //                 @media only screen and (min-width: 951px)  {
        //                     display: none;
        //                 }
        //             }
        //         }

        //         &__mobile-scroll-buttons {
        //             width: 90%;
        //             margin-left: auto;
        //             margin-right: auto;

        //             position: relative;

        //             @media only screen and (min-width: 951px)  {
        //                 display: none;
        //             }
                    
        //             span {
        //                 user-select: none;
        //                 -moz-user-select: none;
        //                 -khtml-user-select: none;
        //                 -webkit-user-select: none;
        //                 -o-user-select: none;

        //                 position: absolute;
        //                 display: block;
        //                 cursor: pointer;

        //                 top: -65px;

        //                 font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 48;

        //                 transform: scale(1.2);

        //                 &.left {
        //                     left: 0px;
        //                 }

        //                 &.right {
        //                     right: 0px;
        //                 }

        //                 &.active {
        //                     color: var(--highlited-color);
        //                 }

        //                 &.inactive {
        //                     color: rgb(210, 210, 210);
        //                 }
        //             }
        //         }

        //         &__mobile-scroll-satus {
        //             margin-top: 25px;
        //             display: flex;
        //             width: 100%;
        //             justify-content: center;

        //             @media only screen and (min-width: 951px)  {
        //                 display: none;
        //             }

        //             &__circle {
        //                 margin: 0px 7px;
        //                 height: 10px;
        //                 width: 10px;
        //                 background-color: var(--spacer-color);
        //                 border-radius: 50%;

        //                 &--active {
        //                     background-color: var(--highlited-color);
        //                 }
        //             }
        //         }
        //     }
        // }
    }
}