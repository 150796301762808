.data-panel{
    &__mainTest {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        // min-height: 70vh;

        &__single-excercise-wrapper {
            min-height: 50vh;
        }

        &__spacer {
            margin-top: 30px;
        }

        &__question-number--wrapper {
            margin-left: 2.5vw;
        }

        &__video-explanation {
            border-color: var(--yellow-color) !important;
            background-color: var(--yellow-color);
            margin-right: 2.5vw;
        }

        &__question-number {
                display: flex;
    
                h4 {
                    margin: 0px;
    
                    font-size: 18px;
                    font-weight: 600;
    
                    position: relative;
                    
                    &.underlined {
                        &::before{
                            content: '';
                            position: absolute;
                            left: 0;
                            bottom: -10px;
                            background-color: var(--highlited-color);
                            height: 2px;
                            box-sizing: border-box;
                            width: 50px;
                        }
                    }
                }

                @media only screen and (max-width: 950px)  {
                    padding-top: 30px;
                }
            }
    }

    &__notes {
        &__container {
            &__data {
                &__text {
                    &.mainTest {
                        margin: 0 2.5vw 0 2.5vw;
                    }
                }
            }

        }
    }
}