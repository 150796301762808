.main-page {
    &__offers {
 
        @media only screen and (max-width: 950px)  {
            margin-bottom: 60px;
            margin-top: 80px;
        }
    
        @media only screen and (min-width: 951px)  {
            margin-bottom: 80px;
        }

        &__headline {

            text-align: center;


            @media only screen and (max-width: 950px)  {
                margin-bottom: 30px;
            }
        

            @media only screen and (min-width: 951px)  {
                margin-bottom: 50px;
            }

            &__main {
                font-size: 37px;
                font-weight: 500;


                &--desktop {
                    @media only screen and (max-width: 950px)  {
                        display: none;
                    }
                }

                &--mobile {
                    @media only screen and (min-width: 951px)  {
                        display: none;
                    }
                }
            

                b {
                    // font-size: 42px;
                    // font-family: "Domine", serif;
                    color: #8f2c5f;
                    font-weight: 500;

                    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.17);
                }
            }

            &__description {
                margin-left: auto;
                margin-right: auto;
                display: block;
                padding-top: 20px;

                // font-weight: 500;

                max-width: 550px;

                &--desktop {
                    font-size: 20px;
                    line-height: 28px;

                    @media only screen and (max-width: 950px)  {
                        display: none;
                    }
                }

                &--mobile {
                    font-size: 22px;
                    line-height: 28px;

                    width: 86%;

                    
                    @media only screen and (min-width: 951px)  {
                        display: none;
                    }
                }
            }

        }  

        &__mobile-menu {

            &__panels {
                display: flex;
                justify-content: space-between;

                margin-left: auto;
                margin-right: auto;
                width: 76vw;

                margin-bottom: 20px;

                @media only screen and (min-width: 951px)  {
                    display: none;
                }

                &__button {

                    
                    display: flex;
                    justify-content: center;
                    
                    border-radius: var(--main-panel-radius);

                    transition: background-color 0.5s ease;
                    transition: border-color 0.3s ease;

                    cursor: pointer;

                    span {
                        display: block;
                        margin: auto;

                        font-size: 22px;
                        font-weight: 500;
                    
                    }

                    &--inactive {
                        margin-top: 1vw;
                        width: 20vw;
                        height: 12vw;

                        color: #c2c2c2;
                        background-color: var(--spacer-color);
                        border: 2px solid var(--spacer-color);
                    }

                    &--active {
                        width: 21vw;
                        height: 14vw;

                        color: #ffff;

                        span {
                            font-weight: 600;
                            font-size: 24px !important;
                        }

                        border: 2px solid var(--yellow-color);
                        background-color: var(--yellow-color);

                        -webkit-box-shadow: 8px 8px 24px -21px rgba(66, 68, 90, 1);
                        -moz-box-shadow: 8px 8px 24px -21px rgba(66, 68, 90, 1);
                        box-shadow: 8px 8px 24px -21px rgba(66, 68, 90, 1);
                    }
                }
            }
        }

        &__scrollable {
            white-space: nowrap;
            &--desktop {
                @media only screen and (max-width: 950px)  {
                    display: none;
                }

                padding-bottom: 60px;

                display: flex;
                overflow-y: hidden;

                @media only screen and (max-width: 1551px)  {
                    overflow-x: scroll;                    
                }
    
                @media only screen and (min-width: 1552px)  {
                    margin-left: auto;
                    margin-right: auto;
                    justify-content: center;
    
                    overflow-x: hidden;
                }
            }

            &--mobile {
                @media only screen and (min-width: 951px)  {
                    display: none;
                }

                width: 100%;
                padding-bottom: 40px;


                .selling-course-panel {
                    margin-bottom: 20px !important;
                }
            }


            &::-webkit-scrollbar {
                height: 6px;
            }
              
            &::-webkit-scrollbar-track {
                background: #e2e2e2; 

                border-radius: var(--main-panel-radius);

                @media only screen and (max-width: 1551px)  {
                    margin-left: 40vw !important;
                    margin-right: 40vw !important;                    
                }

                @media only screen and (min-width: 1552px)  {
                    background: var(--background-color);
                }

            }
               
            &::-webkit-scrollbar-thumb {
                background: #3296a3; 
                border-radius: var(--main-panel-radius)
            }
              
            &::-webkit-scrollbar-thumb:hover {
                background: #3bb2c1; 
            }

            // for no webkit browsers (firefox)
            scrollbar-color: #3296a3 #ececec;
            scrollbar-width: thin;
        }

        &__artificial-spacer {
            position: relative;

            margin-left: auto;
            margin-right: auto;

            width: 45vw;
            border-top: 1px solid var(--spacer-color);

            &--top {
                margin-bottom: 20px;
            }

            &--bottom {
                top: -27px;
            }

            &--mobile {
                @media only screen and (min-width: 951px)  {
                    display: none;
                }
            }
        }

        
    }
}