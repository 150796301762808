.data-panel__user-settings__email-code-panel {

    border-radius: var(--panel-radius);
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
    
    background:rgba(127, 127, 127, 0.217);
    height: calc(100% + 50px);
    width: 100%;
    position: absolute;
    right: 0;
    top: 0;

    
    z-index: 1;

    &__window {

        background-color: #ffff;
        border-radius: var(--panel-radius);

        &--full-size {
            @media only screen and (max-width: 950px)  {
                width: 100%; 
                height: min-content;        
            }

            @media only screen and (min-width: 951px)  {
                height: 420px;
                width: 500px;       
            }
        }

        &--small-size {


            @media only screen and (max-width: 950px)  {
                width: 100%; 
                max-width: 400px;
                height: min-content;        
            }

            @media only screen and (min-width: 951px)  {
                width: 400px;
                height: 205px;       
            }
        }


        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;

        -webkit-box-shadow: 7px 9px 24px -16px rgba(66, 68, 90, 1);
        -moz-box-shadow: 7px 9px 24px -16px rgba(66, 68, 90, 1);
        box-shadow: 7px 9px 24px -16px rgba(66, 68, 90, 1);

        &__notification {
            display: flex;

            margin-top: 35px;
            margin-left: 25px;
            margin-right: 25px;

            span {
                display: block;
                font-size: 16px;
                font-weight: 500;

                cursor: default;
            }

            .material-symbols-outlined {
                position: relative;
                top: 19px;
                transform: scale(2.0);
                margin-right: 25px;

                font-variation-settings:
                'FILL' 0,
                'wght' 400,
                'GRAD' 0,
                'opsz' 48
            }

            .success {
                color: var(--valid-color) !important;
            }

            .error {
                color: var(--invalid-color) !important;
            }
        }

        &__spacer {
            margin-left: 10%;
            margin-right: 10%;
            margin-top: 25px;
            margin-bottom: 0px;

            border-bottom: 1px solid var(--spacer-color);
        }

        &__headline {
            margin-top: 35px;
            margin-bottom: 20px;
            font-size: 20px;
            font-weight: 550;

            @media only screen and (max-width: 950px)  {
                text-align: center;
            }

            @media only screen and (min-width: 951px)  {
                padding-left: 25px;     
            }
        }

        &__main-text {
            padding-left: 25px;
            padding-right: 25px;

            line-height: 22px;

            @media only screen and (max-width: 950px)  {
                margin-top: 30px;
                margin-bottom: 20px;
            }

            @media only screen and (min-width: 951px)  {
                margin-bottom: 10px;
            }

            b {
                font-weight: 600;
            }
        }

        &__input-row {
            display: flex;
            justify-content: center;

            label {
                display: block;
                margin-left: auto;
                margin-right: auto;

                transform: scale(0.65);
            }

            span {
                display: block;
                font-size: 26px;
                font-weight: 500;
                margin-bottom: 35px;

                text-align: center;
            }

            input {
                cursor: pointer;
            }

            :where([autocomplete=one-time-code]) {
                --otp-digits: 6;
                --otp-ls: 2ch;
                --otp-gap: 1.25;
            
                --_otp-bgsz: calc(var(--otp-ls) + 1ch);
                --_otp-digit: 0;
            
                all: unset;

                background: 
                linear-gradient(
                90deg, 
                var(--otp-bg, var(--highlited-color)) calc(var(--otp-gap) * var(--otp-ls)),
                transparent 0),
                linear-gradient(90deg, 
                var(--otp-bg, #EEE) calc(var(--otp-gap) * var(--otp-ls)),
                transparent 0
                );
                    background-position: calc(var(--_otp-digit) * var(--_otp-bgsz)) 0, 0 0;
                    background-repeat: no-repeat, repeat-x;
                    background-size: var(--_otp-bgsz) 100%;
                    caret-color: var(--otp-cc, #222);
                    caret-shape: block;
                    clip-path: inset(0% calc(var(--otp-ls) / 2) 0% 0%);
                    font-family: ui-monospace, monospace;
                    font-size: var(--otp-fz, 2.5em);
                    inline-size: calc(var(--otp-digits) * var(--_otp-bgsz));
                    letter-spacing: var(--otp-ls);
                    padding-block: var(--otp-pb, 1ch);
                    padding-inline-start: calc(((var(--otp-ls) - 1ch) / 2) * var(--otp-gap));
            }
        }

        &__buttons {
            margin-top: 20px;
            padding-left: 45px;
            padding-right: 45px;

            display: flex;
            justify-content: space-between;

            @media only screen and (max-width: 950px)  {
                margin-bottom: 35px;
            }

            &--centered {
                margin-top: 15px !important;
                justify-content: center !important;
            }

            &__button {
                height: min-content;
                margin: 15px 0px;
                padding: 10px 20px;
                border-radius: var(--panel-radius);
                border: 2px solid var(--highlited-color);
                cursor: pointer;
                -webkit-user-select: none;
                user-select: none;

                white-space: nowrap;

                &:active {
                    transform: scale(0.98);
                }

                &--full {
                    margin-right: 30px;
                }

                &--active {
                    color: #ffff;
                    background-color: var(--highlited-color);
                    
                    &:hover {
                        border-color: var(--button-full-hover-color);
                        background-color: var(--button-full-hover-color);
                    }
                }

                &--yellow {

                    background-color: var(--yellow-color);
                    border-color: var(--yellow-color);
                    font-weight: 500 !important;

                    &:hover {
                        border-color: var(--yellow-hover-color);
                        background-color: var(--yellow-hover-color);
                    }
                }

                &--inactive {

                    background-color: #ffff;
                    border-color: #d2d2d2 !important;
                    color: #d2d2d2 !important;

                    &:active {
                        transform: scale(1) !important;
                    }
                }

                &--empty {
                    text-align: center;
                    width: 70px;

                    &:hover {
                        border-color: var(--button-full-hover-color);   
                    }
                }

                &__text {
                    &--long {
                        @media only screen and (max-width: 950px)  {
                            display: none;
                        }
                    }

                    &--short {
                        @media only screen and (min-width: 951px)  {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}