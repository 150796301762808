.FAQWrapper {

    &__section-name {
        margin-top: 130px;

        border-bottom: 1px solid var(--spacer-color);
        padding-bottom: 20px;
        margin-bottom: 21px;

        font-size: 24px;
        font-weight: 500;

        @media only screen and (min-width: 951px) and (max-width: 1312px) {
            margin-left: 21px;
            margin-right: 21px;
        }
        
          @media only screen and (min-width: 1313px) {
            max-width: 1270px;
            margin-left: auto;
            margin-right: auto;
        }

        &__text {
            margin-left: 2.5%;
        }
    }

    #content-name {
        display: none;
    }

    .FAQ-section {
        margin-bottom: 60px;
    }
}