.data-panel {
    &__latex {
        // position: relative;

        &__vertical-line-container {
            content: "";
              position: absolute;
              bottom: 0;
              margin-left: 50%;
              height: 20vh;
              border-right: 0.5px solid #bebebe;
              height: 100%;
        }

        &__horizontal-line-container {
            content: "";
            position: absolute;
            left: 2.5%;
            right: 2.5%;
            border-bottom: 0.5px solid #bebebe;

            &.bottom {
                bottom: 0;
            }

            &.top {
                top: 0;
            }

        }

        &__wrapper {
            display: flex;
            justify-content: flex-start;
            flex-direction: row;
            margin-top: 30px;
            font-size: 30px;
        }

        &__latex-and-inputs-wrapper {
            display: flex;
            flex-direction: column;
            @media only screen and (max-width: 950px) {
                align-items: center;
                width: 100%;
            }
            @media only screen and (min-width: 950px) {
                align-items: flex-start;
                width: 100%;
            }
            
            margin-top: 30px;
            margin-right: 50px;
            margin-left: 50px;
            margin-bottom: 50px;
            transition: filter 0.3s ease;

            &.hidden {
                  /* Adjust 0.3s to the desired speed of the transition */
                filter: blur(7px);
            }

            &.revealed {
                filter: blur(0px);
            }
        }

        &__inputs-wrapper {
            display: flex;
            flex-direction: row;
            justify-content: center;
            width: 100%;

            div {
                &.hidden {
                    /* Adjust 0.3s to the desired speed of the transition */
                filter: blur(7px);
            }

            &.revealed {
                filter: blur(0px);
            }
            }
                
            // margin-left: 50px;
        }

        // &__real-inputs {

        // }

        &__input-wrapper {
        
            // position: relative;
            margin: 15px;
            // width: 100%;
            @media only screen and (max-width: 950px) {
                display: flex;
                flex-direction: row;
                align-items: center;
            }
            span {
                &.long-label{
                    @media only screen and (max-width: 950px) {
                        display: none;
                    }
                }
                @media only screen and (max-width: 950px) {
                    margin-right: 8px;
                }
            }
            input {
                
                &.finished {
                    caret-color: transparent;
                }

                transition: border-color 0.3s ease;
                transition: color 0.3s ease;

                &.correct-answer--user-didnt-answer {
                    border-color: #bebebe !important;
                    color: transparent;
                }

                &.correct-answer--user-did-answer {
                    border-color: var(--highlited-color) !important;
                    color: auto;
                }
                
                font-size: 20px;
                // color: var(--highlited-color);
                
                // padding: 10px 15px 10px 4px;
                // box-sizing: border-box;
                width: 30px;
                height: 30px;
                // color: #1a1a21;
                
                outline: 0;
                border-width: 2px;
                border-radius: var(--panel-radius);
                border-color: var(--highlited-color);
                border-style: solid;

                text-align: center;
                
                &.invalid {
                    border-color: var(--invalid-color) !important;
                }
    
                &:focus {
                    border-color: #37c1d4;
                }
    
                // @media only screen and (max-width: 950px) {
                //     width: 100%;
                // }
    
                // @media only screen and (min-width: 951px) {
                //     width: 230px;
                // }
    
                &::placeholder {
                    color: #bebebe;
                }
            }
        }
    }
}