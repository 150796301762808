.data-panel__user-panel__buttons__button {
    border-radius: var(--panel-radius);
    border: 2px solid var(--highlited-color);

    display: flex;

    cursor: pointer;

    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */

    &:hover {
        border-color: var(--button-border-only-hover-color) !important;
    }

    &:active {
        transform: scale(0.98);
    }


    &--inactive {
        color: #c5c5c5 !important;
        border-color: #d2d2d2 !important;

        &:hover {
            border-color: #d2d2d2 !important;
        }

        &:active {
            transform: scale(1.0) !important;
        }
    }

    &--prev {
        margin-left: 5%;
        padding: 10px 20px 5px 17px;
    }

    &--next {
        margin-right: 5%;
        padding: 10px 12px 5px 20px;

        .material-symbols-outlined {
            margin-left: 5px;
        }
    }

    .material-symbols-outlined {
        position: relative;
        bottom: 3px;
    }

    &__text {
        &--mobile {
            @media only screen and (min-width: 951px)  {
                display: none;
            }
        }

        &--desktop {
            @media only screen and (max-width: 950px)  {
                display: none;
            }
        }
    }
}