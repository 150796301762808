
.about-us {


    &__header {

        margin-left: 5%;
        margin-right: 5%;

        margin-top: 180px;
        margin-bottom: 60px;
        font-weight: 500;        

        @media only screen and (max-width: 950px) {
            font-size: 37px;
        }

        @media only screen and (min-width: 951px) {
            padding-left: 7px;
            font-size: 45px;
        }


        color: rgb(26, 26, 33);

        .colored {
            text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.17);
            color: rgb(143, 44, 95);
        }        

        .material-symbols-outlined {
            position: relative;

            cursor: pointer;

            @media only screen and (max-width: 950px) {
                top: 5px;
                font-size: 35px;
            }
    
            @media only screen and (min-width: 951px) {
                top: 4px;
                font-size: 40px;
            }

            font-variation-settings:
            'FILL' 0,
            'wght' 500,
            'GRAD' 0,
            'opsz' 24;

            &:hover {
                text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.17);
                color: rgb(143, 44, 95);
            }

            &:active {
                transform: scale(0.98);
            }

        }
    }

    &__split {

        margin-left: 5%;
        margin-right: 5%;

        width: 90%;

        @media only screen and (max-width: 950px) {

        }

        @media only screen and (min-width: 951px) {
            display: flex;
        }


        .text {
            font-size: 18px;
            line-height: 24px;

            b {
                text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.17);
                color: rgb(143, 44, 95);
            }

            &--margined {
                display: block;    
            
    
                @media only screen and (min-width: 951px) {
                    padding-right: 9%;
                    padding-left: 7px;
                }
    
            }

        }

        .header {
            margin-top: 20px;
            margin-bottom: 40px;

            font-weight: 500;        

            @media only screen and (max-width: 950px) {
                font-size: 37px;
            }

            @media only screen and (min-width: 951px) {
                font-size: 40px;
            }

    
            color: rgb(26, 26, 33);

            &--margined {

                @media only screen and (min-width: 951px) {
                    padding-left: 7px;
                }
            }
            
            &--small {
                font-size: 28px !important;
                margin-bottom: 28px !important;


            }

            &--centered {
                margin-left: auto;
                margin-right: auto;

                
                width: min-content;
                white-space: nowrap;

            }

            .material-symbols-outlined {
                font-size: 28px !important;

                margin-left: 15px;
                position: relative;
                top: 5px;

                font-variation-settings:
                'FILL' 0,
                'wght' 450,
                'GRAD' 0,
                'opsz' 24;

                // text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.17);
                // color: rgb(143, 44, 95);
            }
        }

        .panel {


            border-radius: 15px;

            &__point {
                font-size: 18px;
                line-height: 24px;

                margin-bottom: 20px;

                display: flex;

                span {
                    display: block;
                }

                &__bullet {
                    min-width: 9px;
                    min-height: 9px;
                    width: 9px;
                    height: 9px;

                    border-radius: 90px;

                    border: 3px solid #ffbe1a;

                    margin-right: 12px;

                    position: relative;
                    top: 4px;
                }
            }
        }

        .join-us-button {
            margin-top: 30px;

            cursor: pointer;
            background-color: var(--yellow-color);
            font-weight: 500;
            -webkit-user-select: none;
            user-select: none;

            position: relative;
            display: flex;

            border-radius: 17px;

            @media only screen and (max-width: 950px) {
                width: 75%;
                
                margin-left: auto;
                margin-right: auto;

                margin-top: 50px;
                padding: 18px 16px 11px 24px;

                text-align: center;

                display: flex;
                justify-content: center;
            }

            @media only screen and (min-width: 951px) {

                margin-left: 7px;
                width: 158px;

                padding: 16px 16px 9px 24px;
            }

            span {
                cursor: pointer;
                font-weight: 500;
                -webkit-user-select: none;
            }

            svg {
                margin-left: 8px;
                position: relative;
                top: -3px;
            }

            &:hover {
                background-color: #ffbe1a !important;
                border-color: #ffbe1a !important;
            }

            &:active {
                transform: scale(0.98);
            }
        }

        &__left {

            @media only screen and (max-width: 950px) {
                width: 100%;
            }

            @media only screen and (min-width: 951px) {
                width: 60%;
            }

            img {
                width: 100%;

                margin-bottom: 30px;
            
                @media only screen and (max-width: 950px) {
                    position: relative;
                    right: -25px;
                }

            }
        }

        &__right {

            @media only screen and (max-width: 950px) {
                width: 100%;
            }

            @media only screen and (min-width: 951px) {
                width: 40%;
            }
            
        }
    }

    &__join-us {
        width: 100%;

        padding-left: 5%;
        padding-right: 5%;

        padding-top: 40px;
        padding-bottom: 40px;

        margin-top: 50px;
    }

    .join-team {

        &--mobile {
            margin-top: 60px;
            @media only screen and (min-width: 951px) {
                display: none;
            }
    
        }

        &--desktop {
            @media only screen and (max-width: 950px) {
                display: none;
            }
        }
    }
}