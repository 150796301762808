.FAQ-section {
  border-radius: var(--panel-radius);
  background-color: #ffff;

  @media only screen and (max-width: 950px) {
    margin-left: 0px;
    margin-right: 0px;
  }

  @media only screen and (min-width: 951px) and (max-width: 1312px) {
    margin-left: 21px;
    margin-right: 21px;
  }

  @media only screen and (min-width: 1313px) {
    max-width: 1270px;
    margin-left: auto;
    margin-right: auto;
  }

  -webkit-box-shadow: 8px 8px 16px -16px #42445a;
  -moz-box-shadow: 8px 8px 16px -16px #42445a;
  box-shadow: 8px 8px 16px -16px #42445a;

  * {
    box-sizing: border-box;
  }
  *::before,
  
  *::after {
    box-sizing: border-box;
  }

  &__title {
    padding-top: 50px;
    padding-bottom: 40px;
    margin-left: 2.5%;

    .material-symbols-outlined {
        font-variation-settings: 'FILL' 0, 'wght' 500, 'GRAD' 0, 'opsz' 48;
        position: relative;
        top: 5px;
        margin: auto 5px auto 0px;
        color: var(--highlited-color);
        cursor: pointer;
        
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        &:hover {
            color: var(--button-border-only-hover-color);
        }
    }

    &__text {
        font-size: 20px;
        font-weight: 600;
        user-select: none;
        cursor: default;
    }
  }

  &__description {
    margin-left: 2.5%;
    margin-right: 2.5%;

    font-size: 17px;
    line-height: 23px;

    &--top {
      margin-bottom: 25px;
    }

    &--bottom {
      padding-bottom: 50px;

      @media only screen and (max-width: 950px) {
        display: none;
      }
    }
  }

  &__sub-title {
    margin-left: 2.5%;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 20px;
  }

  &__container {
    width: 100%;
    padding-bottom: 50px;

  
    @media only screen and (min-width: 951px)  {
      display: flex;
    }

    justify-content: space-between;

    &__list {
        margin-left: 2.5%;
        margin-right: 2.5%;

        // ...

        @media only screen and (max-width: 950px) {
          width: 95%;
        }
      
        @media only screen and (min-width: 951px)  {
          width: 45%;
        }
    }

    &__not-found {
      margin-left: 5%;
      font-size: 18px;
      color: #bfbfbf;
    }
  }
}
