.page-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 3;

    background-color: rgba(0, 0, 0, 0.405);
    cursor: default;

    backdrop-filter: blur(2px);
}


body {
    .open-test-panel {

        background-color: #ffffff;
        margin: auto;
        z-index: 4;
    
        --blur-size: 0;
        -webkit-filter: blur(var(--blur-size));
        -moz-filter: blur(var(--blur-size));
        -o-filter: blur(var(--blur-size));
        -ms-filter: blur(var(--blur-size));
        filter: blur(var(--blur-size));
    
        -webkit-box-shadow: 14px 8px 32px -14px rgba(66, 68, 90, 1);
        -moz-box-shadow: 14px 8px 32px -14px rgba(66, 68, 90, 1);
        box-shadow: 14px 8px 32px -14px rgba(66, 68, 90, 1);
            
        border-radius: var(--panel-radius);

        @media only screen and (max-width: 950px)  {
            width: 100%;
            height: max-content;        
    
            position: fixed;
        }  
    
        @media only screen and (min-width: 951px)  {      
            display: flex;
            width: 800px;
            height: 480px;
        
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }  
    
        &__offer {    
            z-index: 4;
    
            @media only screen and (max-width: 950px)  {
                -webkit-box-shadow: 1px 8px 24px -14px rgba(66, 68, 90, 1);
                -moz-box-shadow: 1px 8px 24px -14px rgba(66, 68, 90, 1);
                box-shadow: 1px 8px 24px -14px rgba(66, 68, 90, 1);
                
                padding-top: 1px;
                padding-bottom: 50px;

                border-radius: var(--panel-radius);
            } 
    
            @media only screen and (min-width: 951px)  {
                -webkit-box-shadow: 1px 15px 24px -14px rgba(66, 68, 90, 1);
                -moz-box-shadow: 1px 15px 24px -14px rgba(66, 68, 90, 1);
                box-shadow: 1px 15px 24px -14px rgba(66, 68, 90, 1);
            }  
    
            &__preview {
                margin: 50px 5% 0px 2.5%;
    
                &__headline {
                    padding-left: 2.5%;
                    padding-bottom: 2px;
                 
                    font-size: 18px;
                    font-weight: 600;
    
                    @media only screen and (max-width: 950px)  {
                        margin-left: 2.5%;
                        padding-left: 3.5%;
                        border-left: 2px solid var(--highlited-color);
                    }  
                }
        
                &__text { 
                    padding-left: 2.5%;
                    line-height: 22px;
                    color: #3a3a3a;
        
                    &__headline {        
                        @media only screen and (max-width: 950px)  {
                            display: block;
                            padding-top: 25px;
                        }  
                    }
        
                    &__description {
                        border-top: 1px solid var(--spacer-color);
                        border-bottom: 1px solid var(--spacer-color);
                        
                        padding-top: 5px;
                        padding-bottom: 10px;

                        margin-top: 20px;
                        margin-bottom: 30px;

                        &--mobile {
                            @media only screen and (min-width: 951px)  {
                                display: none;
                            }  
                        }

                        &--desktop {
                            @media only screen and (max-width: 950px)  {
                                display: none;
                            }  
                        }
        
                        &__point {
                            display: flex;
                            margin-top: 15px;
        
                            &--first {
                                margin-bottom: 10px;
                            }

                            &__dot {
                                height: 6px;
                                width: 6px;
                                min-width: 6px;
                                min-height: 6px;
                                border-radius: 50px;
                                border: 3px solid #ffca43;
                                position: relative;
                                top: 4px;
                                margin-right: 10px;
                            }
                        }
                    }                
                }
            }
        
            &__button {
                margin-top: 30px;
                display: flex;
        
                margin-left: auto;
                margin-right: auto;
                width: min-content;
                white-space: nowrap;
            
                border-radius: 15px;
                border: 2px solid var(--highlited-color);

                -webkit-box-shadow: 8px 8px 16px -16px #42445a;
                -moz-box-shadow: 8px 8px 16px -16px #42445a;
                box-shadow: 8px 8px 16px -16px #42445a;
        
                cursor: pointer;

                --yellow-colour: #ffca43;
                --yellow-hovered-colour: #fac130;

                background-color: var(--yellow-colour);
                border-color: var(--yellow-colour);

                -webkit-user-select: none; /* Safari */        
                -moz-user-select: none; /* Firefox */
                -ms-user-select: none; /* IE10+/Edge */
                user-select: none; /* Standard */

                -webkit-box-shadow: 1px 5px 13px -11px rgba(66, 68, 90, 1);
                -moz-box-shadow: 1px 5px 13px -11px rgba(66, 68, 90, 1);
                box-shadow: 1px 5px 13px -11px rgba(66, 68, 90, 1);
        
                &:hover {
                    background-color: var(--yellow-hovered-colour);
                    border-color: var(--yellow-hovered-colour) !important;
                }
        
                &:active {
                    transform: scale(0.98);
                }
                
                .material-symbols-outlined {
                    position: relative;
                    bottom: 5px;
        
                    font-variation-settings:
                    'FILL' 0,
                    'wght' 400,
                    'GRAD' 0,
                    'opsz' 48;
                    
                    transform: scale(0.95);
                    padding: 14px 2px 6px 23px;
                }
        
                &__text {
                    margin-left: 0px;
                    font-weight: 500;
        
                    padding: 13px 23px 13px 10px;
        
                    @media only screen and (min-width: 951px)  {
                        &:after {
                            content: 'Odblokuj omówienie wszystkich zadań otwartych w kursie.';
                        }
                    }
        
                    @media only screen and (max-width: 950px)  {
                        &:after {
                            content: 'Odblokuj nagrania';
                        }
                    }
                }
            }
        }
    
        &__graphic {
    
            @media only screen and (max-width: 950px)  {
                display: none;
            }  
    
            @media only screen and (min-width: 951px)  {
                width: 300px;

                height: 100%;
                position: relative;

                border-top-right-radius: var(--panel-radius);
                border-bottom-right-radius:var(--panel-radius);

                svg {
                    position: absolute;
                    top: 15px;
                    left: 0;

                    height: 452px;
                    
                    width: 195px;
                    border-radius: var(--panel-radius);
                }

                .light-panel {
                    z-index: 2;

                    transition: opacity 0.7s ease-out;
                    -moz-transition: opacity 0.7s ease-out;
                    -webkit-transition: opacity 0.7s ease-out;
                    -o-transition: opacity 0.7s ease-out;   

                    &--active {
                        opacity: 1.0;

                    }

                    &--inactive {
                        opacity: .0;
                      
                    }

                }

                .dark-panel {
                    z-index: 1;
                }
            }  
    
            background-color: #0d0d2b;
        }
    }


    .popup-close-button {
        position: absolute;
        right: 3px;
        top: 12px;
        width: 25px;
        height: 25px;
        opacity: 0.3;
    
        cursor: pointer;
    
        &--mobile {
            position: relative;
            margin-left: auto !important; 
            margin-right: -10px !important;
            top: -25px;
    
            @media only screen and (min-width: 951px)  {
                display: none;
            } 
        }
    
        &--desktop {
            z-index: 3;
            
            @media only screen and (max-width: 950px)  {
                display: none;
            }
        }
    
        &:before, &:after {
            position: absolute;
            left: 0px;
            content: ' ';
            height: 25px;
            width: 2px;
            background-color: #ffffff !important;

            opacity: 1.0;
        }
    
        &:before {
            transform: rotate(45deg);
        }
    
        &:after {
            transform: rotate(-45deg);
        }
    
        &:hover:after {
            background-color: #f1f1f1;
            transform: scale(1.1) rotate(45deg);
        }
    
        &:hover:before {
            background-color: #eeeeee;
            transform: scale(1.1) rotate(-45deg);
        }
    
        &:active:after {
            background-color: #282828;
            transform: scale(0.9) rotate(45deg);
        }
    
        &:active:before {
            background-color: #282828;
            transform: scale(1.0) rotate(-45deg);
        }            
    }
}