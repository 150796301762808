:root {
  --panel-radius: 12px;
  --background-color: #fafafa;
  --highlited-color: #38a1af; // ocean dark
  --inactive-color: lightgrey;
  --invalid-color: rgb(216, 103, 103);
  --valid-color: #40aab8; 
  --yellow-color: #ffca43;
  --yellow-hover-color: #fac130;
  --highlited-light-color: #CFEDF0;
  --spacer-color: #e8e8e8;
  --spacer-width: 1px;

  --purple: #8f2c5f;
  --blue: #44cadb;

  --button-full-hover-color: #3296a3;
  --button-border-only-hover-color: #2c8894;

  --dark-background-color: #0d0d2b;
  --light-blue-color: #44cadb;
}

body {
  font-size: 16px;
  // font-family: 'Arimo', sans-serif;
  color: #1a1a21;
  background-color: var(--background-color);
  margin: 0px 0px;
  max-width: 100%;
}

html, body {
  position:relative;
	overflow-x:hidden;
}

.skeleton {
  pointer-events: none !important;      
}

.not-selectable {
  -webkit-user-select: none;  /* Chrome all / Safari all */
  -moz-user-select: none;     /* Firefox all */
  -ms-user-select: none;      /* IE 10+ */
  user-select: none;          /* Likely future */      
}

.responsive-container {
  margin-top: 25px;
  margin-bottom: 30px;

  margin-left: auto;
  margin-right: auto;

  width: 95%;
  // height: 95%;

  position: relative;
  padding-bottom: 53.6%;
  // padding-top: 30px;
  
  // height: 0;


  border-radius: 8px !important;

  overflow: hidden;

  &.skeleton {
    padding-bottom: 50%;
    margin-top: 25px;
    margin-bottom: 30px;

    &.main {
      margin-top: 15px;
    }
  }

  iframe { 
      position: absolute;
      top: 0; 
      width: 100%;
      height: 100%;

      border: none;
      overflow: hidden !important;

      border-radius: 8px !important;

      &::-webkit-scrollbar {
          display: none;
      }
  }
}

.material-symbols-outlined {
  font-variation-settings:
  'FILL' 0,
  'wght' 300,
  'GRAD' 0,
  'opsz' 48
}