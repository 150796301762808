



.payment-confirmation {



      height: 100vh;
      max-height: 100vh;
  
      overflow: hidden;

    
  

    &__main {

      
        height: 100vh;
        

        background-color: rgb(215, 232, 234);


        position: relative;

        &__panel {

            @media (max-width: 950px) {
              width: 90%;
              margin-top: 110px;
 
            }

            @media (min-width: 951px) {
              width: 440px;
              --panel-height: 420px;
              height: var(--panel-height);
              top: calc(50vh - 0.5 * var(--panel-height));
            }

          
            position: absolute;
        
            border-radius: var(--panel-radius);
            background-color: white;
            z-index: 1;

            left: 0;
            right: 0;
            margin: 0 auto;
            box-shadow: 6px 6px 24px -4px rgba(66, 68, 90, 0.744);
        
            padding: 20px 0px 20px 0px;

            &__confirmation {
                width: 100%;
                height: 160px;

                @media (max-width: 950px) {

                  height: 110px;
                }
        
                @media (min-width: 951px) {
                  height: 160px;

                }

                display: flex;
                justify-content: center;
                  
            }

            &__headline_1 {
                text-align: center;

                padding-top: 20px;

                padding-left: 20px;
                padding-right: 20px;


                font-weight: 500;
                color: #8f2c5f;
                text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.17);

                @media (max-width: 950px) {

                  font-size: 32px;
                  line-height: 42px;
                }
        
                @media (min-width: 951px) {
                  font-size: 35px;
                  line-height: 45px;
                }
            }

            &__text {


              @media (max-width: 950px) {

                line-height: 24px;
                font-size: 20px;
              }
      
              @media (min-width: 951px) {
                line-height: 30px;
                font-size: 22px;
              }

                margin-top: 40px;
                font-weight: 400;

                padding-left: 20px;
                padding-right: 20px;

                text-align: center;

            }

            &__footer {

              @media (max-width: 950px) {
                margin-bottom: 10px;
              }
      
              @media (min-width: 951px) {

              }

                margin-top: 26px;
                border-top: 1px solid var(--spacer-color);

                span {
                    position: relative;

                    top: 13px;
                    color: #c0c0c0;

                    display: block;
                    text-align: center;
                }
            }
        }
    }

    .top-right-ray-team {
        position: absolute;
        z-index: 1;

        top: 51px;
        right: -20px;
        opacity: 0.8;
    }

    .down-left-ray-team {
        bottom: -20px;
        left: -20px;
        position: absolute;
        z-index: 1;
        opacity: 0.8;
    }

    .landing-footer {
        position: relative;
        z-index: 2;
    }
}