
.main-page {

    &__desktop-opinions {
        margin-bottom: 150px;

    
        border-radius: var(--panel-radius);
    
        overflow: hidden;
        position: relative;


        @media only screen and (max-width: 1584px)  {
            margin-left: calc(var(--main-panel-margin) + 5%);
            margin-right: calc(var(--main-panel-margin) + 5%);
        }

        @media only screen and (min-width: 1585px)  {
            max-width: 1321px;

            margin-left: auto;
            margin-right: auto;
        }

        &__headline {


            @media only screen and (max-width: 950px) {

            }

            @media only screen and (min-width: 951px) {
                width: 70%;
                max-width: 859px;
            }


            .desktop {
                @media only screen and (max-width: 950px) {
                    display: none;
                }

                @media only screen and (min-width: 951px) {
  
                }
            }

            .mobile {

                text-align: center;

                @media only screen and (max-width: 950px) {

                }

                @media only screen and (min-width: 951px) {
                    display: none;  
                }
            }

            h3 {

                @media only screen and (max-width: 950px) {
                    font-size: 37px;
                    line-height: 42px;
                }

                @media only screen and (min-width: 951px) {
                    font-size: 42px;
                    line-height: 47px;
                }

                font-weight: 500;
    
                b {
                    font-weight: 500 !important;
                    font-size: 40px;
                    
      
                    color: #8f2c5f;
            
                    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.202);
                  }
            }
        }


        &__content {
            display: flex;


            &__left {

                width: 50%; 
    
                @media only screen and (max-width: 950px) {
                    display: none;
                }

                @media only screen and (min-width: 951px) {
                       
                }
    
                &__socials {
            
                    @media only screen and (min-width: 951px) {
                        margin-top: 0px;
                        margin-bottom: 30px;

                        display: flex;
                        
                    }
            
                    display: flex;
            
                    &__data {
                
                        position: relative;
                
                        margin-left: 35px;
    
                    

                        &__headline {
                            // border-bottom: 1px solid var(--spacer-color);
                            padding-bottom: 2px;
                            margin-bottom: 2px;

                            span {
                                font-size: 18px;
                            }
                
                            @media only screen and (max-width: 950px) {
                                display: none;
                            
                            }
            
                        }
            
                      &__stats {
            
                        @media only screen and (max-width: 950px) {
                            position: relative;
                            top: -2px;              
                        }
                        
                        @media only screen and (min-width: 951px) {
                            display: flex;
                          
                        }
            
                        &__batch {
                            margin-right: 20px;
            
                          font-size: 22px;
                          font-weight: 400;
            
                          @media only screen and (max-width: 950px) {
                            margin-bottom: 3px;
                            
                          }
            
                          svg {
                            position: relative;
                            top: 3px;
                          }
            
                          .favoriteIcon {
                            fill: #ff0000;
                          }
            
                        }
                      }
                    
            
                    }
            
                    &__tik-tok {
                        cursor: pointer;
                
                        width: 50px;
                        height: 50px;
                
                        border: solid 3px;
                        background-color: #000000;
                        border-radius: var(--main-panel-radius);
                
                        -webkit-box-shadow: 8px 8px 24px -12px rgba(66, 68, 90, 1);
                        -moz-box-shadow: 8px 8px 24px -12px rgba(66, 68, 90, 1);
                        box-shadow: 8px 8px 24px -12px rgba(66, 68, 90, 1);
                
                      svg {
                        position: relative;
                        top: 5px;
                        left: 5px;
                        width: 40px;
                        height: 40px;
                      }
            
                      &:hover {
                          transform: scale(1.05);
                          -webkit-transition: all .3s;
                          -moz-transition: all .3s;
                          transition: all .3s;
                        }
                
                    }
            
                    &__instagram {
                      cursor: pointer;
                      width: 50px;
                      height: 50px;
            
                      margin-left: 25px;
            
                      -webkit-box-shadow: 8px 8px 24px -12px rgba(66, 68, 90, 1);
                      -moz-box-shadow: 8px 8px 24px -12px rgba(66, 68, 90, 1);
                      box-shadow: 8px 8px 24px -12px rgba(66, 68, 90, 1);
            
                      img {
                        width: 110%;
                        height: 110%;
                        border-radius: var(--main-panel-radius);
                      }
            
                      svg {
                        position: relative;
                        top: 5px;
                        left: 5px;
                        width: 40px;
                        height: 40px;
                      }
            
                        &:hover {
                            transform: scale(1.05);
                            -webkit-transition: all .3s;
                            -moz-transition: all .3s;
                            transition: all .3s;
                        }
                
                    }
                }
    
                &__image {
                    width: 100%;
                    height: auto;
                }

                &__discord {
                    display: flex;
                    position: relative;

                    font-size: 20px;
                    margin-bottom: 30px;

                    &__logo {

                        cursor: pointer;
                        background-color: #5a65ea;

                        border-bottom: 2px solid rgb(68, 79, 199);

                        width: min-content;
                        border-radius: 15px;

                        padding: 5px 10px 3px 10px;

                        -webkit-box-shadow: 8px 8px 24px -13px rgba(66, 68, 90, 1);
                        -moz-box-shadow: 8px 8px 24px -13px rgba(66, 68, 90, 1);
                        box-shadow: 8px 8px 24px -13px rgba(66, 68, 90, 1);


                        svg {
                            position: relative;
                            width: 36px;
                            height: 36px;
                            top: 3px;
                        }
                    }

                    img {
                        position: absolute;
                        width: 80px;
                        height: auto;

                        margin-left: 55px;

                        top: -10px;

                        transform: rotate(50deg) scaleY(-1) ;
                    }

                    span {
                        display: block;
                        margin-left: 72px;
                        margin-top: 10px;

                        b {
                            font-weight: 500px;
                            color: #5a65ea;
                        }
                    }
                }

                &__about-us {

                    margin-top: 25px;
                    display: flex;



                    span {
                        padding-left: 5px;
                        display: block;

                        font-size: 20px;
                        line-height: 28px;
                    }

                    a {
                        text-decoration: none;
                        color: black;

                        margin-left: 20px;
                        margin-right: 0px;
                        
                        margin-top: auto;
                        margin-bottom: auto;
                        
                        padding: 10px 20px;
                        border: 2px solid #383838;
                        font-weight: 500;
                        cursor: pointer;
                        -webkit-user-select: none;

                        border-radius: 12px;

                        background-color: #ffff;

                        font-size: 16px;
                        font-weight: 500;

                        max-height: 45px;

                        white-space: nowrap;
                    }
                }
            }

            &__right {

                margin-top: 0px;

                @media only screen and (max-width: 950px) {

                    width: 100%;

                    padding-left: 10px;
                    padding-right: 10px;
                }

                @media only screen and (min-width: 951px) {
                    margin-left: 100px;
                    width: 45%;      
                    display: flex;
                }

  

                &__column-1 {
                  
                    @media only screen and (max-width: 950px) {

                        width: 100%;
                    }
    
                    @media only screen and (min-width: 951px) {
                        width: 50%;
                    }
    
                }

                &__column-2 {


                    @media only screen and (max-width: 950px) {

                        width: 100%;
                    }
    
                    @media only screen and (min-width: 951px) {
                        margin-left: 20px;
                        width: calc(50% - 20px);
                    }
                }

                .opinion {

                    background-color: #f9f9f9;
                    padding: 24px;

                    border: 1px solid var(--spacer-color);
                    border-radius: 15px;

                    font-size: 20px;

                    margin-bottom: 20px;

                    -webkit-box-shadow: 8px 8px 24px -22px rgba(66, 68, 90, 1);
                    -moz-box-shadow: 8px 8px 24px -22px rgba(66, 68, 90, 1);
                    box-shadow: 8px 8px 24px -22px rgba(66, 68, 90, 1);

                    &--paddingless {
                        padding: 0px 0px 24px 0px !important;

                        span {
                            display: block;
                            padding-top: 15px;
                            padding-left: 24px;
                            padding-right: 24px;
                        }

                        .opinion__name {
                            margin-top: 7px;
                        }
                        
                    }

                    &__name {
                        color: #bdbdbd;
                    }

                    &__course {
                        color: #bdbdbd;
                    }

                    b {
                        font-weight: 500;
                        background-color: #f1e437a4;
                    }

                    img {
                        width: 100%;
                        border-top-left-radius: 15px;
                        border-top-right-radius: 15px;

                    }
                }

            }
        }

    }
}