header {
    // .login-naviation {
    //     margin-bottom: 0px !important;
    //     z-index: 3;
    //     position: relative;
    // }

    .marginLess {
        margin-bottom: 0px !important;
    }


    .notifications-wrapper {
        z-index: 3;

        @media only screen and (max-width: 950px)  {

            width: calc(100%);
            top: 72px;
            position: fixed;
        }
    }

    .discord-information {
        background-color: #5a65ea;

    }

    .password-information {
        background-color: #2e9540;
    
        // @media only screen and (max-width: 950px)  {
        //     top: 72px;
        // }

        @media only screen and (min-width: 951px)  {

        }
    }

    .password-information, .discord-information {    
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 1px;

        color: #ffff;

        display: flex;
        justify-content: space-between;

        &.additional-margin {
            margin-bottom: 20px;
        }

        @media only screen and (max-width: 950px)  {
            padding-bottom: 11px;
        }

        @media only screen and (min-width: 951px)  {
            padding-bottom: 3px;
        }

        &__text-section {
            display: flex;


            &__hand {
                margin-right: 10px;
                @media only screen and (max-width: 950px)  {
                    display: none;
                }
            }

            &__text {
                position: relative;

                top: 5px;

                &.desktop {
                    @media only screen and (max-width: 950px)  {
                        display: none;
                    }
                }

                &.mobile {
                    @media only screen and (min-width: 951px)  {
                        display: none;
                    }
                }
            }

            b {
                font-weight: 500;
            }
        }

        svg {
            position: relative;
            fill: #ffff;
            cursor: pointer;

            @media only screen and (max-width: 950px)  {
                
                top: 7px;
                height: 35px !important;
                width: 35px !important;
            }
    
            @media only screen and (min-width: 951px)  {
                top: 3px;
                height: 22px;
                width: 22px;
            }
        }
    }

    #navigation {
        display: flex;
        justify-content: space-between;
        
        height: 72px;

        width: 100%;
       
        background-color: rgb(255, 255, 255);

        @media only screen and (max-width: 950px)  {
            position: fixed;
            z-index: 3;
            top: 0;
            width: 100%;

            -webkit-box-shadow: 0px 10px 17px -20px rgba(66, 68, 90, 1);
            -moz-box-shadow: 0px 10px 17px -20px rgba(66, 68, 90, 1);
            box-shadow: 0px 10px 17px -20px rgba(66, 68, 90, 1);
        }

        @media only screen and (min-width: 1695px) {
            padding-bottom: 4px;
        }

        &__logo-wrapper {
            width: 182px;
            cursor: pointer;
        }
        
        &__user-panel {
            display: flex;

            @media only screen and (min-width: 1695px) {
                display: none;
            }

            &__my-courses {
                border-radius: var(--panel-radius);
                margin: 15px 0px;
                margin-right: 20px;

                padding: 10px 20px;
                border: 2px solid var(--highlited-color);
                font-weight: 500;

                cursor: pointer;
        
                -webkit-user-select: none; /* Safari */        
                -moz-user-select: none; /* Firefox */
                -ms-user-select: none; /* IE10+/Edge */
                user-select: none; /* Standard */

                &:hover {
                    border-color: var(--button-border-only-hover-color) !important;
                }

                &:active {
                    transform: scale(0.98);
                }

                @media only screen and (max-width: 950px)  {
                    display: none;
                }
            }

            .active {
                img {
                    opacity: 0.0;
                }

                .material-symbols-outlined {
                    position: absolute;
                    transform: rotate(270deg) scale(2);

                    font-variation-settings: 'wght' 250;  
                    top: 20px !important;
                    left: 11px !important;
                    color: #ffff;
                    background-color: var(--highlited-color);
                    border-radius: 6px;

                    
                    padding-top: 5px;
                    padding-bottom: 3px;
                    padding-left: 0px;
                    padding-right: 0px;
                }

                &:hover {
                    .material-symbols-outlined {
                        background-color: var(--button-full-hover-color);
                    }
                }
            }

                            
            &__my-profile {
                height: 100%;
                padding-right: 35px;
                padding-left: 20px;
                width: 40px;

                z-index: 2;

                background-color: #ffff;
                border-left: var(--spacer-width) solid var(--spacer-color);
        
                -webkit-user-select: none; /* Safari */        
                -moz-user-select: none; /* Firefox */
                -ms-user-select: none; /* IE10+/Edge */
                user-select: none; /* Standard */

                &__icon-bundle{

                    display: flex;
                    position: relative;
                    left: 5px;

                    cursor: pointer;

                    .material-symbols-outlined {
                        position: relative;
                        top: 25px;
                        left: 5px;

                        transform: scale(1.5);
                        font-variation-settings: 'wght' 200;
                    }


                    img {
                        top: 23px;
                        position: relative;
                        width: 20px;
                        height: auto;
                    }

                    @media only screen and (max-width: 950px)  {
                        display: none;
                    }
                }

                &__hamburger-menu {
                    display: block;
                    position: relative;
                    cursor: pointer;
                    
                    -webkit-user-select: none;
                    user-select: none;

                    top: 25px;
                    left: 11px;

                    span {
                        display: block;
                        width: 35px;
                        height: 3px;
                        margin-bottom: 7px;
                        position: relative;
                        
                        background: var(--highlited-color);
                        
                        border-radius: var(--panel-radius);
                        
                        transform-origin: 4px 4px;
                        
                        transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                                    opacity 0.55s ease;

                        &:first-child {
                            transform-origin: 0% 0%;
                        }

                        &:nth-last-child(2) {
                            transform-origin: 0% 100%;
                        }
                    }

                    @media only screen and (min-width: 951px)  {
                        display: none;
                    }
                }

                .active {
                    span {
                        opacity: 1;

                        &:nth-last-child(1) {
                            transform: translateX(6px) rotate(-45deg);
                        }

                        &:nth-last-child(2) {
                          opacity: 0;
                        }

                        &:nth-last-child(3) {
                          transform: translateX(6px) rotate(45deg);
                        }
                    }
                }


            }
        }
    }

    .rolledUserMenu {
        position: absolute;

        z-index: 1;

        transition-delay: 50ms;
        transition-timing-function: ease-in-out;
        transition-duration: 0.5s;

        justify-content: right;

        background-color: #ffff;

        @media only screen and (max-width: 950px)  {
            top: 71px;
            width: 100%;
            left: 100%;
        }

        @media only screen and (min-width: 951px)  {
            top: 0;
            right: -600px;
            display: flex;
            padding-right: 4px;
        }

        @media only screen and (min-width: 1696px)  {
            display: none !important;
        }

        &--active {
            @media only screen and (max-width: 950px)  {
                position: fixed;
                z-index: 3;
                left: 0px;

                -webkit-box-shadow: 0px 10px 17px -20px rgba(66, 68, 90, 1);
                -moz-box-shadow: 0px 10px 17px -20px rgba(66, 68, 90, 1);
                box-shadow: 0px 10px 17px -20px rgba(66, 68, 90, 1);
            }
    
            @media only screen and (min-width: 951px)  {
                right: 96px;
            }
        }

        &__element {
            display: flex;
            position: relative;
            cursor: pointer;

            margin-right: 0px;

            padding: 24px 22px 24px 0px;

            @media only screen and (max-width: 950px)  {
                padding-left: 22px;
                background-color: var(--highlited-light-color);

                --border-color: #c9e7ea;
                border-top: 1px solid var(--border-color);
                border-bottom: 1px solid var(--border-color);
            }

            @media only screen and (min-width: 951px)  {
                margin-left: 22px;
                border-right: 1px solid var(--spacer-color);
            }

            &--first {
                @media only screen and (max-width: 950px)  {
                    border-top: 1px solid var(--spacer-color);
                }
    
                @media only screen and (min-width: 951px)  {
                    border-left: 1px solid var(--spacer-color);
                    padding-left: 22px;
                }
            }

            &--last {
                @media only screen and (max-width: 950px)  {
                    border-bottom: 1px solid var(--spacer-color);
                }

                @media only screen and (min-width: 951px)  {
                    border-right: none !important; 
                }
            }

            &--language {
                @media only screen and (min-width: 951px)  {
                    display: none;
                }
            }

            &__text {
                font-size: 16px;

                display: block;
                margin-top: auto;
                margin-bottom: auto;
                margin-left: 15px;

                white-space: nowrap;
            }

            .material-symbols-outlined {
                display: block;
                position: relative;
            }

            &:hover {
                @media only screen and (min-width: 951px)  {
                    color: var(--button-full-hover-color);
                }
            }
        }
    }

}