@mixin skeleton-common {
  background-color: #eee;
  border-radius: 10px;
  color: #eee;
  animation: pulse 1s infinite ease-in-out;
}

@keyframes pulse {
  0% {
      color: #eee;
      background-color: #eee;
  }
  50% {
      color: #e0e0e0;
      background-color: #e0e0e0;
  }
  100% {
      color: #eee;
      background-color: #eee;
  }
}