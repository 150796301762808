.checkout-wrapper {

    cursor: default;

    z-index: 3;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;


    @media only screen and (max-width: 950px) {
        background-color: #ffff;
    }

    @media only screen and (min-width: 951px) {
        -webkit-backdrop-filter: blur(3px);
        backdrop-filter: blur(3px);
        background-color: rgba(0, 0, 0, 0.345);
    }
}

.send-notification-when-available {
    &.active {
        background-color: #40aab8;
        &:hover{
            background-color: #389ca9;
        }
        color: white;
    }

    &.inactive {
        border-color: #aaaaaa;
        background-color: rgb(222, 222, 222);
        color: #a1a1a1;
    }
    border: none;
    
    border-radius: 5px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    padding-top: 12px;
    padding-right: 20px;
    padding-bottom: 12px;
    padding-left: 20px;
    box-shadow: none;
    width: 50%;    
}

.checkout {
    z-index: 4;
    background-color: #ffff;

    -webkit-transition: max-height 1s; 
    -moz-transition: max-height 1s; 
    -ms-transition: max-height 1s; 
    -o-transition: max-height 1s; 
    transition: max-height 1s;  

    padding-left: 20px;
    padding-right: 20px;


    &--format-1 {
        @media only screen and (min-width: 951px) {
            height: auto;
            max-height: 650px;
        }
    }

    &--format-2 {
        @media only screen and (min-width: 951px) {
            height: 566px;
            max-height: 566px;
        }
    }

    &--format-3 {
        @media only screen and (min-width: 951px) {
            height: 600px;
            max-height: 600px;
        }
    }

    &--upsell {
        @media only screen and (min-width: 951px) {
            height: 80%;

            max-height: 590px !important;
            width: 70% !important;
            max-width: 900px !important;

          
        }
    }

    @media only screen and (max-width: 950px) {
        width: calc(100% - 40px);

        overflow-y: auto; 
        height: calc(100dvh) !important;
    }


    @media only screen and (min-width: 951px) {
        position: fixed;
        left: 50%;
        transform: translateX(-50%);

        border: 1px solid var(--spacer-color);

        -webkit-box-shadow: 6px 6px 24px -4px rgba(66, 68, 90, 1);
        -moz-box-shadow: 6px 6px 24px -4px rgba(66, 68, 90, 1);
        box-shadow: 6px 6px 24px -4px rgba(66, 68, 90, 1);

        padding-top: 30px;
        padding-bottom: 30px;

        overflow-y: auto;
    }


    
    // @media only screen and (max-width: 950px) and (max-height: 637px) {
    //     overflow-y: auto;

    //     --scroll-buffer: 60px; // equals to padding

    //     height: calc(100vh - var(--scroll-buffer)) !important;
    //     max-height: calc(100vh - var(--scroll-buffer)) !important;
    // }

    @media only screen and (min-width: 951px) {
        border-radius: var(--panel-radius);
        width: 415px;
    }

    @media only screen and (min-width: 951px) and (max-height: 637px) {
        top: 5vh !important;
        overflow-y: scroll;
        height: calc(90vh - 60px) !important;
    }

    @media only screen and (min-width: 951px) and (min-height: 638px) {
        top: 50%;
        transform: translate(-50%, -50%);
    }

    /* width */
  &::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  &::-webkit-scrollbar-track {
    margin-top: 15px;
    margin-bottom: 15px;
    box-shadow: inset 0 0 5px #cdcdcd; 
    border-radius: var(--panel-radius);
  }
   
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #389ca9; 
    border-radius: 10px;
  }
  
    &__popup {

        position: absolute;
        margin: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        
        height: min-content;
        text-align: center;

        z-index: 3;

        width: 75%;

        background-color: #ffff;
        border-radius: var(--panel-radius);

        padding: 15px;

        text-align: center;

        &__headline {
            padding-bottom: 10px;
            margin-bottom: 15px;
            border-bottom: 1px solid var(--spacer-color);
        
            text-align: center;
        
            font-size: 18px;
            font-weight: 500;
        }

        &__message {
            padding-left: 10px;
            padding-right: 10px;
        }

        &__close-button {
            margin-top: 30px;
            cursor: pointer;

            padding-top: 12px;
            padding-bottom: 12px;

            background-color: #40aab8;
            border-radius: 5px;

            color: #ffff;
            font-weight: 500;

            &:hover {
                background-color: #389ca9;
            }

            &:active {
                transform: scale(0.98);
            }
        }
    }

    &__mask {
        position: absolute;


        @media only screen and (max-width: 950px) {
            --width: 100vh;
        }

        @media only screen and (min-width: 951px) {
            --width: calc(415px + 44px);
        }

        &--simplified {
            @media only screen and (max-width: 950px) {
                --height: 100vh
            }

            @media only screen and (min-width: 951px) {
                --height: calc(600px + 63px);
            }
        
        }

        &--normal {
            --height: calc(600px + 63px);
        }


        height: var(--height);

        width: var(--width);

        top: -1px;
        left: -2px;

        border-radius: var(--panel-radius);

        background-color: rgba(0, 0, 0, 0.345);

        -webkit-backdrop-filter: blur(3px);
        backdrop-filter: blur(3px);

        z-index: 2;

        &--fallback-height {
            top: -2px;
            height: 564px;
        }
    }

    &__signup-box {
        position: relative;

        &__title {

            @media only screen and (max-width: 950px) {
                padding-top: 25px;
            }

            font-weight: 600;
            font-size: 26px;

            margin-bottom: 15px;

            svg {
                width: 32px;
                height: 32px;

                position: relative;

                top: 6px;
                margin-right: 10px;
            }

                display: flex;
                justify-content: space-between;

            &__mobile-close-button {

                &.buy-all-courses-upsell {

                    @media only screen and (min-width: 951px) {
                        position: absolute;
                        right: 10px;
                        top: 10px;

          
                    }

                }

                cursor: pointer;

                svg {
                    fill: #3a3a3a;
                    position: relative;
                    left: 13px;
                    top: 8px;
                    opacity: 0.2;
                    transition: opacity 0.3s ease; 
                }

                &:hover {
                    svg {
                        opacity: 1.0;
                    }
                }

                &:active {
                    transform: scale(0.95);
                }
            }
        }

        &__infomertial {
            color: #3a3a3a;
            line-height: 20px;

            margin-bottom: 20px;
            padding-bottom: 15px;

            border-bottom: 1px solid var(--spacer-color);

            padding-top: 15px;
            border-top: 1px solid var(--spacer-color);        
        }

        &__signup-login-buttons {
            display: flex;
            justify-content: space-between;

            margin-bottom: 20px;

            padding-bottom: 13px;
            border-bottom: 1px solid var(--spacer-color);

            padding-top: 13px;
            border-top: 1px solid var(--spacer-color);  
            
            &__button {
                width: 47%;
                text-align: center;

                border: 1px solid var(--spacer-color);

                border-radius: 5px;

                padding-top: 11px;
                padding-bottom: 10px;

             
                cursor: pointer;

                &--active {
                    font-weight: 500;
                    color: #ffff;
                    background-color: var(--valid-color);
                    border-color: var(--valid-color);
                }

                &--inactive {
                    border-color: #3a3a3a;
                }

                &__text {
                    &--desktop {
                        @media only screen and (max-width: 950px) {
                            display: none;
                        }
                    }

                    &--mobile {
                        @media only screen and (min-width: 951px) {
                            display: none;
                        }
                    }
                }
            }
        }

        &__email-box, &__password-box, &__email-box--notify{
            width: 100%;

            @media only screen and (max-width: 950px) {
                position: relative;
            }  

            @media only screen and (min-width: 951px) {
                position: absolute;
            }  

            &.visible {
                z-index: 3;

                input {
                    -webkit-box-shadow: 8px 8px 24px -16px rgba(66, 68, 90, 1);
                    -moz-box-shadow: 8px 8px 24px -16px rgba(66, 68, 90, 1);
                    box-shadow: 8px 8px 24px -16px rgba(66, 68, 90, 1);        
                }
            }

            span {
                display: block;
                margin-bottom: 7px;
                font-weight: 500;
                padding-left: 2px;
            }

        

            input {
                --padding: 12px;
                width: calc(100% - var(--padding) * 2 - 2px);

                margin-bottom: 30px;
                font-size: 16px;

                padding: var(--padding);
                border: none;
                border: solid 1px;
                border-radius: 5px;
                border-color: #a1a1a1;

                margin-bottom: 25px;

                &.valid {
                    border-color: #40aab8 !important;
                }

                &.invalid {
                    border-color: #b70f0f !important;
                }
            }



            .warning {
                svg {
                    @media only screen and (max-width: 950px) {
                        top: 38px;
                    }
                
                    @media only screen and (min-width: 951px) {
                        top: 36px;
                    }
                }

                &--invalid {
                    svg {
                        fill: #b70f0f;
                    }
                }

                &--valid {
                    svg {
                        fill: #40aab8;
                    }
                }
            }

            .valid {
                svg {
                    @media only screen and (max-width: 950px) {
                        top: 39px;
                    }
                
                    @media only screen and (min-width: 951px) {
                        top: 36px;
                    }
                }

                &--invalid {
                    svg {
                        fill: #b70f0f;
                    }
                }

                &--valid {
                    svg {
                        fill: #40aab8;
                    }
                }
            }



            svg {
                position: absolute;
                right: 10px;

                width: 24px;
                height: 24px;
            }

        }

        &__email-box {

        }

        &__password-box {

            &--first {
                @media only screen and (min-width: 951px) {
                    top: 260px;
                }
            }

            &--second {

                @media only screen and (min-width: 951px) {
                    top: 355px; 
                }           
            }
                
        }

        &__errors {
            z-index: 3;

            position: absolute;
            height: auto;

            padding: 5px 15px 10px 15px;
            border-radius: var(--panel-radius);

            background-color: #ffff;
        
            -webkit-box-shadow: 8px 8px 24px -16px rgba(66, 68, 90, 1);
            -moz-box-shadow: 8px 8px 24px -16px rgba(66, 68, 90, 1);
            box-shadow: 8px 8px 24px -16px rgba(66, 68, 90, 1);

            &--display {

            }

            &--hide {
                display: none;
            }

            label {
                display: block;
                margin-bottom: 5px;
            }

            &__triangle {
                // position: absolute;
                position: relative;
                top: -19px;

                width: 0; 
                height: 0; 
                border-left: 12px solid transparent;
                border-right: 12px solid transparent;
                
                border-bottom: 15px solid #ffff;
            }
        }


        &__login-ending {

            &__login-text {
                margin-top: 50px;
                margin-bottom: 20px;

                &__headline {
                    font-weight: 600;
                    font-size: 18px;

                    text-align: center;
                    margin-bottom: 15px;
                }

                &__main-text {
                    line-height: 20px;
                    color: #3a3a3a;
                }

            }

            &--displayed {

                .checkout__signup-box__email-box {
                    @media only screen and (min-width: 951px) {
                        top: 270px !important;
                    } 
                }
    
                .checkout__signup-box__password-box {
                    @media only screen and (min-width: 951px) {
                        top: 365px !important;
                    } 
                }

                .checkout__signup-box__email-box--notify {
                    position: relative;
                }
            }

            &--hidden {
                .checkout__signup-box__login-ending__login-text {
                    display: none;
                }
            }
        }

        &__signup-ending {

            &--displayed {

            }

            &--hidden {
                display: none;
            }

            .form__checkbox {
                @media only screen and (min-width: 951px) {
                    position: absolute;

                    top: 445px;
                }  
    
            }

        }

        &__finalize-transaction {

            @media only screen and (min-width: 951px) {
                position: absolute;
            }  

            &--format-2 {
                @media only screen and (min-width: 951px) {
                    top: 500px;        
             
                }
            }
        

            &--format-3 {
                @media only screen and (min-width: 951px) {
                    top: 540px;        
            
                }
            }

            &--upsell {
                @media only screen and (max-width: 950px) {
                    margin-bottom: 70px;      
                    
                }

                @media only screen and (min-width: 951px) {
                    padding-bottom: 40px;
            
                }
            }


            padding-top: 20px;

            display: flex;
            justify-content: center;

            width: 100%;

            &__button {
                justify-content: center;
                width: min-content;
                white-space: nowrap;

                border-radius: 5px;
                font-size: 16px;
                font-weight: 500;

                text-align: center;

                border: solid 1px;
                cursor: pointer;

                @media only screen and (max-height: 637px) {
                    margin-bottom: 40px;
                }

                @media only screen and (max-width: 950px) {
                    width: 100%;
                    padding-top: 15px;
                    padding-bottom: 15px;
                }
            
                @media only screen and (min-width: 951px) {
                    width: 80%;
                    padding-top: 12px;
                    padding-bottom: 12px;
                }

                &--inactive {
                    border-color: #aaaaaa;
                    color: #a1a1a1;
                    background-color: rgb(222, 222, 222);
                }

                &--active {
                    border-color: #40aab8;
                    color: white;
                    background-color: #40aab8;

                    &:hover {
                        background-color: #389ca9;
                    }
        
                    &:active {
                        transform: scale(0.98);
                    }

                    &.upsell-button-desktop {
                        @media only screen and (min-width: 951px) {
                            width: 30% !important;
                            height: 20px !important;
                        }
                    }
                }
            }

            &__loading {

                margin-left: auto;
                margin-right: auto;
                display: flex;
                justify-content: center;

                width: 100%;

                @media only screen and (min-width: 951px) {
                    position: absolute;
                    border-top: 1px solid var(--spacer-color);
                }  
    
                &--format-2 {
                    @media only screen and (min-width: 951px) {
                        top: 502px;          
                 
                    }
                }
            
                &--format-3 {
                    @media only screen and (min-width: 951px) {
                        top: 542px;        
                
                    }
                }

                .lds-ellipsis {
                    display: inline-block;
                    position: relative;
                    width: 80px;
                    height: 80px;
                    box-sizing: border-box;

                    transform: scale(0.8);

                    color: #389ca9;
                  
                    div {
                      position: absolute;
                      top: 33.33333px;
                      width: 13.33333px;
                      height: 13.33333px;
                      border-radius: 50%;
                      background: currentColor;
                      animation-timing-function: cubic-bezier(0, 1, 1, 0);
                      box-sizing: border-box;
                  
                      &:nth-child(1) {
                        left: 8px;
                        animation: lds-ellipsis1 0.6s infinite;
                      }
                  
                      &:nth-child(2) {
                        left: 8px;
                        animation: lds-ellipsis2 0.6s infinite;
                      }
                  
                      &:nth-child(3) {
                        left: 32px;
                        animation: lds-ellipsis2 0.6s infinite;
                      }
                  
                      &:nth-child(4) {
                        left: 56px;
                        animation: lds-ellipsis3 0.6s infinite;
                      }
                    }
                  
                    @keyframes lds-ellipsis1 {
                      0% {
                        transform: scale(0);
                      }
                      100% {
                        transform: scale(1);
                      }
                    }
                  
                    @keyframes lds-ellipsis2 {
                      0% {
                        transform: translate(0, 0);
                      }
                      100% {
                        transform: translate(24px, 0);
                      }
                    }
                  
                    @keyframes lds-ellipsis3 {
                      0% {
                        transform: scale(1);
                      }
                      100% {
                        transform: scale(0);
                      }
                    }
                  }
                  
            }
        }

        &__simplified-box {
            
            width: 100%;

            @media only screen and (min-width: 951px) {
                position: absolute;
            }  

            @media only screen and (min-width: 951px) {
            
                top: 230px;        
          
            }

            &__description {
                padding-left: 2px;

                display: block;

                color: #808080;

                margin-bottom: 3px;

                @media only screen and (max-width: 950px) {
                    display: flex;            
                }

                svg {
                    width: 16px !important;
                    height: 16px !important;
                    position: relative;
                    top: 3px;

                    margin-right: 5px;
                    fill: #808080;

                    @media only screen and (max-width: 950px) {
                        display: block;            
                    }
                }
            }

            &__payment-selection {

                margin-top: 15px;
                border-top: 1px solid var(--spacer-color);

                &__label {
                    display: block;
                    margin-top: 17px;
                    margin-bottom: 7px;
                    font-weight: 500;
                    padding-left: 2px;
        
                }
                
                
                &__box {
                    // border: 1px solid var(--spacer-color);

                    padding: 10px 0px 10px 0px;

                    &__option {
                        display: flex;
                        margin-bottom: 10px;


                        input {
                            height: 15px;
                            width: 15px;
                            vertical-align: middle;
                        }

                        &__checkout {
                            position: relative;
                            top: -3px;
                        }

                        &__upsell {
                            
                        }





                        label {
                            display: block;
                            // display: flex;


                            .blik {
                                width: 39px;
                                height: 19px;
                                margin-left: 10px;
                            }

                            .apple-pay {
                                // width: 39px;
                                // height: 39px;
                                margin-left: 10px;

                                position: relative;
                                top: -2px;
                            }

                            .apple-pay-span {
                                top: -10px;   
                            }

                            .visa {
                                width: 39px;
                                height: 19px;
                                margin-left: 10px;
                            }

                            .mastercard {
                                width: 39px;
                                height: 19px;

                                // transform: scale(0.5);
                            }

                            .przelewy24 {
                                width: 39px;
                                height: 19px;
                                margin-left: 10px;
                            }


                            svg {
                                // width: 100px;
                            }
                        }

                        input {
                            margin-right: 10px;
                        }
                    }

                }
            }

            &__label {
                display: block;

                margin-left: 2px;
                font-size: 14px;

                color: #3a3a3a;

                b {
                    font-weight: 500;
                }
            }

        }


    }

    &__buy-all-courses-upsell {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;


        @media only screen and (min-width: 951px) {
            height: 100%;
        }

        &__sections-wrapper {
            overflow-x: hidden !important;

            @media only screen and (min-width: 951px) {
                // flex: 1;
                overflow-y: auto;
            }

            &__first-section {
                @media only screen and (min-width: 951px) {
                    width: 40% !important;
                }
            }
    
            &__second-section {
                @media only screen and (min-width: 951px) {
                    width: 50% !important;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    overflow-x: hidden;
                }
            }   

            @media only screen and (min-width: 951px) {
                display: flex;  
                flex-direction: row;
                justify-content: space-between;  
            }
        }

        .checkout__signup-box__finalize-transaction {
            display: flex;
            justify-content: center; // Center the button container
            margin-top: 20px;
            
            @media only screen and (min-width: 951px) {
                position: relative;
                width: 100%;
            }
    
            &__button {
                width: 100%;
                min-width: 200px; 
                padding-left: 40px !important; 
                padding-right: 40px !important;
                
                @media only screen and (max-width: 950px) {
                    width: 100% !important; 
                }
            }
        }

        &__sum-savings {
            margin: 10px auto;
            padding: 15px 20px;
            font-weight: 500;
            font-size: 16px;
            background: white;
            border-radius: 8px;
            box-shadow: 0 4px 8px rgba(0,0,0,0.2);
            text-align: center;
            width: 90%;

            @media only screen and (max-width: 950px) {
                margin-bottom: 20px;
                padding-top: 15px;
            }


            &__container {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                text-align: left;

                .bullet-point {
                    display: flex;
                    flex-direction: row;

                    .bullet-point__icon {
                        font-size: 28px;
                        margin-right: 5px;
                    }

                    .bullet-point__text {
                        margin: auto;
                        font-size: 16px;
                    }
                }

            }

            .--sum-savings--heavy {
                font-weight: 600;
            }
        }

        &__course-properties--wrapper {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            @media only screen and (min-width: 951px) {
                padding-bottom: 15px;
                margin-top: 20px;
            }
        }

        &__property {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            width: 25%;  // Give each property a fixed width
            gap: 8px;    // Add space between icon and text

            svg {
                width: 25px;
                height: 25px;
            }

            &.--highlight {
                svg {
                    fill: var(--yellow-color);
                }
            }

            &.--normal {
                svg {
                    fill: var(--valid-color);
                }
            }

            &__text {
                display: flex;
                flex-direction: column;
                font-weight: 500;
                font-size: 14px;  // Slightly reduce font size if needed
                line-height: 1.2; // Tighten line height

                &--heavy {
                    font-weight: 600;
                }
            }
        }

        .--sum-strike-through {
            color: red;
            text-decoration: line-through;
        }

        .--strike-through {
            text-decoration: line-through;
        }

        .--low-opacity {
            opacity: 0.7;
        }

        .--sum-savings--highlight {
            font-weight: 600;
            color: green;
        }

        .--heavy {
            font-weight: 600;
        }

        &__image {
            &__wrapper {
                display: flex;
                flex-direction: row;
                justify-content: center;
                gap: 20px;
                margin-bottom: 20px;

                @media only screen and (min-width: 951px) {
                    margin: 30px;
                }
            }
            &--container {
                display: flex;
                justify-content: center;
                position: relative;

                @media only screen and (max-width: 950px) {
                    width: 40%;
                }

                position: relative;

                --valid-color-dim: #d7eaed;

                --border-width: 3px;

                &:hover {
                    transform: scale(1.03);
                }

                .label {
                    position: absolute;
                    padding: var(--border-width);

                    &-top {
                        border-radius: 5px 5px 0 0;
                        top: -10px;
                    }

                    &-bottom {
                        border-radius: 0 0 5px 5px;
                        bottom: -10px;
                    }

                    &.--dim {
                        background-color: var(--valid-color-dim);
                    }

                    &.--highlight {
                        background-color: var(--valid-color);
                    }
                    
                    font-weight: 500;
                    font-size: 17px;
                    color: white;
                    width: 100%;
                    
                    text-align: center;
                    z-index: 100;
                }

                img {
                    width: 100%;
                    border-radius: 10px;
                    cursor: pointer;

                    &.--highlight {
                        box-shadow: 0 0 0 var(--border-width) var(--valid-color);
                        transition: box-shadow 0.2s ease-in-out;
                    }

                    &.--dim {
                        opacity: 0.5;
                        transition: opacity 0.2s ease-in-out;
                        box-shadow: 0 0 0 var(--border-width) var(--valid-color-dim);
                    }

                    @media only screen and (min-width: 951px) {
                        width: 100%;
                    }
                }
            }
        }
        
        &__title__secondary-text {
            @media only screen and (min-width: 951px) {
                font-size: 18px;
                font-weight: 500;
                margin-bottom: 10px;
                text-align: center;
                border-bottom: 1px solid var(--spacer-color);
                padding-bottom: 20px;
            }
        }
        
        &__title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-weight: 600;
            font-size: 26px;

            @media only screen and (max-width: 950px) {
                margin-bottom: 15px;
            }

            @media only screen and (min-width: 951px) {
                margin-bottom: 0;
            }

           

            @media only screen and (min-width: 951px) {
                display: flex;
                justify-content: center;
            }

            &__container {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 10px;

                .gift-icon {
                    font-size: 40px;
                }
            }
            
            @media only screen and (max-width: 950px) {
                margin-top: 20px;
            }

            svg {
                width: 32px;
                height: 32px;
                position: relative;
                top: 6px;
                margin-right: 10px;
            }
        }

        &__courses-list {

            @media only screen and (min-width: 951px) {
                margin: 0;
            }

            @media only screen and (max-width: 950px) {
                margin: 20px 0;
            }

            padding: 15px 0 0 0;
            border-top: 1px solid var(--spacer-color);
            border-bottom: 1px solid var(--spacer-color);

            @media only screen and (min-width: 951px) {
                border-bottom: none;

                &.first-list {
                    border-top: none;
                }
            }

            &.second-list {
                border-bottom: none;
            }

            h3{
                margin: auto;
                display: flex;
                justify-content: center;
                font-size: 18px;
                font-weight: 500;
                margin-bottom: 10px;
            }

            div {
                @media only screen and (min-width: 951px) {
                    &.line {
                        border-bottom: 1px solid var(--spacer-color);
                    }
                }
            }

            &__header {
                display: flex;
                justify-content: space-between;
                padding: 0 0 12px 0;
                font-weight: 500;
                color: #808080;
            }

            &__items-container {
                height: auto;
            }

            &__item {
                display: flex;
                justify-content: space-between;
                padding: 8px 0;
                color: #3a3a3a;
                height: auto;
                opacity: 1;
                transition: all 0.3s ease-in-out;

                &__name {

                    .--circle {
                        width: 10px;
                        height: 10px;
                        border-radius: 50%;
                        background-color: var(--yellow-color);
                        display: inline-block;
                        margin-right: 8px;
                        vertical-align: middle;
                    }
                    font-weight: 500;
                }

                &__price {
                    // color: red;
                    opacity: 0.5;
                }
            }
        }

        &__options {

            width: 100%;
            &__option {
                display: flex;
                align-items: center;
                padding: 12px;
                margin-bottom: 10px;
                border: 1px solid var(--spacer-color);
                border-radius: 5px;
                cursor: pointer;
                transition: all 0.2s ease;

                &:hover {
                    background-color: #f5f5f5;
                }

                &--selected {
                    border-color: #40aab8;
                    background-color: #f0f9fa;
                }

                input[type="radio"] {
                    margin-right: 12px;
                }

                label {
                    font-weight: 500;
                    cursor: pointer;
                }
            }
        }
    }
}