.selling-course-panel {
    @media only screen and (max-width: 950px)  {
        width: 90% !important;
        min-width: 90% !important;
        max-width: 350px !important;
    }

    @media only screen and (min-width: 951px)  {
        width: 350px !important;
        min-width: 350px !important;
    }
    
    &--first {
        @media only screen and (min-width: 951px) and (max-width: 1551px)  {
            margin-left: 3.5%;      
        }
    }

    &--last {
        @media only screen and (min-width: 951px) and (min-width: 1552px)  {
            margin-right: 0px !important;      
        }
    }

    @media only screen and (max-width: 950px)  {
        margin-left: auto;
        margin-right: auto;
    }

    @media only screen and (min-width: 951px)  {
        display: inline-block;
        margin-right: 30px; 
    }

    position: relative;

    overflow: hidden;

    &__discount-banner {
        background-color: #2c8793;

        position: absolute;

        z-index: 2;

        transform: rotate(40deg);

        color: var(--yellow-color);
        font-size: 20px;

        color: #ffff;
        background-color: #4a9f35;

        // color: var(--yellow-color);
        // background-color: #1a1a21; <- for black week


        padding: 5px 40px 5px 45px;
        right: -35px;
        top: 20px;

        

        svg {
            position: relative;

            margin-left: 10px;
            top: 3px;
            width: 20px;
            height: 20px;

            fill: #ffff;
            // fill: var(--yellow-color);
        }
    }

    &__main-name {
        margin-top: 8px !important;
        margin-left: 8px !important;
        margin-right: 8px !important;

        @media only screen and (min-width: 951px)  {
            height: 139px !important;
        }

        &__img {
            position: relative;

            img {
                @media only screen and (min-width: 951px)  {
                    max-width: 130px !important;
                }

                height: 92% !important;

                display: block !important;
                margin-top: 4% !important;
                margin-bottom: 4% !important;

                margin-left: 0px !important;
                padding-left: 10px !important;
                border-top-left-radius: var(--panel-radius);
                border-bottom-left-radius: var(--panel-radius);
            }
        }

        &__text {
            @media only screen and (min-width: 951px)  {
                margin-top: 40px !important;
            }
            padding-right: 20px !important;
        }
    }

    &__description {
        white-space: normal !important;
        height: 80px;
    }

    &__params {

        margin-left: 12px !important;
        margin-right: 12px !important;

        &__param {


            padding-left: 8px !important;
            padding-right: 8px !important;

            svg {
                position: relative;
                top: 7px;

                margin-right: 10px;
            }
        }
    }


    &__content-wrapper {


        &__price {

            width: calc(100% - 175px);
            display: flex;
            justify-content: center;
                    
            font-weight: 500;
            font-size: 18px;

            span {
                display: block;
                position: relative;
                
                top: 12px;
                left: -8px;
            }

            .small-price {
                font-size: 18px;
                margin-right: 10px;
                text-decoration: line-through;
                color: red;
            }

            .discounted-price {
                font-size: 20px;

            }            
        }

        &__button {
            white-space: nowrap;
            text-decoration: none;
            color: black;
            padding: 3px 20px 10px 20px !important;

            .material-symbols-outlined {

                position: relative !important;

                top: 5px !important;

                margin-right: 10px;

                font-variation-settings:
                'FILL' 0,
                'wght' 350,
                'GRAD' 0,
                'opsz' 48;
            }


            svg {
                position: relative;
                margin-right: 10px;
                top: 6px;

            }

            &--centered {
                margin-left: auto;
                margin-right: auto;

                .material-symbols-outlined {
                    position: relative;

                    left: 10px;
                    top: 6px !important;
                    margin-right: 7px;
                }
                
            }
        }

    }

    &__lesson-preview {

        justify-content: center;
        display: flex;
        
        font-size: 16px !important;
        font-weight: 600;

        padding-bottom: 8px;
        padding-top: 14px;

        color: #ffff !important;
        // background-color: #a62b6a;

        user-select: none;


        height: 22px;
        
        &.displayed {
            cursor: pointer;
            border-top: 1px solid var(--spacer-color);
            background-color: #3296a3;
    
            &:hover {
                background-color: #2c8793;            
            }
    
        }

        &.hidden {
            background-color: #4a9f35;
            border-top: 1px solid #fafafa;
            span {
                // color: rgb(58, 58, 58) ;
            }
        }



        width: 100% !important;
        margin-bottom: 0px !important;
    
        border-bottom-left-radius: var(--panel-radius);
        border-bottom-right-radius: var(--panel-radius);

        svg {
            position: relative;
            margin-right: 10px;

            top: -3px;
        }

        // margin-left: 12px !important;
        // margin-right: 12px !important;
        // width: calc(100% - 24px) !important;

    }
}