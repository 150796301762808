.error {
    background-color: #0d0d2b;
    width: 100%;
    
    @media only screen and (max-width: 950px)  {
        width: 100%;
    }

    @media only screen and (min-width: 951px)  {
        display: flex;
        --header-height: 72px;
        height: calc(100vh - var(--header-height));

        min-height: 650px;
    }

    &__text-section {

        color: #ffff;

        position: relative;

        @media only screen and (max-width: 950px)  {
            width: 100%;
        }
    
        @media only screen and (min-width: 951px)  {
            width: calc(50% - 60px);
            margin-left: 60px;
        }

        &__container {

            @media only screen and (max-width: 950px)  {
                margin-top: 72px;
                margin-left: 7.5%;
                margin-right: 7.5%;

                padding-top: 70px;
            }
        
            @media only screen and (min-width: 951px)  {
                margin: 0;
                position: absolute;
                top: 50%;
    
                max-width: 660px;
            }

            @media only screen and (min-width: 951px) and (max-width: 1417px)  {
                -ms-transform: translateY(-50%);
                transform: translateY(-50%);
            }
        
            @media only screen and (min-width: 1418px)  {
                left: 50%;
                -ms-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);

                width: max-content;
            }

            &__headline {
                font-size: 40px;
                font-weight: 600;

                margin-bottom: 10px;

                &--error-code {
                
                    @media only screen and (max-width: 950px)  {
                        font-size: 80px;
                    }

                    @media only screen and (min-width: 951px)  {
                        font-size: 120px !important;
                    }
                }
            }

            &__middle-text {
                font-size: 22px;

            }

            &__end-text {
                font-size: 16px;
                line-height: 22px;   

            }

            &__spacer {
                width: 85%;
                margin-top: 20px;
                margin-bottom: 30px;

                padding-bottom: 2px;
                
                border-bottom: 2px solid transparent;
                border-image: linear-gradient(0.25turn, var(--yellow-color), #ff0080, #0d0d2b);
                border-image-slice: 1;
                width:100%;
            }

            &__button {
                width: min-content;
                white-space:nowrap;

                cursor: pointer;
                
                -webkit-user-select: none; 
                
                padding: 10px 20px 10px 20px;

                font-size: 18px;
                color: #ffff;

                border-radius: var(--panel-radius);
                border: 1px solid #ffff;

                display: flex;

                transition: border-color 0.3s ease, color 0.3s ease, background-color 0.3s ease;

                &:hover { 
                    --hover-color: #b63879;
                    background-color: var(--hover-color);
                    // color: #1a1a1a !important;
                    border-color: var(--hover-color);
                }

                &:active {
                    transform: scale(0.98);
                }

                &--desktop {
                    margin-left: 0%;
                    margin-top: 60px;

                    @media only screen and (max-width: 950px)  {
                        display: none;
                    }
                }

                &--mobile {           
                    margin-top: 25px;
                    margin-left: auto;
                    margin-right: auto;

                    @media only screen and (min-width: 951px)  {
                        display: none;
                    }
                }
            }
        }
    }

    &__graphic {

        @media only screen and (max-width: 950px)  {
            height: auto;
            width: auto;
        }
    
        @media only screen and (min-width: 951px)  {
            height: 100%;

            width: 50%;
            height: auto;
        }

        @media only screen and (min-width: 1418px)  {
            display: flex;
        }

        svg {
            margin-top: 5%;

            @media only screen and (max-width: 950px)  {
                margin-left: 5%;
                margin-right: 5%;
                width: 90%;
                height: auto;
            }
        
            @media only screen and (min-width: 951px)  {
                height: 90%;
                width: auto;
            }

            @media only screen and (min-width: 1418px)  {
                margin-left: auto;
                margin-right: auto;
            }
        }
    }

    &__mobile-spacer {
        padding-top: 70px;
        @media only screen and (min-width: 951px)  {
            display: none;
        }   
    }

    .module-border-wrap {
        background: linear-gradient(#0d0d2b, #0d0d2b) padding-box,
        linear-gradient(to right,  var(--yellow-color), #ff0080) border-box;
        border-radius: var(--panel-radius);
        border: 2px solid transparent;            
    }
}