
.data-panel {
    // ... styled in style.scss

    &__quiz {

        &__name {
            display: flex;
            margin-top: 50px;
            margin-bottom: 30px;

            h4 {
                @media only screen and (max-width: 950px)  {
                    padding-top: 30px;
                }

                margin: 0px;
                margin-left: 2.5%;

                font-size: 18px;
                font-weight: 600;

                position: relative;

                &::before{
                    content: '';
                    position: absolute;
                    left: 0;
                    bottom: -10px;
                    background-color: var(--highlited-color);
                    height: 2px;
                    box-sizing: border-box;
                    width: 50px;
                }
            }
        }

        &__question {
            margin-top: 20px;

            &--mobile-hidden {
                @media only screen and (max-width: 950px)  {
                    display: none;
                }
            }

            &__text {
                margin: 0px 20px;
                line-height: 22px;
                color: #3a3a3a;
                font-size: 16px;
                text-align: justify;

                &.mainTest {
                    margin-left: 2.5vw !important;
                    margin-right: 2.5vw !important;
                }
            }

            &__img-wrapper {
                margin-top: 25px;
                margin-bottom: 25px;
                display: flex;

                img {
                    border-radius: 15px;
                    max-width: 535px;
                    margin-left: auto;
                    margin-right: auto;
                    width: 95%;
                    height: auto;

                    pointer-events: none;
                    user-select: none;
                }
            }
        }

        &__command {
            display: flex;
            margin-bottom: 40px;
            margin-top: 40px;

            &.mainTest {
                justify-content: center;
            }

            span {
                @media only screen and (max-width: 1275px)  {
                    margin-left: auto;
                    margin-right: auto;
                }

                @media only screen and (min-width: 1276px)  {
                    margin-left: 2.5%;
                }
            
                font-size: 18px;
                font-weight: 600;
            }
        }

        &__mobile-nav-bar {            
            margin-left: auto;
            margin-right: auto;
            width: 85%;
            margin-bottom: 30px;
            margin-top: 30px;
            display: flex;
            justify-content: space-between;

            @media only screen and (min-width: 950px)  {
                display: none;
            } 

            &__button {
                cursor: pointer;
        
                -webkit-user-select: none; /* Safari */        
                -moz-user-select: none; /* Firefox */
                -ms-user-select: none; /* IE10+/Edge */
                user-select: none; /* Standard */

                .material-symbols-outlined {
                    position: relative;
                    top: 5px;
            
                    transform: scale(1.2);

                    font-variation-settings:
                    'FILL' 0,
                    'wght' 400,
                    'GRAD' 0,
                    'opsz' 48;
                }

                &--active {
                    color: var(--highlited-color);
                }

                &--inactive {
                    color: rgb(210, 210, 210);
                }
            }

            &__wrapper {
                display: flex;
                justify-content: center;

                &__element {
                    border-radius: var(--panel-radius);
                    border: solid 2px var(--highlited-color);

                    padding: 5px 10px;
                    font-size: 16px;
                    margin: 0px 9px;

                    -webkit-user-select: none; /* Safari */        
                    -moz-user-select: none; /* Firefox */
                    -ms-user-select: none; /* IE10+/Edge */
                    user-select: none; /* Standard */

                    cursor: default;

                    &--active {
                        background-color: var(--highlited-color);
                        color: #ffffff;
                    }
                }
            }
        }

        &__mobile-tip {
            margin-top: 40px;
            margin-left: auto;
            margin-right: auto;
            width: 95%;
            display: flex;

            @media only screen and (min-width: 950px)  {
                display: none;
            } 

            &__symbol {
                color:#b7c1ce;
                margin-right: 10px;
            }

            &__text {
                margin-top: 4px;
                color:#b7c1ce;
            }
        }

        &__anwsers {
            --width: 325px;

            margin-left: auto;
            margin-right: auto;

            @media only screen and (max-width: 949px)  {
                width: 85%;
            } 

            &.main-test {
                @media only screen and (min-width: 950px)  {
                    display: flex !important;
                    flex-direction: row !important;
                    justify-content: center;
                }
            }

            @media only screen and (min-width: 950px)  {
                display: grid;
                grid-template-columns: 1fr 1fr;
    
                grid-column-gap: var(--distance);
                grid-row-gap: var(--distance);
            }

            @media only screen and (min-width: 950px) and (max-width: 1125px)  {
                width: 95%;
                --distance: 21px;
            }

            @media only screen and (min-width: 1126px)  {
                --distance: 40px;
                width: calc(var(--width)*2 + var(--distance));
            }
            

            &__anwser {
                align-self: center;
                justify-self: center;

                border-radius: var(--panel-radius);
                border: 2px solid var(--highlited-color);
                
                background-color: #f6fcfc;

                -webkit-box-shadow: 8px 8px 24px -21px rgba(66, 68, 90, 1); 
                -moz-box-shadow: 8px 8px 24px -21px rgba(66, 68, 90, 1); 
                box-shadow: 8px 8px 24px -21px rgba(66, 68, 90, 1);


                cursor: pointer;
        
                -webkit-user-select: none; /* Safari */        
                -moz-user-select: none; /* Firefox */
                -ms-user-select: none; /* IE10+/Edge */
                user-select: none; /* Standard */

                &:hover {
                    border-color: #2c8894;
                    background-color: #ecf9f9;

                    -webkit-box-shadow: 8px 8px 24px -18px rgba(66, 68, 90, 1); 
                    -moz-box-shadow: 8px 8px 24px -18px rgba(66, 68, 90, 1); 
                    box-shadow: 8px 8px 24px -18px rgba(66, 68, 90, 1); 

                    .data-panel__quiz__anwsers__anwser__index {
                        border-color: #2c8894 !important; 
                        background-color: #2c8894 !important;
                    }
                }

                &:active {
                    transform: scale(0.98);
                }

                @media only screen and (max-width: 949px)  {
                    width: 100%;
                    margin-bottom: 20px;
                } 

                @media only screen and (min-width: 950px)  {
                    max-width: var(--width);
                } 

                img {
                    width: 100%;
                    height: auto;

                    pointer-events: none;
                    user-select: none;

                    @media only screen and (max-width: 949px)  {
                        max-width: 350px;
                    } 

                    &.skeleton {
                        visibility: hidden;
                    }

                    @media only screen and (min-width: 1126px)  {
                        min-width: 300px;
                        min-height: 157px;
                    }

                    @media only screen and (min-width: 950px) and (max-width: 1125px) {
                        min-width: 258px;
                        min-height: 121px;
                    }

                    .main-test-row {
                        @media only screen and (min-width: 1126px)  {
                            min-width: 300px !important;
                            min-height: 157px !important;
                        }
    
                        @media only screen and (min-width: 950px) and (max-width: 1125px) {
                            min-width: 258px !important;
                            min-height: 121px !important;
                        }
                    }
                }

                &--hide-mobile {
                    @media only screen and (max-width: 950px)  {
                        display: none;
                    }
                }
                
                &--last {
                    @media only screen and (max-width: 949px)  {
                        margin-bottom: 0px;
                    } 
                }

                &--hidden {
                    @media only screen and (max-width: 949px)  {
                        display: none;
                    }
                }

                &__index {
                    padding: 5px 10px; 
                    width: min-content;


                    border-radius: var(--panel-radius) 0px var(--panel-radius) 0px;
                    border: solid 2px var(--highlited-color);

                    background-color: var(--highlited-color);

                    position: relative;
                    top: -2px; left: -2px;

                    color: #ffffff;
                    font-size: 18px;
                }


                &__anwsers-wrapper {
                    display: flex;     
                    justify-content: center;
                    

                    &__anwser {
                        display: flex;
                        justify-content: center;
                        position: relative;
    
                        img {
                            width: 100%;
                            max-width: 321px;
                            
                            height: 100%;
        
                            pointer-events: none;
                            user-select: none;

                            margin-left: auto;
                            margin-right: auto;

                            @media screen and (max-width: 950px) {
                                min-height: 175px;
                                
                            }
                        }
    
                        .text-anwser {
                            margin: 0;
                            position: absolute;
                            top: calc(50% - 10px);
                            -ms-transform: translateY(-50%);
                            transform: translateY(-50%);
    
                            --padding: 40px;
                            padding: 0px var(--padding) 0px var(--padding);
                            
    
                            // width: calc(100% - 50px);
                            width: calc(100% - calc(var(--padding) * 2));
                            max-width: calc(321px - calc(var(--padding) * 2));
    
                            display: flex;
                            justify-content: center;


                            .text-style {

                                font-size: 16px;
                                line-height: 22px;
                                color: #3a3a3a;

                                margin-left: auto;
                                margin-right: auto;
                            }

    
                        }
                    }
                }
            }
        }

    }
}