.data-panel {

    &--padding-reduced {
        padding-bottom: 42px !important;
        // padding-bottom: 0px !important;

    }

    &__change-email {
        position: relative;
    }

    &__user-settings {
        &__description {
            display: flex;
            padding-top: 40px;
            margin-bottom: 50px;
        
            h4 {
                margin: 0px;
                margin-left: 2.5%;
        
                font-size: 18px;
                font-weight: 600;
        
                position: relative;
        
        
                &::before {                  
                    content: '';
                    position: absolute;
                    left: 0;
                    bottom: -10px;
                    background-color: var(--highlited-color);
                    height: 2px;
                    box-sizing: border-box;
                    width: 20%;
                }
        
                span {
                    color: var(--highlited-color);
                }
            }
        }

        &__text {
            margin: 0px 2.5% 0px 2.5%;
            color: #3a3a3a;
            line-height: 22px;
        
            margin-bottom: 30px;

            &--middle-margin {
                margin: 50px 2.5% 30px 2.5%;
            }
        
        }

        &__spacer {
            margin: 10px 5% 9px 7.5%;
            border-bottom: 1px solid var(--spacer-color);
        }

        &__button-wrapper {

        
            @media only screen and (max-width: 950px) {

                justify-content: center;
                margin: 40px 0px 0px 0px;
            }
        
            @media only screen and (min-width: 951px)  {
                display: flex;

                margin: 40px 5% 0px 7.5%;
                justify-content: right;
            }

            &__notification {

                @media only screen and (max-width: 950px) {
                    width: min-content;
                    white-space: nowrap;

                    margin-bottom: 30px;

                    margin-left: auto;
                    margin-right: auto;
                }

                @media only screen and (min-width: 951px)  {
                    position: relative;
                    top: 12px;
                    margin-right: 25px;
                }

                &--good {
                    color: #38a1af;
                }

                &--bad {
                    color: rgb(216, 103, 103);
                }

                span {
                    font-weight: 500;
                    display: block;
                }
            }
        
            &__submit-button {
                width: min-content;
                white-space: nowrap;
                cursor: pointer;
                border-radius: var(--panel-radius);
                padding: 10px 20px 10px 20px;

                -webkit-user-select: none; /* Safari */        
                -moz-user-select: none; /* Firefox */
                -ms-user-select: none; /* IE10+/Edge */
                user-select: none; /* Standard */

                @media only screen and (max-width: 950px) {
                    margin-left: auto;
                    margin-right: auto;
                }

                &--valid {
                    border: 2px solid var(--highlited-color);

                    &:hover {
                        border-color: var(--button-border-only-hover-color) !important;
                    }
    
                    &:active {
                        transform: scale(0.98);
                    }
                }

                &--invalid {
                    border: 2px solid var(--inactive-color);
                    color: var(--inactive-color);
                }
            }
        }

        &__input-row {
            margin: 0px 2.5% 0px 2.5%;
        
            border-left: 2px solid var(--highlited-color);
            border-right: 1px solid var(--spacer-color);
        
            @media only screen and (max-width: 950px)  {
                padding: 10px 15px;            
            }
        
            @media only screen and (min-width: 951px)  {
                display: flex;
                justify-content: space-between;
                padding: 10px 20px;
            }
        
            &__text {
        
                display: flex;
                justify-content: left;
        
                @media only screen and (max-width: 950px) {
                    margin-bottom: 8px;
                }
        
                span {
                    margin: auto 20px auto 0px;
        
                    display: block;
                    height: min-content;
                    white-space: nowrap;
                }
            }
        
            &__input-wrapper {
        
                position: relative;
        
                input {
                  
                    font-size: 16px;
                    
                    padding: 10px 15px 10px 4px;
                    box-sizing: border-box;
        
                    color: #1a1a21;
                    
                    outline: 0;
                    border-width: 0 0 2px;
                    border-color: var(--highlited-color);
                    border-style: solid;
                    
                    &.invalid {
                        border-color: var(--invalid-color) !important;
                    }

                    &:focus {
                        border-color: #37c1d4;
                    }

                    @media only screen and (max-width: 950px) {
                        width: 100%;
                    }
        
                    @media only screen and (min-width: 951px) {
                        width: 230px;
                    }
        
                    &::placeholder {
                        color: #bebebe;
                    }
                }
        
                &::before{
                    @media only screen and (min-width: 951px)  {
                        content: '';
                        position: absolute;
                        left: -20px;
                        bottom: -10px;
                        background-color: var(--spacer-color);
                        height: 62px;
                        box-sizing: border-box;
                        width: 1px;
                    }
                }
            }

            &__data {
                position: relative;
                min-width: 230px;


                @media only screen and (max-width: 950px) {
                    margin-top: 30px;
                    font-weight: 600;
                }

                @media only screen and (min-width: 951px) {
                    display: flex;
                    justify-content: left;
                    font-weight: 500;
                }

                span {
                    display: block;
                    width: min-content;
                    white-space: nowrap;

                    @media only screen and (max-width: 950px) {
                        margin: auto;
                    }
    
                    @media only screen and (min-width: 951px) {
                        margin: auto 0px auto 0px;
                    }

                    &.skeleton {
                        min-width: 100%;
                        height: 16px;
                    }
                }

                &::before{
                    @media only screen and (min-width: 951px)  {
                        content: '';
                        position: absolute;
                        left: -20px;
                        bottom: -10px;
                        background-color: var(--spacer-color);
                        height: 38px;
                        box-sizing: border-box;
                        width: 1px;
                    }
                }
            }

            &__checkbox {
                position: relative;
                margin: 0px;
                height: min-content;

                @media only screen and (max-width: 950px)  {
                    margin-top: 10px;
                }

                &::before{
                    @media only screen and (min-width: 951px)  {
                        content: '';
                        position: absolute;
                        left: -20px;
                        bottom: -10px;
                        background-color: var(--spacer-color);
                        height: 49px;
                        box-sizing: border-box;
                        width: 1px;
                    }
                }

                input {
                    &.skeleton {
                        color: transparent;
                        background-color: transparent;

                        &:before {
                            display: none;
                        }
                    }

                    outline: none;
                    height: 25px;
                    width: 50px;
                    position: relative;
                    top: 1px;
                    margin: 0px;

                    border-radius: calc( var(--panel-radius) - 3px);

                    -webkit-appearance: none;
                    
                    background-color: var(--highlited-light-color);
                    box-shadow: inset 0 0 3px rgba(0,0,0,0.2);

                    cursor: pointer;
                    transition: 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);

                    &:checked{
                        background: var(--highlited-color);

                        &:before{
                            left: 23px;
                        }
                    }

                    &:before{
                        position: absolute;
                        content: "";
                        left: 0;
                        height: 100%;
                        width: 27px;

                        box-shadow: 1px 1px 4px rgba(0,0,0,0.2);
                        border-radius: calc( var(--panel-radius) - 3px);
                        
                        background: #fcfcfc;
                        
                        transform: scale(0.80);
                        transition: left 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
                    }
                }    
                
                &--close-account {
                    input {
                        &:checked{
                            background: var(--highlited-color);
        
                            &:before{
                                left: 23px;
                            }
                        }
                    }

                    .text {
                        position: relative;
                        margin-left: 15px;

                        bottom: 6px;
                        font-size: 16px;

                        &--not-active {
                            color: #b7c1ce;
                        }

                        &--active {
                            color: var(--highlited-color);
                        }
                    }
                }
            }
        }
    }
}