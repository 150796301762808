.course-preview {
    position: relative;

    .display-on-mobile {
        margin-top: 50px;
        @media only screen and (min-width: 951px)  {
            display: none;
        }
    }

    .display-on-desktop {
        @media only screen and (max-width: 950px)  {
            display: none;
        }
    }

    &__main-section {
        min-height: 400px;
        --margin-main: 3.5vw;

        padding-top: var(--margin-main);
        
        padding-bottom: var(--margin-main);

        &--container {
            &.courseIcon {
                border-radius: 10px;
                max-width: 300px;
                display: flex;
            }
        }

        @media only screen and (min-width: 951px)  {
            display: flex;
        }

        background-color: #ffff;

        padding-left: var(--margin-main);
        padding-right: var(--margin-main);

        width: calc(100% - 2 * var(--margin-main));

        &__repeated-cta {
            &__separator {
                margin-bottom: 10px;
                border-bottom: 1px solid var(--spacer-color);
            }

            &__guarantee {
                text-align: center;
                font-size: 15px;
                font-weight: 500;
                color: #575757;

                margin-top: 5px;
                cursor: pointer;
            }

            &__text {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;

                --small-font-size: 22px;
                --big-font-size: 25px;

                &__icon {
                    font-size: calc(var(--big-font-size) + var(--small-font-size));
                    font-weight: 700;
                }

                &__first-line {
                    font-size: var(--small-font-size);
                    font-weight: 700;
                }

                &__second-line {
                    span {
                        color: var(--purple);
                    }
                    font-size: var(--small-font-size);
                    font-weight: 700;
                }

                &__bottom-text {
                    margin-top: 10px;
                    font-size: var(--small-font-size);
                    font-weight: 500;
                }
            }
        }

        &__left-panel {

            @media only screen and (max-width: 950px)  {
                width: 100%;
            }
        
            @media only screen and (min-width: 951px)  {
                width: 60%;
            }

            &__headline {
                margin-top: 25px;
                font-size: 23px;
                font-weight: 600;


                margin-bottom: 10px;

                @media only screen and (min-width: 951px)  {
                    display: none;
                }         
            }

            &__responsive-container {
                // padding-top: 0px !important;
                margin-left: 0px !important;
                margin-right: 0px !important;

                position: relative;

                @media only screen and (max-width: 950px)  {
                    width: 100%;
                }

                &__loading {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    display: flex;
                  

                    background-color: #f1f8f9;

                    .lds-roller {
                        position: relative;

                        margin: auto;

                        top: 0px !important;

                        div {
                            &::after {
                                background: var(--valid-color) !important;
                            }
                        }
                      
                    }
                }
            }

            &__course-properties {


                @media only screen and (max-width: 950px)  {
                    width: calc(100% - 20px);
                }
            
                @media only screen and (min-width: 951px)  {
                    width: calc(95% - 20px);            
                    position: relative;
                    top: -10px;   
                }
                            
                padding-left: 10px;
                padding-right: 10px;

                display: flex;

                justify-content: space-between;

                &__property {

                    @media only screen and (max-width: 950px)  {
                        span {
                            display: block;
                        }
                    }

               
                    &.--normal {
                        svg {
                            fill: var(--valid-color);
                        }
                    }

                    &.--highlight {
                        svg {
                            fill: var(--yellow-color);
                        }
                    }

                    svg {
                        @media only screen and (max-width: 950px)  {
                            display: block;
                            margin: auto;
                            margin-bottom: 15px;
                        }
                        

                        width: 24px;
                        height: 24px;
                    }


                    &__text {
                        position: relative;
                        top: -6px;
                          

                        margin-left: 7px;

                        @media only screen and (max-width: 950px)  {
                            text-align: center;
                            font-size: 14px;
                        }
                    }
                }
            }

            &__panels-selector {
                margin-top: 60px;
                margin-bottom: 30px;

                display: flex;

                padding-bottom: 20px;

                border-bottom: 1px solid var(--spacer-color);

                @media only screen and (max-width: 950px)  {
                    width: calc(90%);
                    padding-left: 5%;
                    padding-right: 5%;
                    justify-content: space-between;
                }
            
                @media only screen and (min-width: 951px)  {
                    width: calc(95% - 10px);
                    padding-left: 10px;
                }

                
                &__option {

                    @media only screen and (min-width: 951px)  {
                        margin-right: 25px;
                    }

                    font-weight: 600;

                    cursor: pointer;

                    &--active { 
                        color: var(--valid-color);
                    }

                    &--inactive {
                        color: #aaaaaa;
                    }
                }
            }

            &__text-section {
                padding-left: 10px;
                padding-right: 10px;

                &--from-landing {
                    background-color: #ffff;
  
                    @media only screen and (max-width: 950px)  {
                        width: calc(100% - 20px) !important;
                    }
                
                    @media only screen and (min-width: 951px)  {
                        width: calc(100% - 20px) !important;
                    }         
                }

                @media only screen and (max-width: 950px)  {
                    width: calc(100% - 20px);
                }
            
                @media only screen and (min-width: 951px)  {
                    width: calc(95% - 20px);            
                }                

     
                &__text {
                    color: #3a3a3a;
                    line-height: 22px;

                    h2{
                        font-size: 15px;
                    }
                }       
                
                &__consultations {
                    &__main-text {
                        b {
                            color: var(--purple);
                        }

                        display: block;
                    }

                    &__second-text {
                    }

                }
                
                .main-page__guarantee__money {
                    height: 48px;

                    @media only screen and (max-width: 950px)  {
       
                    }
                
                    @media only screen and (min-width: 951px)  {
                        margin-bottom: 0px !important;
                    }    

                    span {
                        position: relative;
                        top: 15px;
                    }
                }
                
                &__learning-method {

                    background-color: #ffff;

                    &__from-landing-separator {         
                         background-color: #ffff;
                       
                    
                        &--top {
                            margin-top: 60px;
                            padding-top: 30px;
                            border-top: 2px solid var(--spacer-color);
                        }

                        &--bottom {
                            padding-bottom: 20px;
                        }
                    }

                    &__table-of-contents {

                        &__chapter {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            gap: 10px;
                            
                        }
                        
                        ul{
                            li::marker{
                                size: 6px;
                                border-radius: 50px;
                                border: 3px solid #ffca43;
                                color: #ffca43;
                            }

                            li {
                                &.active {
                                    color: var(--purple);
                                    font-weight: 600;
                                    cursor: pointer;
                                }

                                &.inactive {
                                    color: #646464;
                                }
                            }
                        }
                        

                    }


                    margin-top: 40px;
                    font-weight: 600;
                    color: #3a3a3a;
                    // border-top: 1px solid var(--spacer-color);
                    h1{
                        font-size: 20px;
                    }

                    h3 {
                        font-size: 16px;
                    }

                    &__laptop {
                        display: flex;
                        justify-content: flex-start;

                        img {
                            @media only screen and (min-width: 950px)  {
                                display: flex;   
                                width: 60%;
                                height: 60%;                       
                            }
                            --width: 80%;
                            width: 80%;
                            height: 80%;
                            margin: auto;
                        }
                    }

                    &__text {
                        margin-top: 20px;
                        font-weight: 400 ;
                        line-height: 20px;

                        br {
                            display: block;
                            margin: 10px 0; // Adjust the gap between lines
                          }

                        h2{
                            font-size: 18px;
                        }

                        b {
                            font-weight: 700;

                            &.blue {
                                color: var(--blue);
                            }

                            &.purple {
                                color: var(--purple);
                            }
                        }
        
                        &__bullet-point {
                            // display: flex;
                            // flex-direction: row;

                            @media only screen and (max-width: 950px)  {
                                margin-bottom: 8px;
                            }
                        
                            @media only screen and (min-width: 951px)  {
                                margin-bottom: 15px;
                            }

                            &__number {
                                color: var(--valid-color);
                                font-weight: 600;
                                // font-size: 17px;
                            }

                            &__emoji {
                                font-size: 25px;
                                margin-right: 5px;

                                &--container{
                                    margin-bottom: 10px;
                                    display: flex;
                                    flex-direction: row;
                                    align-items: center;
                                    
                                    &.card {
                                        margin-bottom: 0px;
                                        background: #fff;
                                        border-radius: 8px;
                                        padding: 15px;
                                        box-shadow: 0 2px 4px rgba(0,0,0,0.1);
                                        @media only screen and (min-width: 951px) {
                                            width: 84%;
                                        }
                                        gap: 5px;
                                        margin-top: 10px;
                                        margin-bottom: 10px;
                                    }

                                    &--bundle {
                                        display: flex;
                                        flex-direction: column;
                                    }
                                }

                                @media only screen and (max-width: 950px)  {
                                    margin-bottom: 15px;
                                }
                            
                                @media only screen and (min-width: 951px)  {
                                    margin-bottom: 15px;
                                }
                            }
                        }


                    }
                }

                &__about-author {
                    position: relative;

                    h2 {
                        font-size: 18px;
                        margin-top: 0px;
                    }

                }
            }
            
        }

        &__right-panel {
            @media only screen and (max-width: 950px)  {
                // margin-top: 15px;
                width: 100%;


            }
        
            @media only screen and (min-width: 951px)  {
                width: 40%;
                padding-left: 30px;
                padding-top: 22px;
            }


            &__headline {
                font-size: 28px;
                font-weight: 600;

                margin-bottom: 20px;

                @media only screen and (max-width: 950px)  {
                    display: none;
                }            
            }

            &__brief-description {
                line-height: 20px;
                color: #3a3a3a;
                max-width: 450px;
            }

            &__discount {


                &__headline {
                    font-size: 15px;
                }

                width: min-content;
                // background-color: #1a1a21;
                background-color: #4a9f35;

                text-wrap: nowrap;
                color: #ffff;
                padding: 5px 10px 5px 10px;
                font-size: 20px;

                border-radius: 8px;

                margin-top: 15px;


                
                svg {
                    margin-left: 7px;
                    top: 3px;
                    position: relative;
                    width: 22px;
                    height: 22px;

                    // fill: #ffbe1a;
                    fill: #ffff;
                }
            }

            &__price {

                border-top: 1px solid var(--spacer-color);

                margin-top: 30px;
                padding-top: 15px;
                color: #3a3a3a;

                b { 
                    font-weight: 500;
                }


                &__discounted-section {
                    margin-top: 10px;
                }


                .full-price {
                    text-decoration: line-through;
                    color: red;    
                    font-size: 18px;             
                }

                .discounted-price {
                    font-size: 22px;
                    margin-right: 10px;   
                }

                &--mobile {
                    margin-top: 0px;
                }
            }

            &__lowest-price {
                margin-top: 10px;
                color: #4b4b4b;
                font-size: 14px;
            }
            
            &__buttons {
                margin-top: 30px;
                margin-bottom: 40px;

                @media only screen and (min-width: 1270px)  {
                    display: flex;                          
                }

                cursor: pointer;

                &__button {

                    border-radius: var(--panel-radius);

                    &--left {
                        font-weight: 500;

                        @media only screen and (max-width: 1269px)  {
                            display: flex;
                            justify-content: center;  
                        }
                    }

                    &--active {
                        background-color: var(--yellow-color);
                        padding: 16px 24px 16px 24px;

                        &:hover {
                            background-color: #ffbe1a !important;
                            border-color: #ffbe1a  !important;
                        }
                
                        &:active {
                            transform: scale(0.98);
                        }
                    }

                    &--inactive {
                        padding: 15px 23px 15px 23px;
                        border: 1px solid #aaaaaa !important;
                        background-color: var(--spacer-color);
                        color: #aaaaaa;
                    }

                    &--right {
                        padding: 3px 18px 17px 18px;

                        border: 1px solid #3a3a3a; 


                        @media only screen and (min-width: 951px)  {
                            transition: border-color 0.3s ease;
                            transition: background-color 0.3s ease;
                            transition: color 0.3s ease;

                            &:hover {
                                color: #ffff;
                                font-weight: 500;

                                svg {
                                    fill: #ffff;
                                }

                                border-color: var(--valid-color);
                                background-color: var(--valid-color)
                            }

                            &:active { 
                                transform: scale(0.98);
                            }
                        }

                        @media only screen and (max-width: 1269px)  {
                          
                            margin-top: 20px;
                            text-align: center;
                            
                            width: calc(100% - 36px);
                            justify-content: center;
                        }
        
                        @media only screen and (min-width: 1270px)  {
                            margin-left: 35px;
                        }


                        svg {
                            height: 28px;
                            width: 28px;

                            position: relative;
                            top: 9px;
                            margin-right: 10px;               
                        }

                    }
                }

            }

            &__additional-materials {

                margin-top: 35px;
                margin-bottom: 35px;

                &__headline {
                    font-weight: 600;
                    font-size: 18px;

                    margin-bottom: 20px;

                }

                &__text { 
                    line-height: 20px;
                    color: #3a3a3a;

                    b { 
                        font-weight: 500;
                    }
                }

                &__buttons {
                    margin-top: 20px;

                    @media only screen and (min-width: 1270px)  {
                        display: flex;
                        justify-content: space-between;                              
                    }

                    &__button {
                        border-radius: var(--panel-radius);

                        &--left {

                        }

                        &--right {
                            @media only screen and (max-width: 1269px)  {
                                margin-top: 20px;
                            }
                        }

                        &--inactive {
                            border: 1px solid #3a3a3a;
                            height: 66px;

                            @media only screen and (max-width: 1269px)  {
                                width: 100%;
                            }
        
                            @media only screen and (min-width: 1270px)  {
                                width: calc(50% - 10px);                             
                            }
                            
                        }

                        &--active {
                            border: 2px solid var(--yellow-color);
                            height: 64px;

                            @media only screen and (max-width: 1269px)  {
                                width: 100%;
                            }
        
                            @media only screen and (min-width: 1270px)  {
                                width: calc(50% - 12px);                            
                            }
                        }

                        position: relative;                  
                        display: flex;
                        cursor: pointer;

                        span {
                            padding-left: 10px;
                            padding-right: 10px;
                            display: block;

                            margin-top: 0px;
                            margin-bottom: 0px;

                            position: absolute;
                            top: 50%;
                            -ms-transform: translateY(-50%);
                            transform: translateY(-50%);
                        }

                        justify-content: space-between;

                        &__left-text {
                            left: 0px;
                        }

                        &__right-text {
                            right: 0px;

                            font-size: 14px;
                        }


                    }

                }

                &__select-option {
                    margin-top: 15px;
                    font-size: 14px;

                    display: flex;

                    &--active {
                        color: rgb(216, 78, 78);
                    }

                    &--inactive {
                        color: #3a3a3a;
                    }
                }

            }

            &__pros {

                &__headline {

                    font-weight: 600;
                    font-size: 18px;

                    margin-bottom: 23px;
                    
                }

                &__point {
                    display: flex;
                    margin-bottom: 13px;

                    position: relative;

                    &__dot {

                        height: 6px;
                        width: 6px;
                        border-radius: 50px;
                        border: 3px solid #ffca43;

                        position: absolute;
                        top: 0;
                        bottom: 0;
                        margin: auto 0;
                    }

                    &__text {
                        display: block;
                        margin-left: 25px;
                    }

                    // color: #3a3a3a; 
                }
            }



        }
    }

    &__footer-separator {
        padding-top: 100px;
        background-color: #ffff;

        &__line {
            margin-left: 3.5%;
            margin-right: 3.5%;

            width: 93%;
            
            border-bottom: 1px solid var(--spacer-color);
        }
    }
}