
.navigation__user-panel__language-selector-menu__selector__element {
    // display: flex;

    text-align: center;


    @media only screen and (max-width: 950px)  {
        margin-bottom: 30px;
    }

    @media only screen and (min-width: 951px)  {
                     
    }

    &__language {


        user-select: none;
        cursor: default;

        @media only screen and (max-width: 950px)  {
            margin-top: 14px;
            font-size: 14px;
        }

        @media only screen and (min-width: 951px)  {
            margin-top: 12px;
            font-size: 16px;    
        }
    }

    .flag {
        margin-left: auto;
        margin-right: auto;

        @media only screen and (max-width: 950px)  {
            width: 42px;
            height: 42px;
        }

        @media only screen and (min-width: 951px)  {
            width: 42px;
            height: 42px;                     
        }
        
        box-shadow: 0 0 1px rgba(0, 0, 0, 0.5);
        overflow: hidden;

        border-radius: 90px;

        border: 1px solid var(--spacer-color);

        &--active {
            border: 2px solid #ffc532;
            cursor: pointer;

            -webkit-box-shadow: 1px 1px 24px -10px rgba(66, 68, 90, 1);
            -moz-box-shadow: 1px 1px 24px -10px rgba(66, 68, 90, 1);
            box-shadow: 1px 1px 24px -10px rgba(66, 68, 90, 1);
        }

        &--avaiable {
            cursor: pointer;
        
            &:hover {
                border: 2px solid #ffc532;
    
                -webkit-box-shadow: 1px 1px 24px -12px rgba(66, 68, 90, 1);
                -moz-box-shadow: 1px 1px 24px -12px rgba(66, 68, 90, 1);
                box-shadow: 1px 1px 24px -12px rgba(66, 68, 90, 1);           
            }
        }

        &--unavaiable {
            filter: grayscale(90%);
            cursor: not-allowed;
        }
    
        &--poland {
            background: linear-gradient(#fff 50%, #de0c39 0);
        }

        &--ukraine {
            background: linear-gradient(#105bbc 50%, #ffd600 0);
        }

        &--lithuania {
            background: linear-gradient(#fdba0b 33%, #006a42 33%, #006a42 66%, #c2222a 0);
        }

        &--hungary {
            background: linear-gradient(#cf2436 33%, #fff 33%, #fff 66%, #45714e 0);
        }
    }
}
