
.document {

    margin-top: 150px;

    &__text {

        margin-left: 3.5%;
        margin-right: 3.5%;

        li {
            margin-bottom: 6px;
            margin-top: 6px;
        }

        .spacer {
            margin-top: 50px;
            margin-bottom: 50px;
            width: 100%;
            height: 1px;

            border-top: 1px solid var(--spacer-color);
        }

        .links {

            svg {
                position: relative;
                top: 5px;
                margin-right: 8px;
            }
            
            p {
                cursor: pointer;
                
                &:hover {
                    // border-bottom: 1px solid var(--yellow-color);
                    color: var(--yellow-color);

                    text-decoration: underline;
                }

            }
        }
    }
}