.page-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 3;

    background-color: rgba(0, 0, 0, 0.405);
    cursor: default;

    backdrop-filter: blur(2px);
}

.open-test-video-panel {
    background-color: #ffffff;
    margin: auto;
    z-index: 4;

    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    border-radius: var(--panel-radius);

    padding-bottom: 25px;

    --blur-size: 0;
    -webkit-filter: blur(var(--blur-size));
    -moz-filter: blur(var(--blur-size));
    -o-filter: blur(var(--blur-size));
    -ms-filter: blur(var(--blur-size));
    filter: blur(var(--blur-size));

    -webkit-box-shadow: 14px 8px 32px -14px rgba(66, 68, 90, 1);
    -moz-box-shadow: 14px 8px 32px -14px rgba(66, 68, 90, 1);
    box-shadow: 14px 8px 32px -14px rgba(66, 68, 90, 1);

    height: min-content;

    padding-left: 2.5% !important;
    padding-right: 2.5% !important;

    @media only screen and (max-width: 950px)  {
        width: 95%;
    }

    @media only screen and (min-width: 951px)  {
        width: min-content;
    
        max-height: calc(100vh) !important;
        max-width: 70vw !important;

        overflow-y: auto;

    }

    &__responsive-container {

        display: block;

        margin: 0;
        padding: 0;
        border: 0 none;
        box-sizing: border-box;

        position: relative;
        padding-top: 56.25%;
        border-radius: 8px;

        overflow: hidden !important;      

        iframe {
            border: none;

            position: absolute;

            top: 0;
            height: 100%;
            width: 100%;

            border-radius: 8px;

            overflow: hidden !important;

            &::-webkit-scrollbar {
                display: none;
        }
        }
   

        @media only screen and (max-width: 950px)  {
            width: 100%  !important;
            margin-left: 0px !important;
            margin-right: 0px !important;
        }
    
        @media only screen and (min-width: 951px)  {
            aspect-ratio: 16 / 9 !important;
            width: 70vw !important;
        }
    }

    &__title {
        font-size: 20px;
        font-weight: 600;
        margin-top: 30px;

        display: flex;

        margin-bottom: 20px;

        span {
            display: block;
        }

        &__coloured {
            color: var(--highlited-color);
        }


        &__text {
            &--desktop {
                @media only screen and (max-width: 950px)  {
                    display: none;
                }
            }
        
            &--mobile {
                @media only screen and (min-width: 951px)  {
                    display: none;
                }

                font-size: 18px;
            }
        }

        .material-symbols-outlined {
            @media only screen and (min-width: 951px)  {
                font-variation-settings:
                'FILL' 0,
                'wght' 500,
                'GRAD' 0,
                'opsz' 48;

                scale:(1.1);
            }

            @media only screen and (max-width: 950px)  {
                font-variation-settings:
                'FILL' 0,
                'wght' 400,
                'GRAD' 0,
                'opsz' 48;

                scale: (1.0);
            }

            position: relative;
            bottom: 1px;

            margin: auto 5px auto 0px;

            color: var(--highlited-color);

            cursor: pointer;

            -webkit-user-select: none; /* Safari */        
            -moz-user-select: none; /* Firefox */
            -ms-user-select: none; /* IE10+/Edge */
            user-select: none; /* Standard */
    
            &:hover {
                color: var(--button-border-only-hover-color);
            }

            &:active {
                transform: scale(1.0);
            }
        }
    }

}