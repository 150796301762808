$--animation-duration: 0.250s;

:root {
    --min-height: 45px;
    --max-height: 1000px;
}

.data-panel{
    &__mainTest {
        &__nav {
            padding-top: 15px;
            padding-bottom: 15px;

            &__spacer-bottom {
                border-bottom: 1px solid var(--spacer-color);
                margin-bottom: 15px;
            }

            @keyframes expandedInit {
                from {
                    max-height: var(--min-height);
                }
                to {
                    max-height: var(--max-height);
                }
            }

            @media only screen and (max-width: 950px){
                @keyframes collapseInit {
                    from {
                        max-height: var(--excercise-nav-height);
                    }
                    to {
                        max-height: 100px;
                    }
                }
            }
            @media only screen and (min-width: 951px){
                @keyframes collapseInit {
                    from {
                        max-height: var(--excercise-nav-height);
                    }
                    to {
                        max-height: 45px;
                    }
                }
            }

            &__buttons {

                    &__button-wrapper {

                        margin-bottom: 25px;
                    }

                    &__wrapperExpanded--desktop {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        position: relative;
                        overflow: hidden;
                        
                        &.expanded {
                            max-height: var(--max-height);
                            animation: expandedInit $--animation-duration ease-in;
                        }


                        &.not-expanded {
                            animation: collapseInit $--animation-duration ease-in-out;
                            max-height: var(--min-height);
                        }
                    }

                    &__wrapperExpanded--mobile {
                        display: flex; 
                        flex-direction: column;
                        overflow: hidden;

                        &.expanded {
                            max-height: var(--max-height);
                            animation: expandedInit $--animation-duration ease-in;
                        }


                        &.not-expanded {
                            animation: collapseInit $--animation-duration ease-in-out;
                            max-height: var(--excercise-nav-height);
                        }
                    }

                    &__wrapper-close-and-expand--mobile {
                        
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        
                        margin-bottom: 15px;
                    }

                    &--expanded-buttons {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;

                        @media only screen and (max-width: 950px){
                            align-items: flex-start;
                        }
                    }

                    &__wrapperNotExpanded {
                        @media only screen and (max-width: 950px){
                            display: flex; 
                            flex-direction: row;
                            justify-content: center;

                        }
                        @media only screen and (min-width: 951px){
                            display: flex; 
                            flex-direction: row;
                            justify-content: space-between;
                        }
                    }

                    &--notExpanded {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        @media only screen and (max-width: 950px){
                            justify-content: center !important;
                        }
                        
                        align-items: center;
                        text-align: center;
                    }
                    

                    &__button {

                        height: min-content;
                        padding: 10px 20px 10px 20px;
                        --yellow-button: #ffca43;
                        font-weight: 500;
                        cursor: pointer;
                        -webkit-user-select: none;
                        user-select: none;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        
                        &.left-border {
                            border-left: 2px solid;
                            border-right: 2px solid transparent !important;
                        }

                        &.full-border {
                            border: 2px solid;
                            border-radius:var(--panel-radius);
                        }
                        
                        transition: transform 0.05s ease-in-out;

                        &:active {
                            transform: scale(0.95);
                        }

                        &.single-excercise {
                            width: 15px;
                            height: 15px;
                            margin: 3px;
                            @media only screen and (max-width: 950px){
                                margin: 7px 3px 7px 3px;
                            }
                        }

                        &.expand {
                            border: 2px solid var(--valid-color);
                            border-radius: var(--panel-radius);
                            padding: 10px 20px 5px 20px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            text-align: center;

                            .material-symbols-outlined {
                                position: relative;
                                bottom: 3px;
                                fill: 1;
                            }

                            .button-text {
                                position: relative;
                                bottom: 3px;
                            }
                        }

                        &.video-explanation {
                            margin-right: 2.5vw;
                        }

                        &.close-and-save {
                            margin-left: 2.5vw;
                        }
        
                        &.active {
                            border-color: var(--yellow-color);;
                            animation: scaleAnimation 0.5s forwards;

                            @keyframes scaleAnimation {
                                from {
                                  transform: scale(1.2);
                                }
                                to {
                                  transform: scale(1);
                                }
                              }
                        }
        
                        &.inactive {
                            border-color: var(--highlited-light-color);
                        }

                        &.expandButtonWhenExpaned {
                            margin-right: 2.5vw;
                        }

                        // &.expandButtonWhenNotExpaned {
                        //     margin-right: 2.5vw;
                        // }
        
                        &.finished {
                            border-color: var(--valid-color);
                        }
                    }
                }
            }
    }
}