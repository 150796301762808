.course-panel {
    position: relative;


    width: 100%;
    max-width: var(--max-width);
    min-width: var(--min-width);

    border: 1px solid #e4e4e4;
    border-radius: calc(var(--panel-radius)*1.5);

    align-self: center;
    justify-self: center;

    -webkit-box-shadow: 8px 8px 16px -16px rgba(66, 68, 90, 1);
    -moz-box-shadow: 8px 8px 16px -16px rgba(66, 68, 90, 1);
    box-shadow: 8px 8px 16px -16px rgba(66, 68, 90, 1);

    &--empty-course {
        color: #e9e9e9 !important;

        @media only screen and (max-width: 990px)  {
            display: none;
        }
    }

    &__main-name {
        display: flex;

        margin: var(--margin);
        width: calc(100% - var(--margin)*2);
        border-radius: calc(var(--panel-radius)*1.5);

        background-color: #0d0d2b;

        @media only screen and (min-width: 1045px)  {
            height: 139px;
        }

        @media only screen and (min-width: 989px) and (max-width: 1044px)  {
            height: 109px;
        }

        @media only screen and (max-width: 990px) {
            height: 139px;
        }


        &__img {    
            img {
                margin-left: 10px;
                margin-top: 5px;
               
                width: 100%;
                object-fit: contain;
                height: auto;

                @media only screen and (min-width: 1045px)  {
                    max-width: 130px;
                }

                @media only screen and (min-width: 989px) and (max-width: 1044px)  {
                    max-width: 100px;
                }

                @media only screen and (max-width: 990px) {
                    max-width: 130px;
                }

                &.skeleton {
                    display: none;
                    background-color: transparent;
                    color: transparent;
                }
            }
        }

        &__text {
            padding-right: 5px;
            margin-left: 25px;

            @media only screen and (min-width: 1045px)  {
                margin-top: 40px;
            }

            @media only screen and (min-width: 989px) and (max-width: 1044px)  {
                margin-top: 22px;
            }

            @media only screen and (max-width: 990px) {
                margin-top: 40px;
            }

            &__element {
                position: relative;
                display: block;

                color: #ffffff;
                font-size: 24px;
                font-weight: 600;

                &--first {
                    margin-bottom: 5px;
                }

                &--second {

                }

                &--26 {
                    font-size: 26px !important;
                }

                &--25 {
                    font-size: 25px !important; 
                }

                &--24 {
                    font-size: 24px !important;
                }

                &--23 {
                    font-size: 23px !important;
                }
            
                &--22 {
                    font-size: 22px !important;
                }

                &--21 {
                    font-size: 21px !important;
                }

                &--20 {
                    font-size: 20px !important;
                }

                &--19 {
                    font-size: 19px !important;
                }

                &--18 {
                    font-size: 18px !important;
                }
            }
        }
    }

    &__empty-course-head {
        margin: var(--margin);
        width: calc(100% - var(--margin)*2);
        border-radius: calc(var(--panel-radius)*1.5);

        border: 1px solid #e9e9e9;
        background-image: linear-gradient(to bottom right, #f8f8f8, #ffffff);

        @media only screen and (min-width: 1045px)  {
            height: 139px;
        }

        @media only screen and (min-width: 989px) and (max-width: 1044px)  {
            height: 109px;
        }

        @media only screen and (max-width: 990px) {
            height: 139px;
        }
    }

    &__name {
        margin-top: 20px;
        margin-left: 20px;
        font-weight: 600;

        position: relative;
    }


    @media only screen and (max-width: 950px)  {
        --description-height: 105px;
    }

    @media only screen and (min-width: 951px)  {
        --description-height: 80px;
    }

    &__description {
        font-size: 14px;
        color: #3a3a3a;
        margin: 10px 20px;
        height: var(--description-height);
        margin-bottom: 25px;
    }

    &__params {
        padding-left: var(--margin);
        padding-right: var(--margin);
        margin: 20px calc(20px - var(--margin));
        padding-bottom: 8px;

        border-top: 1px solid #e4e4e4;
        border-bottom: 1px solid #e4e4e4;

        display: flex;
        justify-content: space-between;

        &__param {

            .material-symbols-outlined {
                position: relative;
                top: 6px;
                margin-right: 10px;
            }
        }
    }

    &__content-wrapper {
        justify-content: space-between;
        display: flex;
        margin: 25px 20px;

        &__score {            
            position: relative;
            top: 3px;     
            

            span {
                display: block;
            }

            &__text {
                font-weight: 600;
            }

            &__perc { 
                margin-top: 3px;
                font-size: 14px;
                color: #3a3a3a;
            }
        }

        &__button {
            border-radius: var(--panel-radius);
            height: min-content;
           
            padding: 10px 20px 10px 20px;


            --yellow-button: #ffca43;
            border: 2px solid var(--yellow-button);
            background-color: var(--yellow-button);

            font-weight: 500;

            cursor: pointer;

            -webkit-user-select: none; /* Safari */        
            -moz-user-select: none; /* Firefox */
            -ms-user-select: none; /* IE10+/Edge */
            user-select: none; /* Standard */

            &:hover {
                background-color: #fac130 !important;
                border-color: #fac130 !important;
            }

            &:active {
                transform: scale(0.98);
            }
        }
    }

    &__valid-time {
        padding: 0px var(--margin);
        padding-top: 8px;
        margin: 0px calc(20px - var(--margin));
        margin-top: 20px;

        margin-bottom: calc(var(--margin) + 3px);

        border-top: 1px solid #e4e4e4;

        display: flex;
        width: calc(100% - 40px);
        justify-content: center;

        
        color: #3a3a3a;
        font-size: 14px;

    }
}