.footer {
	background-color: #ffffff;

    &__wrapper {
        justify-content: center;
        display: flex;

        @media only screen and (min-width: 951px)  {
            margin-left: calc(2.5%);
            margin-right: calc(2.5%);               
        }

        &__FAQ {
            width: 100%;

            @media only screen and (max-width: 950px)  {
                // display: none;
            }
        
            @media only screen and (min-width: 951px)  {
                display: flex;          
                padding-top: 20px;  
                padding-bottom: 40px;    
            }

            @media only screen and (min-width: 1695px) {
                display: none;
            }

            &__description {

                @media only screen and (max-width: 950px)  {
                    width: 100%;
                }
            
                @media only screen and (min-width: 951px)  {
                    width: 40%;
                    padding-right: 30px;
                    padding-left: 15px;               
                }

                &__title {
                    h4 {
                        font-size: 18px;
                        font-weight: 600;
                    }

                    @media only screen and (max-width: 950px)  {
                        padding-left: calc(2.5%);
                        padding-top: 20px;
                        padding-bottom: 20px;

                        margin-bottom: 30px;
                        border-bottom: var(--spacer-width) solid var(--spacer-color);

                        h4 {
                            margin: 0;
                        }
                    }
                }

                &__text {
                    color: #3a3a3a;
                    line-height: 22px;

                    @media only screen and (max-width: 950px)  {
                        padding-left: calc(2.5%);
                        padding-right: calc(2.5%);
                    }
                }
            }

            &__buttons { 
                display: flex;

                @media only screen and (min-width: 951px)  {
                    width: 60%;
                    justify-content: center;
                }

                @media only screen and (max-width: 950px)  {
                    margin: 20px 0px;
                }

         
                &__button {
                    height: min-content;
                    margin: 15px 0px;
                    padding: 10px 20px;

                    border-radius: var(--panel-radius);
                    border: 2px solid var(--highlited-color);

                    cursor: pointer;
        
                    -webkit-user-select: none; /* Safari */        
                    -moz-user-select: none; /* Firefox */
                    -ms-user-select: none; /* IE10+/Edge */
                    user-select: none; /* Standard */
    
                    &:active {
                        transform: scale(0.98);
                    }

                
                    @media only screen and (max-width: 950px)  {
                        --margin: calc(25% - 65px);
                        margin-left: var(--margin);
                        margin-right: var(--margin);                             
                    }
                
                    @media only screen and (min-width: 951px)  {
                        align-self: flex-end;
                        margin-bottom: 20px;
                    }


                    &--left {
                        @media only screen and (min-width: 951px)  {                     
                            margin-right: 10%;
                        }

                        background-color: var(--highlited-color);
                        color:#ffffff;

                        &:hover {
                            border-color: var(--button-full-hover-color);
                            background-color: var(--button-full-hover-color);
                        }
                    }
    
                    &--right {
                        &:hover {
                            border-color: var(--button-border-only-hover-color);
                        }

                        @media only screen and (min-width: 951px)  {
                            margin-left: 20px;                       
                        }
                    }
                }

            }

        }
    }

    &__spacer {
        @media only screen and (min-width: 951px)  {
            border-bottom: var(--spacer-width) solid var(--spacer-color);
        }

        @media only screen and (min-width: 1695px) {
            border-bottom: none;
            height: 1px;
            background-color: #ffffff;
        }
    }

    &__container {
        display: flex;
        justify-content: center;

        @media only screen and (min-width: 951px)  {
            margin-bottom: 70px;
            margin-top: 70px;
        }

        &__row {

            @media only screen and (max-width: 950px)  {
                width: 100%;
            }
        
            @media only screen and (min-width: 951px)  {
                display: flex;
                width: 95%;
            }

            &__col {

                @media only screen and (max-width: 950px)  {
                    transition-duration: 500ms;
                }

                @media only screen and (min-width: 951px)  {
                    width: 25%;
                    padding: 0px 15px;
                }

                &__title {
                    @media only screen and (max-width: 950px)  {
                        // border-top: var(--spacer-width) solid var(--spacer-color);
                        border-bottom: var(--spacer-width) solid var(--spacer-color);
                    
                        width: 100%;
                        display: flex;
                        justify-content: space-between;

                        cursor: pointer;
                        
                        .material-symbols-outlined {
                            transform: scale(1.25);
                            position: relative;
                            top: 15px;
                            right: 15px;

                            color: var(--highlited-color);

                            font-variation-settings:
                            'FILL' 0,
                            'wght' 400,
                            'GRAD' 0,
                            'opsz' 48;

                        }
    
                        h4 {
                            padding-left: 15px;
                            margin: 15px 0px;
                        }
                    }

                    @media only screen and (min-width: 951px)  {

                        .material-symbols-outlined {
                            display: none;
                        }

                        h4 {
                            font-size: 18px;
                            margin-bottom: 35px;
                            font-weight: 600;
                            position: relative;
    
                            &::before{
                                content: '';
                                position: absolute;
                                left: 0;
                                bottom: -10px;
                                background-color: var(--highlited-color);
                                height: 2px;
                                box-sizing: border-box;
                                width: 50px;
                            }
                        }
                    }
                }

                ul {
                    list-style: none;

                    .fixed {
                        @media only screen and (min-width: 951px) and (max-width: 1085px)  {
                            width: 192px !important;
                        }
                    }

                    li {
                        white-space: nowrap;
                        margin-bottom: 10px;
                        
                        svg {
                            margin-right: 9px;
                        }

                        p {
                            margin-left: 5px;
                            text-decoration: none;
                            font-weight: 300;
                            color: #b4c0c2;

                            cursor: pointer;

                            &:hover {    
                                text-decoration: underline;
                                color: rgb(56, 161, 175);
                            }
                        }

                        .disabled {
                            cursor: default !important;

                            &:hover {
                                color: #b4c0c2;
                                text-decoration: none !important;
                            }
                        }

                        span {
                            cursor: pointer;
                        }
                    } 

                }

                &--closed {
                    @media only screen and (max-width: 950px)  {
                        .material-symbols-outlined {
                            transform: rotate(180deg) scale(1.25);
                            top: -15px;
                            
                        }

                        ul {
                            display: none;
                        }

                    }
                }
                
                &--first {
                    @media only screen and (max-width: 950px)  {
                       border-top: 1px solid var(--spacer-color);
                    }
                }

                &--active > div {
                    @media only screen and (max-width: 950px)  {
                        border-bottom: 0px;
                    }
                }

                &--active > ul {
                    @media only screen and (max-width: 950px)  {
                        margin-top: 5px;
                    }
                }

                &--active + .footer__container__row__col--closed {
                    @media only screen and (max-width: 950px)  {
                        border-top: 1px solid var(--spacer-color);
                    }
                }
            }
        }
    }

    &__copyright {
        font-size: 14px;
        font-weight: 300;

        color: rgb(199, 199, 199);

        padding: 15px 0px;
        display: flex;
        justify-content: center;
        background-color: var(--background-color);
    }
}