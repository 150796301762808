@import "../../../../../shared/components/fallbacks/skeleton/skeleton-mixin" ;

.course-panel {

    max-width: 350px;

    &--empty-course {
      @media only screen and (max-width: 990px) {
        display: none;
      }
    }

    &.second__panel__fallback {
      @media only screen and (max-width: 990px) {
        display: none;
      }
    }
  
    &__main-name {
      &.skeleton {
        @include skeleton-common;
      }
    }
  
    &__name {
        span {
            &.skeleton {
                display: inline-block;
                min-width: 70%;
                min-height: 1rem;
            } 
        }
    }
  
    &__description {
        &__skeleton {
          margin-bottom: 0px !important;
        }

        div {
            &.skeleton {
                display: inline-block;
                color: transparent;
                width: 100%;
                margin: 1px;
                height: 14px;
            }
        }
    }
  
    &__params {
      &__param {
        &.skeleton {
            margin-top: 10px;
            width: 30%;

            div {
                height: 24px;
            }
        }
      }
    }
  
    &__content-wrapper {
      &__score {
        &.skeleton {
            display: inline-block;
            width: 30%;
            height: 2rem;
        }
      }
  
      &__button {
        &.skeleton {
            border: 0;

            pointer-events:none !important;

            &:hover {
              color: rgb(238, 238, 238) !important;
              background-color: rgb(238, 238, 238) !important;

            }
        }
      }
    }
  
    &__valid-time {
        div {
            &.skeleton {
                display: inline-block;
                width: 60%;
                height: 1rem;
            }
        }
    }

    &__invisible-buffor {
      height: 0px;

      span {
        color: #ffff;
        user-select: none;
      }
    }
  }
  