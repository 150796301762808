#navigation__user-panel {
    &__language-selector {
        height: 100%;
        padding-right: 20px;
        padding-left: 15px;

        cursor: pointer;

        @media only screen and (max-width: 950px)  {
            display: none;
        }

        border-left: var(--spacer-width) solid var(--spacer-color);

        display:table;


        svg {
            width: 34px;
            height: 34px;
            position: relative;
            top: 18px;            
        }

        .material-symbols-outlined {
            position: relative;
            top: 25px;

            font-variation-settings: 'wght' 200;
            transform: scale(1.40);
        }   


        &__language { 
            display:table-cell;
            vertical-align:middle;
            padding-left: 10px;

            font-weight: 500;
            font-size: 16px;
        }

        &__expand {
            margin-left: 7px;
        }
    }

    &__language-selector-menu {

        @media only screen and (max-width: 950px)  {
            width: 90vw;
            top: calc(10vh);
            
            padding-bottom: 40px;
        }
        
        @media only screen and (min-width: 951px)  {
            --panel-height: 400px;
            width: 400px;
            height: var(--panel-height);
            top: calc(50vh - 0.5 * var(--panel-height));
        }

        border-radius: var(--panel-radius);
        background-color: #ffff;
        z-index: 5;

        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;

        -webkit-box-shadow: 6px 6px 24px -4px rgba(66, 68, 90, 1);
        -moz-box-shadow: 6px 6px 24px -4px rgba(66, 68, 90, 1);
        box-shadow: 6px 6px 24px -4px rgba(66, 68, 90, 1);

        &__headline {
            width: 100%;
            text-align: center;
            margin-top: 15px;

            span {
                font-size: 20px;
                font-weight: 600;
            }

        }

        &__spacer {
            margin-left: 5%;
            margin-right: 5%;
            margin-top: 10px;
            margin-bottom: 15px;
            border-bottom: 1px solid var(--spacer-color);
            border-top: 1px solid white;
        }

        &__text {
            margin-left: auto;
            margin-right: auto;
            width: 90%;
        }

        &__selector-text {
            font-weight: 500;

            @media only screen and (max-width: 950px)  {
                margin-left: 5%;
                margin-right: 5%;
                display: flex;
                justify-content: center;
            }
    
            @media only screen and (min-width: 951px)  {
                margin-left: 5%;                
            }
        }

        &__selector {
            margin-left: 10%;
            margin-right: 10%;
            margin-top: 30px;

            @media only screen and (max-width: 950px)  {
                display: flex;
                justify-content: space-between;   
            }

            @media only screen and (min-width: 951px)  {
                display: flex;
                justify-content: space-between;                        
            }

            // LanguageElement.scss
        }

        &__close-button {
            display: flex;
            width: 100%;



            button {
                font-size: 16px;
                color: #1a1a21;

                @media only screen and (max-width: 950px)  {
                    margin-top: 0px;
                }

                @media only screen and (min-width: 951px)  {
                    margin-top: 55px;                      
                }

                margin-left: auto;
                margin-right: auto;

                border-radius: var(--panel-radius);
                height: min-content;
                padding: 10px 20px 10px 20px;
                
                --yellow-button: #ffca43;
                
                border: 2px solid var(--yellow-button);
                background-color: var(--yellow-button);
                
                font-weight: 500;
                
                cursor: pointer;
                -webkit-user-select: none;
            }
        }

        &__background {
            width: 100%;
            height: 100%;

            z-index: 4;

            // position: absolute;
            position: fixed;;

            left: 0;
            top: 0;
            background-color: rgba(0, 0, 0, 0.405);
            cursor: default;
            -webkit-backdrop-filter: blur(2px);
        }
    }
}