.data-panel {

    &__preview-notes {

        &__container-modifier {
            position: relative;
        }

        --inactive-color: #c8c8c8;

        &__download {

            &__headline {
                color: rgb(196, 196, 196) !important;
            }

            &__text {
                color: var(--inactive-color) !important;
            }

            &__every-note {

                &__link {
                    color: rgb(196, 196, 196) !important;
                    cursor: not-allowed;
                }
            }
        
            &__row {

                border-left-color: rgb(196, 196, 196) !important;

                &__description {
                    color: var(--inactive-color) !important;
                }

                &__button {
                    cursor: not-allowed;

                    color: rgb(196, 196, 196) !important;

                    .material-symbols-outlined {
                        color: rgb(196, 196, 196) !important;
                    }

                    &:active {
                        transform: scale(1.0);
                    }
                }
            }
        }

    }
}