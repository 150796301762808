.landing-footer {
    background-color: #ffff;

    width: 100%;
    bottom: 0;
    left: 0;

    --main-panel-radius: calc(var(--panel-radius) + 5px);
    --margin-main: 3.5%;
          
    @media (orientation: landscape) and (max-height: 500px) {

        position: unset;
    }

    @media (max-width: 767px) {
        position: unset;
    }

    @media (min-width: 768px) and (max-width: 1024px) {
        font-size: 14px;
    }

    &__logos {
        padding-top: 50px;
    
        margin-left: var(--margin-main);
        margin-right: var(--margin-main);

        @media only screen and (max-width: 950px)  {
            display: none;
        }

        @media only screen and (min-width: 951px)  {
            display: flex;
            justify-content: right;
            border-bottom: 1px solid var(--spacer-color);
            padding-bottom: 40px;       
        }
    }

    &__heading {
        padding-top: 40px;


        margin-left: var(--margin-main);
        margin-right: var(--margin-main);

        @media only screen and (max-width: 950px)  {
            margin-bottom: 15px;
        }

        @media only screen and (min-width: 951px)  {
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid var(--spacer-color);
            padding-bottom: 30px;       
        }


        &__text {
            span {
                display: block;
            }

            &__headline {
                font-size: 20px;
                font-weight: 500;
            }

            &__main {
                margin-top: 20px;
                max-width: 450px;

                line-height: 22px;
            }
        }

        &__buttons {
           
            @media only screen and (min-width: 951px)  {
                display: flex;
            }

            &__button {
                height: min-content;
                display: flex;

                margin-top: auto;
                margin-bottom: auto;

                font-weight: 500;
                white-space: nowrap;

                cursor: pointer;
                border-radius: var(--main-panel-radius);
        
                position: relative;

                svg {
                    margin-left: 10px;
                    position: relative;
                    top: -3px;

                    transform: scale(0.9);
                }

                @media only screen and (max-width: 950px)  {
                    width: 75%;
                    margin-left: auto;
                    margin-right: auto;

                    display: flex;
                    justify-content: center;
                    
                }

                @media only screen and (min-width: 951px)  {
                    width: min-content;
                }


                &--left {
                    
                    background-color: var(--yellow-color);

                    @media only screen and (max-width: 950px)  {
                        margin-top: 25px;
                        padding: 18px 16px 11px 24px;
                    }

                    @media only screen and (min-width: 951px)  {
                        padding: 16px 16px 9px 24px;
                        margin-right: 20px;
                    }
                }

                &--right {
                    
                    border: 2px solid var(--yellow-color);

                    @media only screen and (max-width: 950px)  {
                        margin-top: 15px;
                        padding: 16px 14px 9px 22px;
                    }

                    @media only screen and (min-width: 951px)  {
                        padding: 14px 14px 7px 22px;
                    }
                }
            }
        }
    }

    &__content {
        display: flex;
       
        padding-top: 20px;
        justify-content: space-between;
        
        @media (max-width: 950px) {
            display: flex;
            flex-direction: column;
            // font-size: 18px;
        }

        @media only screen and (min-width: 951px)  {
            margin-left: var(--margin-main);
            margin-right: calc( 2 * var(--margin-main));
        }



        p {
            margin-bottom: 1.1rem;

            @media only screen and (max-width: 950px)  {
                padding-left: var(--margin-main);
            }

            svg {
                position: relative;
                top: 4px;
                padding-right: 10px;
            }
        }

        span {
            text-decoration: none;
            cursor: pointer;

            color: #8D8D8D;

            &:hover {
                border-bottom: 1px solid var(--yellow-color);
                color: var(--yellow-color);   
            }
        }

        &__footer-headline {

            

            &--inactive {
                @media only screen and (max-width: 950px)  {
                    padding-bottom: 15px;
                }
            }


            svg {
               

                width: 18px;
                height: 18px;
                fill: #8d8d8d;

                @media only screen and (min-width: 951px)  {
                    display: none;
                }

                &:hover {
                    border: 0px;
                }
            }

            .expand_less {
                position: relative;
                left: -4px;
                transform: rotate(-90deg);
                &:hover {
                    border: 0px;
                }
            }

            .expand_more {
                position: relative;
                left: -7px;
                transform: rotate(90deg);
                &:hover {
                    border: 0px;
                }
            }

            @media only screen and (max-width: 950px)  {
                display: flex;
                justify-content: space-between;
 
                border-top: 1px solid var(--spacer-color);

                padding-top: 15px;
                padding-left: var(--margin-main);
                padding-right:  var(--margin-main);

                cursor: pointer;


                
                .material-symbols-outlined { 
                    font-variation-settings:
                    'FILL' 0,
                    'wght' 400,
                    'GRAD' 0,
                    'opsz' 48;

                    &:hover {
                        text-decoration: none;
                        border-bottom: 0px !important;
                        color: #8D8D8D;

                    }

                    transform: scale(1.2);
                }
                
            }



            h4 {
                margin-bottom: 24px;
                font-weight: 500;

                @media (max-width: 950px) {
                    font-size: 18px;

                    margin-top: 0px;
                    margin-bottom: 0px;
    
                }


                @media (min-width: 951px) {
                    font-size: 18px;
                }

            }
        }

        &__footer-data {

            &--inactive {
                
                @media (max-width: 950px) {
                    display: none;
                }
            }

            .non-clickable {      
                
                cursor: default !important;

                span, p {
                    cursor: default !important;
                }

                &:hover {
                    span, p {
                        color: #8D8D8D !important;
                        text-decoration: none !important;
                        border-bottom: 0px !important;
                    }
                }
    
            }
        }
    
    }

    footer {
        text-align: center;

        border-top: 1px solid var(--spacer-color);
        padding-top: 20px;
        padding-bottom: 20px;

        color: #8D8D8D;

        font-size: 14px;
        
        @media (max-width: 950px) {
            padding-left: var(--margin-main);
            padding-right: var(--margin-main);

            background-color: #fafafa;
        }


        @media (min-width: 951px) {
            margin-top: 20px;
            margin-left: var(--margin-main);
            margin-right: var(--margin-main);
        }
    }

}