
.data-panel {

    &__change-password {
       
        &__requirements {
            line-height: 22px;
            color: #3a3a3a;

            @media only screen and (max-width: 950px)  {
                margin: 50px 2.5% 30px 2.5%;
            }

            @media only screen and (min-width: 951px)  {
                margin: 30px 2.5% 40px 2.5%;
            }
        
            &__yesNo {
                padding-left: 10px;

                &--yes {
                    color: #38a1af !important;
                }

                &--no {
                    color: var(--invalid-color);
                }
            }

            &__list-name {
                font-weight: 600;
            }
        
            &__list {
                display: flex;
                justify-content: space-between;
        
                @media only screen and (max-width: 413px)  {
                    margin: 12px 10% 0px 2.5%;                    
                }
        
                @media only screen and (max-width: 950px)  {
                    margin: 12px 2.5% 0px 2.5%;
                    flex-wrap: wrap;
                }

                @media only screen and (min-width: 951px)  {
                    margin: 12px 20% 0px 17px;
                }

                &--short {
                    @media only screen and (min-width: 1116px)  {
                        margin: 12px 35% 0px 17px !important;
                    }

                }
        
                &__element {
                    display: flex;
                    white-space: nowrap;
        

                    &--short {
                        @media only screen and (max-width: 413px)  {
                            justify-content: left;
                            width: 122px;
                        }
                    }
        
                    .material-symbols-outlined {
                        margin-right: 10px;
                        position: relative;
        
                        bottom: 1px;
        
                        &--filled {
                            color: var(--highlited-color);
                            font-variation-settings:
                            'FILL' 1,
                            'wght' 400,
                            'GRAD' 0,
                            'opsz' 48
                        }
                    }

                }
            }
        }
    }
}