.data-panel {
    &__quiz {
        &__anwsers {
            &__anwser {

                &.skeleton {
                    border: 0px;


                }

                .skeleton__img {
                    @media only screen and (min-width: 1126px)  {
                        min-width: 300px;
                        min-height: 157px;
                    }

                    @media only screen and (min-width: 950px) and (max-width: 1125px) {
                        min-width: 258px !important;
                        min-height: 121px !important;
                    }
                }

                &__index {
                    &.skeleton {
                        display: none;
                    }
                }
            }
        }
    }
}