.go-to-shop-panel {
    @media only screen and (min-width: 951px) and (max-width: 1551px)  {
        margin-right: calc(3.5% - 4px);    
    }

    @media only screen and (min-width: 1552px)  {
        // margin-left: 30px;
        margin-right: 0px;
    }

    &__content-wrapper {
        display: flex;
        justify-content: center;

        margin-top: 24px;

        &__button {
            padding: 2px 13px 10px 20px;

            svg {
                position: relative;
                top: 6px;
                left: 3px;
                margin-left: 5px;
            }
        }
    }

    &__description {
        height: auto !important;

        // margin-bottom: 15px;

        padding-bottom: 10px;
        margin-bottom: 10px;
        border-bottom: 1px solid var(--spacer-color);
    }

    &__data {

        &__name {
            margin-left: 20px;
            margin-bottom: 10px;
            font-weight: 600;

            font-size: 15px;
        }

        &__row {
            margin-left: 18px;
            margin-bottom: 1px;

            color: #3a3a3a;

            svg, .material-symbols-outlined  {
                position: relative;
                top: 6px;
                margin-right: 10px;
            }

            .material-symbols-outlined {
                color: #8f2c5f;


                font-variation-settings:
                'FILL' 0,
                'wght' 380,
                'GRAD' 0,
                'opsz' 24;
            
                left: 1px;
            }
        }
    }
}

.artificial-spacer {

    @media only screen and (max-width: 950px) {
        display: none; 
    }

    @media only screen and (min-width: 1552px)  {
        display: none;
    }
}