

.login-panel {
  @media only screen and (min-width: 951px)  {
    min-height: 565px;
  }
}

.sendResetEmail-panel {
  @media only screen and (min-width: 951px)  {
    min-height: 510px;
  }
  
}

.signUp-panel .resetPassword-panel { 
  &--everything-closed {
    @media only screen and (min-width: 951px)  {
      min-height: 695px;
    }
  }

  &--pass-active {
    @media only screen and (min-width: 951px)  {
      min-height: 765px;
    }
  }

  &--pass-confirm-active {
    @media only screen and (min-width: 951px)  {
      min-height: 720px;
    }
  }

  &--double-pass-active {
    @media only screen and (min-width: 951px)  {
      min-height: 800px;
    }
  }
}

.login-panel, .signUp-panel, .resetPassword-panel, .sendResetEmail-panel {
  width: 100vw;

  @media only screen and (max-width: 950px)  {
    height: auto;
  }

  @media only screen and (min-width: 951px)  {
    height: 100vh;
  }


  display: flex;

  --form-width: 380px;

  &__form {
    background-color: #ffff;

    @media only screen and (max-width: 950px)  {
      width: 100%;

    }

    @media only screen and (min-width: 951px)  {
      width: var(--form-width); 
    }

    --left-margin: 18px;


    .Logo {
      margin-top: 40px;
      width: 50%;
    }

    &__logo-wrapper {
      cursor: pointer;
    }

    &__headline {
      font-size: 25px;
      font-weight: 600;

      margin-top: 20px;
      margin-left: var(--left-margin);

      color: #337579;
    }

    &__sign-up {
      margin-left: var(--left-margin);

      @media only screen and (max-width: 950px)  {

        margin-top: 12px;
        font-size: 20px;
        font-weight: 600;

        a {
          font-size: 20px !important;
          font-weight: 600 !important;
        }
      }

      @media only screen and (min-width: 951px)  {
        margin-top: 10px;
        font-weight: 500;
        font-size: 18px;
      }

      a {
        text-decoration: none;
        color: #40aab8;
        font-weight: 500;
      }

    }

    &__forgot-password {
      margin-top: 35px;
      // margin-top: 65px;
      padding-top: 20px;

      border-top: 1px solid #c5c5c5;
    
      width: calc(100% - 2*var(--left-margin));
      margin-left: var(--left-margin);
      margin-right: var(--left-margin);

      text-align: right;

      a {
        text-decoration: none;
        color: #bfbfbf;
      
        @media only screen and (max-width: 950px)  {
          font-size: 18px;
          font-weight: 500;
        }

        @media only screen and (min-width: 951px)  { 
          font-weight: 500;
          font-size: 16px;
        }
      }

      &__invalid-credentials {
        span {

          @media only screen and (max-width: 950px)  {
            font-size: 18px;
            font-weight: 550;
          }
      
          @media only screen and (min-width: 951px)  { 
            font-size: 16px;
            font-weight: 500;
          }

          color: #b70f0f;

          &.valid {
              color: #40aab8 !important;
            
          }

        }
      }


      &__mobile-reset-button {

        @media only screen and (min-width: 951px)  {
          display: none;
        }

        margin-top: 40px !important;
        width: 100%;

        margin-top: 15px;
        border:solid 1px;
        border-radius: 5px;

        font-size: 16px;
        font-weight: 500;
        
        cursor: pointer;

        width: 100%;
        padding: 10px 15px 10px 15px;     
        
        font-weight: 600;
        font-size: 18px;

        border-color: #40aab8;
        color: #ffff;
        background-color: #40aab8;
      
      }
    }

    &__email-input-section {
      &--tall {
        margin-bottom: 55px;
      }
    }



    &__email-input-section, &__password-input-section {
      position: relative;

      &--short {
        input {
          margin-bottom: 25px !important;
        }
      }

      &--validation-active {
        margin-bottom: 30px !important;

        input {
          margin-bottom: 12px !important;
        }
      }

      &__password-validation, &__email-validation {
        color: #b70f0f;
        padding-left: 2px;

      }

      &__email-validation {
        padding-right: 28px !important;
      }

      .material-symbols-outlined {
        position: absolute;

        @media only screen and (max-width: 950px)  {
          top: 41px;
          left: 92%;
        }
    
        @media only screen and (min-width: 951px)  { 
          top: 36px;
          left: 82%;
        }

        &--valid {
          color: #40aab8;

          font-variation-settings:
          'FILL' 0,
          'wght' 500,
          'GRAD' 0,
          'opsz' 48;
        }

        &--invalid {
          color: #b70f0f;

          font-variation-settings:
          'FILL' 1,
          'wght' 700,
          'GRAD' 0,
          'opsz' 48;

          transform: scale(0.8);
        }
      }
    }

    &__buttons {
      display: flex;

      @media only screen and (max-width: 950px)  {
          width: 100%;
          background-color: #ffff;
      }
     
      .button {
        &--active {
          border-color: #40aab8;
          color: #ffff;
          background-color: #40aab8;
        
          &:hover {
            background-color: #337579;
            border-color: #337579;
          }
        }

        &--inactive {
          border-color: #aaaaaa;
          color: #a1a1a1;
          background-color: rgb(222, 222, 222);
        }

        &--wide {
          @media only screen and (min-width: 951px)  {
            width: 40%;
          }
        }

        &--desktop-full-width {
          @media only screen and (min-width: 951px)  {
            width: calc(85% + 26px);
            padding-top: 12px;
            padding-bottom: 12px;
          }
        }

        &--big {
          padding: 7px 15px 7px 15px !important;
        }

        &--huge-mobile {
          padding: 7px 15px 7px 15px !important;

          @media only screen and (max-width: 950px)  {
            padding: 10px 15px 10px 15px !important;
            margin-bottom: 60px;
          }
        }

        &--reset-password { 
          margin-left: 44px;

          @media only screen and (max-width: 950px)  {
            display: none;
          }
        }
      }
    }

    form {
      
      @media only screen and (max-width: 950px)  {
        margin-left: 3.5%;
        margin-right: 3.5%;

        margin-top: 40px;
      }
  
      @media only screen and (min-width: 951px)  { 
        margin-left: 21px;
        margin-top: 50px;
      }

      input, label {
        display: block;
      }

      label {
        margin-bottom: 7px;
        font-weight: 500;

        @media only screen and (max-width: 950px)  {
          font-size: 18px;
          font-weight: 550;
        }
    
      }
    
      input {
        margin-bottom: 30px;

        font-size: 16px;
        padding: 12px;

        border: none;
        border:solid 1px;
        border-radius: 5px;

        @media only screen and (max-width: 950px)  {
          width: calc(100% - 12px*2);

          padding-top: 15px;
          padding-bottom: 15px;
        }
    
        @media only screen and (min-width: 951px)  { 
          width: 85%;
        }
      }

      .input {
        &--valid {
          border-color:  #40aab8;
        }
  
        &--invalid {
          border-color: #b70f0f;
        }
  
        &--default {
          border-color: #a1a1a1;
        }
      }


      button {
        margin-top: 15px;
        border:solid 1px;
        border-radius: 5px;

        font-size: 16px;
        font-weight: 500;
        
        cursor: pointer;

        @media only screen and (max-width: 950px)  {
          width: 100%;
          padding: 10px 15px 10px 15px;     
          
          font-weight: 600;
          font-size: 18px;
        }
    
        @media only screen and (min-width: 951px)  { 
          padding: 6px 15px 6px 15px;
        }
      }      
    }
  }

  &__svg-container {

    @media only screen and (max-width: 950px)  {
      display: none;
    }

    // @media only screen and (max-width: 1694px) {
    //   --top-bar: 72px;
    // }

    // @media only screen and (min-width: 1695px) {
    //   --top-bar: 76px;
    // }

    // width: 100vw;
    // height: calc(100vh - var(--top-bar) - 46px);

    position: relative;
    width: calc(100% - var(--form-width));
    background-color: #0d0c2b;

    overflow-y: hidden;
    overflow-x: hidden;

      img {
        // top: -250px;
        
        // top: -14.5vw;

        bottom: -6vw;

        width: calc(0.85 * 100vw);
        height: calc(0.85 * 100vw);



        left: 0px;


        position: absolute;

        // bottom: -100px;
        // width: calc(0.65 * 100vw);
        // height: calc(0.65 * 100vw);

        height: auto;

        overflow-y: hidden;
        overflow-x: hidden;

        min-width: 1050px;
        min-height: 750px;


      }

    
  }
}


