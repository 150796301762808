.main-page {

    &__guarantee {

        @media only screen and (max-width: 950px)  {
            margin-top: 40px;
            margin-bottom: 60px;
            margin-left: 20px;
            margin-right: 20px;
        }

        @media only screen and (min-width: 951px)  {
            margin-right: auto;
            margin-left: auto;

            &.coursePreview{
                margin-left: 0px;
                padding: 10px;
                margin-top: 30px;
                margin-bottom: 25px;
            }
            max-width: 650px;

            margin-bottom: 90px;

        }

       
        transform: scale(0);
        opacity: 0;
        transition: transform 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55), opacity 0.5s ease;

        &.visible {
            transform: scale(1);
            opacity: 1;
        }
      


        border: 1px solid var(--spacer-color);
        display: flex;
        position: relative;

        background-color: #ffff;
        border-radius: var(--panel-radius);

        padding: 15px 20px 0px 10px;

        -webkit-box-shadow: 8px 8px 24px -20px rgba(66, 68, 90, 1);
        -moz-box-shadow: 8px 8px 24px -20px rgba(66, 68, 90, 1);
        box-shadow: 8px 8px 24px -20px rgba(66, 68, 90, 1);


        &__rules {

            position: relative;

            &__background {
                width: 100%;
                height: 100%;

                z-index: 4;

                position: fixed;

                left: 0;
                top: 0;
                background-color: rgba(0, 0, 0, 0.405);
                cursor: default;
                -webkit-backdrop-filter: blur(2px);
            }

            &__panel {
                @media only screen and (max-width: 950px)  {
                    width: 95vw;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);

                }
                
                @media only screen and (min-width: 951px)  {
                    --panel-height: 260px;
                    width: 600px;
                    height: var(--panel-height);
                    top: calc(50vh - 0.5 * var(--panel-height));

                    padding-bottom: 30px;
                }

                border-radius: var(--panel-radius);
                background-color: #ffff;
                z-index: 5;

                position: fixed;
                left: 0;
                right: 0;
                margin: 0 auto;


                -webkit-box-shadow: 6px 6px 24px -4px rgba(66, 68, 90, 1);
                -moz-box-shadow: 6px 6px 24px -4px rgba(66, 68, 90, 1);
                box-shadow: 6px 6px 24px -4px rgba(66, 68, 90, 1);


                h3 {
                    margin-top: 30px;
                    text-align: center;
                }

                &__section {
                    display: flex;

                    padding-left: 10px;
                    padding-right: 10px;

                    margin-bottom: 7px;

                    span {
                        display: block;
                    }

                    &__icon {
                        font-size: 22px;
                        margin-right: 5px;

                        position: relative;

                        top: -7px;
                    }

                    &__text {
                        font-size: 16px;
                    }
                }


                &__warning {

                    b {
                        color: #d81717;
                    }

                    @media only screen and (max-width: 950px)  {
                        border-top: 1px solid var(--spacer-color);
                        margin-left: 15px;
                        margin-right: 15px;     

                        margin-top: 10px;
                        padding-top: 10px;
                    }
                    
                    @media only screen and (min-width: 951px)  {
                        border-top: 1px solid var(--spacer-color);
                        margin-left: 20px;
                        margin-right: 20px;

                        padding-top: 10px;
                        margin-top: 15px;

                        padding-left: 15px;
                        padding-right: 15px;
                    }
    
                    
                }

                button {
                    margin-left: auto;
                    margin-right: auto;

                    display: block;

                    margin-top: 30px;
                    margin-bottom: 30px;

                    background-color: var(--yellow-color);
                    border: 0;
                    border-radius: var(--panel-radius);
                    color: #1a1a1a;
                    cursor: pointer;
                    font-size: 16px;
                    font-weight: 500;
                    padding: 10px 17px;
                    
                }

            }
    
        }


        &__sign {

            border-radius: 90px;

            right: -12px;
            top: -12px;
            position: absolute;

            background-color: #f5b6ea;
            padding: 5px 5px 0px 5px;


            svg {
                fill: #ad479c;

                width: 20px;
                height: 20px;
            }
        }

        &__money {
            margin-right: 15px;
            margin-bottom: 15px;
            font-size: 35px;


            max-width: 35px;
            max-height: 48px;
            

            @media only screen and (min-width: 951px)  {

            }

        

            @media only screen and (max-width: 950px)  {
                margin-top: auto;
                margin-bottom: auto;   

                position: relative;
                top: -7px;
            }



            padding: 10px 15px 10px 15px;

            border-radius: var(--panel-radius);

            background-color: #f5b6ea;
            -webkit-box-shadow: 8px 8px 24px -20px rgba(66, 68, 90, 1);
            -moz-box-shadow: 8px 8px 24px -20px rgba(66, 68, 90, 1);
            box-shadow: 8px 8px 24px -20px rgba(66, 68, 90, 1);
        }

        &__text {

            span {
                display: block;
            }

            &__headline {
                font-size: 18px;
                font-weight: 500;
                margin-bottom: 9px;

                &.mobile {
                    @media only screen and (min-width: 951px)  {
                        display: none;
                    }
                }

                &.desktop {
                    @media only screen and (max-width: 950px)  {
                        display: none;
                    }

                }

            }

            &__description {


                &.mobile {
                    @media only screen and (min-width: 951px)  {
                        display: none;
                    }
                    margin-bottom: 15px;
                }

                &.desktop {
                    @media only screen and (max-width: 950px)  {
                        display: none;
                    }

                }

                b {
                    font-weight: 500;

                    cursor: pointer;

                    color: #8f2c5f;
                    font-weight: 500;
                    // text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.17);
                }


                svg {
                    width: 16px;
                    height: 16px;

                    fill: #8f2c5f;
                
                    position: relative;
                    top: 3px;
                }
            }
        }
    }
}