.data-panel {

    &__user-panel {

        &__description {
            display: flex;
            padding-top: 40px;
            padding-bottom: 20px; 
            margin-bottom: 35px; 

            margin-left: 2.5%;
            margin-right: 2.5%;
            border-bottom: solid 1px var(--spacer-color); 

            h4 {
                margin: 0px;
                margin-left: calc(2.5% + 12px);

                
                font-size: 20px !important;
                font-weight: 600;

                position: relative;

                span {
                    color: var(--highlited-color);
                }
            }
        }


        --max-width: 350px;
        --min-width: 310px;

        &__courses-large {
            width: 95%;
            margin: 0px auto;

            display: grid;

            @media only screen and (max-width: 990px)  {
                grid-template-columns: 1fr;
            }

            @media only screen and (min-width: 991px) {
                // grid-template-columns: repeat(auto-fill, minmax(var(--min-width), 1fr));
                grid-auto-flow: column;
            }
            
            grid-column-gap: 10px;
            grid-row-gap: 20px;

            justify-content: space-evenly;
            justify-items: center;
            align-content: space-evenly;
            align-items: center;


            --margin: 8px;

            .ad-panel {
                // ...
            }

            .course-panel {
                // ...
            }

        }


        &__buttons {
            margin: 40px 2.5% 0px 2.5%;
            padding: 20px 0px 0px 0px; 

            border-top: 1px solid var(--spacer-color);

            display: flex;
            justify-content: space-between;

            &__button {
                // ...
            }

            &__navigation {
                position: relative;
                margin-top: 10px;
                font-size: 18px;

                @media only screen and (max-width: 990px) {
                    display: none;
                }

                &__separator {
                    padding: 0px 5px 0px 5px;
                }

                &__number {
                    padding: 3px;
                    font-weight: 600;
                    color: var(--highlited-color);
                }
            }
        }

    }

}