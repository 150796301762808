.data-panel {

    &__close-account {

        &__headline {
            margin: 0px 2.5% 40px 2.5%;

            &__main {
                display: block;
                font-size: 16px;
                font-weight: 600;
                margin-bottom: 15px;
                line-height: 22px;
            }

            &__text {
                display: block;
                line-height: 22px;
                color: #3a3a3a;

                b {
                    font-weight: 600;
                }
            }
        }

        &__text {
            margin: 0px 2.5% 30px 2.5%;
            
            display: block;
            line-height: 22px;
            color: #3a3a3a;
        }


        &__feedback {
            margin: 40px 2.5% 0px 2.5%;

            &__question {
                display: block;
                line-height: 22px;
                font-weight: 600;
                margin-bottom: 15px;
            }

            &__panel {
                padding: 10px 20px 10px 20px;
                border-left: 2px solid var(--highlited-color);
                border-right: 1px solid var(--spacer-color);
                
                display: flex;
                justify-content: left;

                textarea {
                    padding: 7px;
                    width: calc(100% - 5px);
                    resize: none;

                    font-size: 16px;
                    color: #3a3a3a;
                    line-height: 22px;

                    outline: none !important;
                    border: 1px solid var(--spacer-color);
                }
            }

        }
    }
}