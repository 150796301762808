.data-panel {

    &__results-1 {

        &__score {
            display: flex;
            margin-top: 50px;
            margin-bottom: 30px;

            h4 {
                margin: 0px;
                margin-left: 2.5%;

                font-size: 18px;
                font-weight: 600;

                position: relative;

                @media only screen and (max-width: 950px)  {
                    padding-top: 30px;
                }

                &::before{
                    content: '';
                    position: absolute;
                    left: 0;
                    bottom: -10px;
                    background-color: var(--highlited-color);
                    height: 2px;
                    box-sizing: border-box;
                    width: 20%;
                }

                span {
                    color: var(--highlited-color);
                }
            }
        }

        &__motivation {
            display: flex;
            margin: 40px 2.5%;


            &__icon {
                margin-right: 10px;
            }

            &__text {

                &__headline {
                    margin-top: 3px;
                    margin-bottom: 15px;
                    color: #3a3a3a;
                }

                &__regular-text {
                    color: #3a3a3a;
                    line-height: 22px;
                }
            }
        }

        &__correct-anwsers {

            &__title {
                display: flex;
                margin-bottom: 20px;
                margin-top: 40px;
    
                span {
                    margin-left: 2.5%;
                    
                    font-size: 18px;
                    font-weight: 600;
                }      
            }

            &__checkbox {

                margin: 45px 2.5% 0px 2.5%; 
                padding: 5px 20px;
                border-left: 1px solid var(--spacer-color); 
                border-right: 1px solid var(--spacer-color);

                display: flex;
                justify-content: space-between;

                input {
                    outline: none;
                    height: 25px;
                    width: 50px;
                    position: relative;

                    border-radius: calc( var(--panel-radius) - 3px);

                    -webkit-appearance: none;
                    
                    background-color: var(--highlited-light-color);
                    box-shadow: inset 0 0 3px rgba(0,0,0,0.2);

                    cursor: pointer;
                    transition: 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);

                    &:checked{ 
                        background: var(--highlited-color);

                        &:before{
                            left: 23px;
                        }
                    }

                    &:before{
                        position: absolute;
                        content: "";
                        left: 0;
                        height: 100%;
                        width: 27px;

                        box-shadow: 1px 1px 4px rgba(0,0,0,0.2);
                        border-radius: calc( var(--panel-radius) - 3px);
                        
                        background: #fcfcfc;
                        
                        transform: scale(0.80);
                        transition: left 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
                    }

                    &::after { 
                        position: absolute;
                        content: "";
                        left: -22px;
                        bottom: -7px;
                        background-color: var(--spacer-color);
                        height: 41px;
                        box-sizing: border-box;
                        width: 1px;
                    }
                }

                .text {
                    font-size: 16px;
                    display: flex;
                    align-items: center; 

                    &--active {
                        color: #3a3a3a;
                        font-weight: 600;
                    }

                    &--inactive {
                        color: #828a96;
                        font-weight: 500;
                    }
                }
            
            }

            &__spacer { 
                margin: 10px 5% 9px 5%;
                border-bottom: 1px solid var(--spacer-color);
            }

            &__question-number {
                display: flex;
                margin-top: 50px;
                margin-bottom: 30px;
    
                h4 {
                    margin: 0px;
                    margin-left: auto;
                    margin-right: auto;
    
                    font-size: 18px;
                    font-weight: 600;
    
                    position: relative;

                    &::before{
                        content: '';
                        position: absolute;
                        left: 25%;
                        bottom: -10px;
                        background-color: var(--highlited-color);
                        height: 2px;
                        box-sizing: border-box;
                        width: 50%;
                    }
                }
            }

            &__headlines-spacer {
                margin: 0px 2.5%;
                margin-top: 40px;
                height: 1px;
                border-bottom: 1px solid var(--spacer-color);
            }

            &__bottom-spacer {
                margin: 0px 2.5%;
                height: 1px;
                border-bottom: 1px solid var(--spacer-color);
            }

            &__headlines-wrapper {
                justify-content: space-between;
                width: 100%;

                @media only screen and (min-width: 951px)  {
                    display: flex;
                }

                &__headline {
                    display: flex;
                    justify-content: center;

                    font-size: 16px;
                    font-weight: 600;

                    padding-top: 20px;
                    padding-bottom: 10px;

                    border-bottom: 1px solid var(--spacer-color);

                    @media only screen and (max-width: 950px)  {
                        margin-left: 2.5%;
                        margin-right: 2.5%;
                        width: 95%;
                    }
    
                    @media only screen and (min-width: 951px)  {
                        width: 50%;
                    }

                    &--correct {
                        @media only screen and (min-width: 951px)  {
                            border-right: 1px solid var(--spacer-color);
                            margin-left: 2.5%;
                        }
                    }

                    &--yours {
                        @media only screen and (max-width: 950px)  {
                            border-top: 1px solid var(--spacer-color);
                        }

                        @media only screen and (min-width: 951px)  {
                            margin-right: 2.5%;
                        }
                    }

                    .material-symbols-outlined {
                        position: relative;
                        bottom: 4px;
                        right: 6px;
                        color: var(--highlited-color);
                        transform: scale(1.1);

                        font-variation-settings:
                        'FILL' 0,
                        'wght' 400,
                        'GRAD' 0,
                        'opsz' 48;
                    }
                }

                &__mobile {

                    @media only screen and (min-width: 951px)  {
                        display: none;
                    }

                    display: flex;
                    justify-content: center;
                    position: relative;

                    img {
                        margin-left: auto;
                        margin-right: auto;
                        width: 100%;
                        max-width: 350px;
                        height: auto;
    
                        pointer-events: none;
                        user-select: none;
                    }


                    .text-anwser {
                        margin: 0;
                        position: absolute;
                        top: calc(50% - 10px);
                        -ms-transform: translateY(-50%);
                        transform: translateY(-50%);

                        --padding: 10px;
                        padding: 0px var(--padding) 0px var(--padding);


                        width: calc(100% - calc(var(--padding) * 2));
                        max-width: calc(321px - calc(var(--padding) * 2));

                        display: flex;
                        justify-content: center;


                        span {

                            font-size: 16px;
                            line-height: 22px;
                            color: #3a3a3a;

                            margin-left: auto;
                            margin-right: auto;
                        }
                    }
                }
            }

            &__anwsers-wrapper {
                display: flex;     
                
                @media only screen and (max-width: 950px)  {
                    display: none;
                }

                &__anwser {
                    width: 50%;
                    display: flex;
                    justify-content: center;
                    position: relative;

                    &--left {
                        margin-left: 2.5%; 
                        border-right: 1px solid var(--spacer-color);
                    }

                    &--right {
                        margin-right: 2.5%;
                    }

                    img {
                        width: 100%;
                        max-width: 321px;
                        height: auto;
    
                        pointer-events: none;
                        user-select: none;
                    }

                    .text-anwser {
                        margin: 0;
                        position: absolute;
                        top: 50%;
                        -ms-transform: translateY(-50%);
                        transform: translateY(-50%);

                        --padding: 20px;
                        padding: 0px var(--padding) 0px var(--padding);


                        // width: calc(100% - 50px);
                        width: calc(100% - calc(var(--padding) * 2));
                        max-width: calc(321px - calc(var(--padding) * 2));

                        display: flex;
                        justify-content: center;

                        span {
                            line-height: 22px;
                            color: #3a3a3a;
                        }

                    }
                }
            }
        }

        &__buttons {
            border-top: 1px solid var(--spacer-color);

            display: flex;
            justify-content: space-between;

            @media only screen and (max-width: 950px)  {
                margin-left: 7.5%;
                margin-right: 7.5%;
                padding: 20px 0px 0px 0px;
            }

            @media only screen and (min-width: 951px)  {
                
                margin-left: 2.5%;
                margin-right: 2.5%;
                padding: 20px 5% 0px 5%;
            }

            &__button {
                width: 105px;

                border-radius: var(--panel-radius);
                border: 2px solid var(--highlited-color);

                display: flex;
                justify-content: center;

                cursor: pointer;
        
                -webkit-user-select: none; /* Safari */        
                -moz-user-select: none; /* Firefox */
                -ms-user-select: none; /* IE10+/Edge */
                user-select: none; /* Standard */

                &:hover {
                    border-color: #2c8894;
                }

                &:active {
                    transform: scale(0.98);
                }
                
                &--inactive {
                    color: #c5c5c5 !important;
                    border-color: #d2d2d2 !important;

                    &:hover {
                        border-color: #d2d2d2 !important;
                    }

                    &:active {
                        transform: scale(1.0) !important;
                    }
                }

                &--prev {
                    padding: 10px 20px 5px 17px;
                }

                &--center {
                    width: auto !important;
                    padding: 10px 15px 5px 12px;
                    white-space: nowrap;

                    --yellow-colour: #ffca43;
                    --yellow-hovered-colour: #fac130;

                    font-weight: 500;
                    background-color: var(--yellow-colour);
                    border-color: var(--yellow-colour);

                    @media only screen and (max-width: 1050px) and (min-width: 951px) {
                        padding: 10px 15px 10px 15px;    
                    }
        
                    @media only screen and (min-width: 1051px)  {
                        padding: 10px 15px 5px 12px;                        
                    }

                    &:hover {
                        background-color: var(--yellow-hovered-colour) !important;
                        border-color: var(--yellow-hovered-colour) !important;
                    }

                    .material-symbols-outlined {
            
                        margin-right: 8px;
                        position: relative;
                        top: -3px;

                        font-variation-settings:
                        'FILL' 0,
                        'wght' 350,
                        'GRAD' 0,
                        'opsz' 48;

                        @media only screen and (max-width: 1050px)  {
                            display: none;
                        }
                    }
                }

                &--next {
                    padding: 10px 14px 5px 20px;

                    .material-symbols-outlined {
                        left: 7px;
                    }
                }

                .material-symbols-outlined {
                    position: relative;
                    bottom: 3px;
                }

            }
        }

        &--mobile-hidden {
            @media only screen and (max-width: 950px) {
                display: none;
            }
        }

        &__repeat-button-mobile {

            @media only screen and (min-width: 951px) {
                display: none;
            }

            margin-top: 45px;
            display: flex;
            justify-content: center;

            &__button {
                width: min-content !important;
                padding: 10px 25px 5px 22px; 
                
                .material-symbols-outlined {
                    display: inline !important;
                }
            }
        }
    }
}