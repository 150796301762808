.data-panel {

    &__change-username {

        &__wrapper {
            margin: 0px 2.5% 0px 2.5%;
            padding: 10px 0px 10px 0px;

            border-left: 2px solid var(--highlited-color);
            border-right: 1px solid var(--spacer-color);

            
            @media only screen and (min-width: 951px)  {
                display: flex;
                justify-content: space-between;
            }


            // end temp

            &__current-username, &__current-name {
                padding-left: 15px;
                font-size: 16px;

                display: flex;


                @media only screen and (max-width: 950px) {
                    margin-bottom: 30px;
                }  

                @media only screen and (min-width: 951px) {
                    justify-content: center;
                    align-items: center;
                }

                &__text {

                    width: min-content;
                    height: min-content;

                    white-space: nowrap;
                }
     
                span {
                    display: block;

                    @media only screen and (max-width: 950px) {
                        font-weight: 600;
                    }

                }

                &__user-data {
                    font-weight: 600;
                    color: #1a1a21;

                    @media only screen and (max-width: 950px) {
                        margin-top: 4px;
                        color: var(--highlited-color);
                    }

                    @media only screen and (min-width: 951px) {
                        margin-top: 10px;
                    }
                }
            }
    
            &__input-section {

                padding-left: 15px;
                margin-right: 2.5%;

                position: relative;

                    &::before {
                        @media only screen and (min-width: 951px) {
                            content: '';
                            position: absolute;
                            left: -2.5%;
                            bottom: -10px;
                            background-color: var(--spacer-color);
                            height: 86px;
                            box-sizing: border-box;
                            width: 1px;
                        }
                    }
                
                input[type=text] {
                  
                    font-size: 16px;
                    
                    padding: 10px 10px;
                    box-sizing: border-box;
                    border-radius: var(--panel-radius);
                    border: 2px solid var(--highlited-color);

                    @media only screen and (max-width: 950px) {
                        width: 100%;
                    }

                    @media only screen and (min-width: 951px) {
                        width: 230px;
                    }

                    &::placeholder {
                        color: #bebebe;

                    }
                }

                &__field-name {
                    display: flex;

                    @media only screen and (max-width: 950px) {
                        margin-bottom: 8px;
                    }

                    @media only screen and (min-width: 951px) {
                        margin-left: 10px;

                        font-size: 14px;
                        margin-bottom: 8px;
                    }
                }
            }
        }

    }

}