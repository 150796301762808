.main-page {

    &__mobile-intro {

        margin-top: 175px;
    
        margin-left: 20px;
        margin-right: 20px;
    
        position: relative;
        
        &__headline-1 {
          display: block;
          font-size: 40px;
          font-weight: 500;

          b {
            color: #8f2c5f;
            font-weight: 500;
            text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.17);
          }
        }
    
        &__headline-2 {
          display: block;
          margin-top: 12px;
          font-size: 30px;
    
          font-weight: 500;

        //   color: #464646;

          b {
            font-weight: 550;
            color: #31919e;
          }
        }
    
        .laptop {
          display: block !important;
    
          margin-top: 20px;
          // margin-left: 5%;
          // margin-right: 5%;
          width: 100%;
    
        //   margin-bottom: 60px;
          
        }
    
        &__img-wrapper {
          position: relative;
        }
    
        &__buttons {
          position: absolute;
          bottom: calc(50% - 25px);
    
          justify-content: space-between;
          width: 100%;
          display: flex;
    
          svg {
            width: 30px;
            height: 30px;
    
            fill: #ffff;
          }
    
          button {
            border: 1px solid #40aab8;
    
            background-color: rgba(70, 168, 181, 0.889);
    
            border-radius: 50px;
    
            padding-top: 9px;
            padding-bottom: 6px;
    
            padding-left: 10px;
            padding-right: 10px;

            &.inactive {
                background-color: rgba(203, 207, 208, 0.889);
                border-color: rgba(203, 207, 208, 0.889);
            }
          }
    
          &__button-left {
            svg {
              transform: rotate(180deg);
              position: relative;
    
              left: -2px;
            }
    
          }
    
          &__button-right {
            svg {
              position: relative;
    
              left: 2px;
            }
          }
        }

        &__subtitle {
            text-align: center;

            margin-top: 15px;
            margin-bottom: 60px;

            padding-left: 5px;
            padding-right: 5px;

            span {
                font-size: 22px;
                line-height: 26px;
            }

        }
    
      }
    
}