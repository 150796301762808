.main-page {

    &__course-package {

        @media only screen and (max-width: 1584px)  {

            margin-left: calc(var(--main-panel-margin) + 5%);
            margin-right: calc(var(--main-panel-margin) + 5%);
        
        }

        @media only screen and (min-width: 1585px)  {
            max-width: 1321px;

            margin-left: auto;
            margin-right: auto;
        }


        margin-bottom: 130px;
        
        @media only screen and (max-width: 950px)  {
            
        }

        @media only screen and (min-width: 951px)  {
            display: flex;
        }

        h2 {
            margin-top: 0px;
            font-size: 42px;
            display: block;
            font-weight: 500;

            margin-bottom: 40px;

            @media only screen and (max-width: 950px)  {
                text-align: center;
            }
    
        }

        h3 {
            font-size: 20px;
            line-height: 28px;

            font-weight: 500;

            @media only screen and (max-width: 950px)  {
                text-align: center;
            }

            .red {
                // color: red;

                text-decoration: line-through;
            }

            .green {
                color: green;
            }

            b {
                font-weight: 600;   
            }


        }

        &__left {

            @media only screen and (max-width: 950px)  {
                width: 100%;
            }
    
            @media only screen and (min-width: 951px)  {
                width: 50%;
            }
    
            a {
                margin-top: 15px;
                text-decoration: none;
                font-weight: 500 !important;
                color: #1a1a1a !important;

                background-color: var(--yellow-color);
              
                cursor: pointer;
                border-radius: var(--panel-radius);
                border: 2px solid var(--yellow-color);
                font-size: 18px;
                padding: 12px 24px;

                @media only screen and (max-width: 950px)  {
                    margin-bottom: 70px;

                    display: block;
                    margin-left: auto;
                    margin-right: auto;
                }
    
            }
    
            &__properties {
    
                border: 1px solid var(--spacer-color);
    
                border-radius: var(--panel-radius);
    
                display: flex;

                -webkit-box-shadow: 8px 8px 24px -21px rgba(66, 68, 90, 1);
                -moz-box-shadow: 8px 8px 24px -21px rgba(66, 68, 90, 1);
                box-shadow: 8px 8px 24px -21px rgba(66, 68, 90, 1);

                // margin-left: auto;
                // margin-right: auto;



                @media only screen and (max-width: 950px)  {
                    width: 100%;
                
                    svg {
                        display: none;
                    }
                }
        
                @media only screen and (min-width: 951px)  {
                    width: calc(100% - 50px);
                }

                &__left, &__middle, &__right {
                    
                    @media only screen and (max-width: 950px)  {
                        padding: 10px 10px 10px 10px;
                    }
            
                    @media only screen and (min-width: 951px)  {
                        padding: 15px 15px 12px 15px;
                    }

                    display: flex;

                    justify-content: space-between;

                    span {
                        display: block;
                    }

                    svg {
                        width: 30px;
                        height: 30px;

                        min-width: 30px;
                        min-height: 30px;

                        position: relative;

                        top: 10px;
                    }

                    b {
                        font-weight: 550;
                        font-size: 18px;
                    }
                    
                    .left {
                        
                        &__description {

                        }

                        &__number {
                            margin-top: 7px;
                            font-size: 24px;
                            font-weight: 550;

                            // color: #33a3b1;

                            .mobile-hidden {
                                @media only screen and (max-width: 950px)  {
                                    display: none;
                                }
                            }
                        }
                    }
                }
    
                &__left {
    
                    width: 33%;
                    border-right: 1px solid var(--spacer-color);
                
                    svg {
                        fill: #32a9b9;
                    }

                   .left__number {
                        color: #32a9b9;
                   }
                }
    
                &__middle {
    
                    width: 33%;
                    border-right: 1px solid var(--spacer-color);
                
                    svg {
       
                        fill: rgb(0, 150, 0);
                    }

                   .left__number {
                        color: rgb(0, 150, 0);
                   }
                }
    
                &__right {
                    width: 33%;

                    svg {
                        fill: #ae3674;
                    }

                   .left__number {
                        color: #ae3674;
                   }
                }
            }
    
            &__offer {
    
                display: flex;
                margin-top: 30px;
    
                max-width: 550px;
                width: 100%;

                @media only screen and (max-width: 950px)  {
                    text-align: center;
                }
    
                span {
                    font-size: 20px;
    
    
                    line-height: 28px;
                }
    
    
    
            
                &__right {
                    // width: 50%;
    
                    margin-left: auto;
                    margin-right: auto;
    
                    span {
                        display: block;
                    }
    
                }

                &__button {
                    @media only screen and (max-width: 950px)  {
                        display: flex;
                        // margin-left: auto;
                        // margin-right: auto;

                        button {
                            margin-left: auto;
                            margin-right: auto;
                        }
                    }
    
                    @media only screen and (min-width: 951px)  {
                        margin-top: 30px;
                    }
                }

            }
            
        }


        &__right {

          

            @media only screen and (max-width: 950px)  {
                width: 100%;
            }
    
            @media only screen and (min-width: 951px)  {
                width: 50%;
            }
    

            display: flex;

            &__wrapper {
                margin-top: auto;
                margin-bottom: auto;      
                
                height: min-content;
            }

            &__course-grid {

                @media only screen and (max-width: 950px)  {
                    width: 100%;
                    gap: 12px;
                }
        
                @media only screen and (min-width: 951px)  {
                    width: calc(90% - 60px);
                    padding-right: 30px;
                    padding-left: 10px;
                    gap: 20px;
                }

                @media only screen and (max-width: 1584px)  {

                    margin-left: auto;
                    margin-right: auto;
                }
        
                @media only screen and (min-width: 1585px)  {
                    margin-right: 0px;
                    margin-left: auto;
                }


               
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr;
                grid-template-rows: 1fr 1fr;

                padding-top: 10px;
                padding-bottom: 30px;

              
                border-radius: var(--panel-radius);  
                overflow: hidden;

               

                .grid-item {

                    -webkit-box-shadow: 8px 8px 24px -14px rgba(66, 68, 90, 1);
                    -moz-box-shadow: 8px 8px 24px -14px rgba(66, 68, 90, 1);
                    box-shadow: 8px 8px 24px -14px rgba(66, 68, 90, 1);

                  

                    aspect-ratio: 1; // This makes the container square
                    width: 100%;
                    overflow: hidden;
                    position: relative;


                    border-radius: var(--panel-radius);
                    img {

                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }

                }
            }

            &__courses {
                display: flex;
                flex-wrap: wrap;
                
                
                @media only screen and (max-width: 950px)  {
                    width: 100%;
                    gap: 7px;
                }
        
                @media only screen and (min-width: 951px)  {
                    width: calc(90% - 40px);
                    gap: 5px;
                }

                @media only screen and (max-width: 1584px)  {

                    margin-left: auto;
                    margin-right: auto;
                }
        
                @media only screen and (min-width: 1585px)  {
                    padding-right: 30px;
                    padding-left: 10px;
                    margin-left: auto;
                    margin-right: 0px;

                    width: 535px;
                }

                .course {

                    @media only screen and (max-width: 950px)  {
                        font-size: 16px;
                    }
            
                    @media only screen and (min-width: 951px)  {
                        font-size: 14px;
                    }

                    display: flex;
                    padding: 7px;
                    border-radius: var(--panel-radius);
                    border: 1px solid var(--spacer-color);
                    

                    background-color: #ffff;

                    -webkit-box-shadow: 8px 8px 24px -20px rgba(66, 68, 90, 1);
                    -moz-box-shadow: 8px 8px 24px -20px rgba(66, 68, 90, 1);
                    box-shadow: 8px 8px 24px -20px rgba(66, 68, 90, 1);
                    
                    svg {
                        width: 15px;
                        height: 15px;
                        margin-left: 5px;

                        position: relative;
                        top: 2px;
                    }
                }
            }
        }
    }

    
}