.ad-panel {
    height: 100% !important;

    // @media only screen and (max-width: 990px) {
    //     display: none;
    // }
    
    // &__title-box {
    //     margin: 8px 8px 8px 8px;
    //     height: 100%;

    //     border-radius: var(--panel-radius);
    //     background-color: #0d0d2b;

    //     display: flex;

    //     @media only screen and (min-width: 1045px)  {
    //         max-height: 139px;
    //     }

    //     @media only screen and (min-width: 989px) and (max-width: 1044px)  {
    //         max-height: 109px;
    //     }

    //     &__wrapper {
    //         margin: auto 0px auto 30px;
            
    //         &__element {
    //             color: #ffffff;
    //             font-size: 24px;
    //             font-weight: 600;
    
    //             &--top {
    //                 margin-bottom: 5px;
    //             }
    
    //             &--bottom {
    
    //             }
    //         }
    //     }
    // }

    &__courses-list {
        margin: 20px 20px 20px 20px;

        &__headline {
            color: #1a1a21;
            font-size: 16px;
            display: block;
            margin-bottom: 15px;
            font-weight: 600;
        }

        &__element {
            display: flex;
            margin: 10px 0px;
            color: #3a3a3a;
            font-size: 14px;

            &__dot {
                height: 6px;
                width: 6px;

                min-width: 6px;
                min-height: 6px;

                border-radius: 50px;
                border: 3px solid #ffca43;

                position: relative;
                top: 2px;

                margin-right: 10px;
            }
        }
    }

    &__text {
        margin: 0px 12px;

        font-size: 14px;
        color: #3a3a3a;

        padding: 10px 8px;
        border-top: 1px solid var(--spacer-color);
        border-bottom: 1px solid var(--spacer-color);

        &__headline {
            color: #1a1a21;
            font-size: 16px;
            display: block;
            margin-bottom: 10px;
            font-weight: 600;
        }
    }

    &__button-wrapper {
        width: 100%;
        display: flex;
        justify-content: center;

        height: 80px;

        &__button {
            margin: auto;
            padding: 10px 20px 10px 20px;
            font-weight: 500;
            border-radius: var(--panel-radius);

            --yellow-button: #ffca43; 
            border: 2px solid var(--yellow-button);
            background-color: var(--yellow-button);

            cursor: pointer;
        
            -webkit-user-select: none; /* Safari */        
            -moz-user-select: none; /* Firefox */
            -ms-user-select: none; /* IE10+/Edge */
            user-select: none; /* Standard */

            &:hover {
                background-color: #fac130 !important;
                border-color: #fac130 !important;
            }

            &:active {
                transform: scale(0.98);
            }
        }
    }
}