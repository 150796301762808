.data-panel {

    &__final-test-preview {
        &__img-and-attributes-wrapper {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
        }
        &__attributes {
            margin: 15px 2.5% 20px 2.5%;
            border-bottom: 1px solid var(--spacer-color);
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            --padding-top-and-bottom: 20px;
            padding-top: var(--padding-top-and-bottom);
            padding-bottom: var(--padding-top-and-bottom);
            // width: 100%;
            // align-content: space-between;
            // padding-bottom: 23px;
            // padding-top: 23px;

            // @media only screen and (max-width: 950px)  {
            //     flex-direction: column;
            // }

            --font-size: 20px;

            &__section {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                // margin: 20px;
                // width: 50%;

                &__element {
                    width: 100%;
                    margin-right: 2.5vw;
                    display: flex;

                    color: #3a3a3a;
                    font-size: var(--font-size);

                    white-space:nowrap;

                    span {
                        &.skeleton {
                            display: inline-block;
                            height: 28px;
                            width: 30%;
                        }
                    }

                    &__number {
                        font-size: var(--font-size);
                        font-weight: 600;
                        color: var(--highlited-color);

                        margin-right: 5px;
                    }
                }
                
            }
        }

        &__content-box {
            margin-top: 30px;
            margin-bottom: 30px;

            padding-left: 2.5%;
            padding-right: 2.5%;


            @media only screen and (max-width: 950px)  {

            }
        
            @media only screen and (min-width: 951px)  {
                display: flex;
                justify-content: space-between;
            }
        

            &__column {


                &--left {
                      
                    @media only screen and (max-width: 950px)  {
                        width: 100%;
                        margin-bottom: 60px;
                    }
                
                    @media only screen and (min-width: 951px)  {
                        width: calc(55% - 1px);
                        border-right: 1px solid var(--spacer-color); 
                    }
                }

                &--right {

                    @media only screen and (max-width: 950px)  {
                        width: 100%;
                    }
                
                    @media only screen and (min-width: 951px)  {
                        width: 45%;
                    }
                }


                &__description {
                    line-height: 20px;
                    color: #3a3a3a;
                
                    @media only screen and (min-width: 951px)  {
                        margin-right: 5%;
                    }
                }

                &__reset {
                    margin-top: 40px;

                    height: min-content;

                    padding: 20px;
                    border: 1px solid var(--spacer-color);
                    border-radius: var(--panel-radius);


                    @media only screen and (min-width: 951px)  {
                        margin-left: 10%;
                        margin-right: 15%;
                    }


                    &__headline {
                        text-align: center;
                        margin-bottom: 20px;

                        font-weight: 500;
                    }

                    &__description {
                        line-height: 20px;
                        color: #3a3a3a;

                        b {
                            font-weight: 500;
                        }
                    }

                    &__button-wrapper {
                        display: flex;

                        .active {
                            background-color: var(--yellow-color);
                            color: #1a1a21;
                            border: 2px solid;
                            border-color: var(--yellow-color);

                            &:hover {
                                background-color: var(--yellow-hover-color);
                                border-color: var(--yellow-hover-color);
                            }
            
                            &:active {
                                transform: scale(0.98);
                            }
                        }

                        .inactive {
                            border: 1px solid #aaaaaa;
                            color: #aaaaaa;
                            background-color: var(--spacer-color);
                        }

                        button {
                            margin-left: auto;
                            margin-right: auto;

                            margin-top: 30px;
                            // margin-bottom: 20px;

                            background-color: #ffff;

                            padding: 10px 15px 5px 18px;
                            border-radius: var(--panel-radius);

                            display: flex;
                            cursor: pointer;
                            
                            font-size: 16px;

                            font-weight: 500;


                            .material-symbols-outlined {
                                margin-right: 10px;
                                position: relative;
                                top: -3px;

                                font-variation-settings:
                                'FILL' 0,
                                'wght' 400,
                                'GRAD' 0,
                                'opsz' 48;
                            }
                        }
                    }
                }

                &__headline {
                    font-size: 18px;
                    font-weight: 600;
                    text-align: center;
                    margin-bottom: 15px;

                    position: relative;

                    &--left-aligned {
                        text-align: left !important;
                        margin-bottom: 33px;

                        &::before {
                            content: "";
                            position: absolute;
                            left: 0;
                            bottom: -10px;
                            background-color: var(--highlited-color);
                            height: 2px;
                            box-sizing: border-box;
                            width: 50px;
                        }
                    }

                }

                &__results-info {

                    @media only screen and (min-width: 951px)  {
                        margin-left: 5%;
                        margin-right: 5%;
                    }

                    line-height: 20px;
                    color: #3a3a3a;

                    margin-bottom: 25px;
                }

                &__completion-score {
                    margin-left: 5%;
                    margin-right: 5%;

                    &__headline {
                        margin-top: 15px;
                        margin-bottom: 20px;

                        font-weight: 500;

                        .material-symbols-outlined {
                            position: relative;
                            top: 6px;
                            margin-right: 5px;

                            color: var(--valid-color);


                            font-variation-settings:
                            'FILL' 0,
                            'wght' 400,
                            'GRAD' 0,
                            'opsz' 48;

                        }
                    }

                    &__row {
                        display: flex;
                        justify-content: space-between;

                        border-bottom: 1px solid var(--spacer-color);
                        
                        padding-right: 5px;
                        padding-left: 0px;

                        padding-bottom: 3px;
                        margin-bottom: 10px;


                        .colored {
                            color: var(--valid-color);
                            font-weight: 600;
                            font-size: 16px;
                        }

                        &__headline {
                            display: flex;

                        }

                    }
                }

                &__spacer {
                    margin-top: 30px;
                }

                &__notification {

                    @media only screen and (max-width: 950px)  {
                        margin-top: 40px;
                    }
                
                    @media only screen and (min-width: 951px)  {
                        margin-left: 5%;
                        margin-right: 5%;
                    }

                    line-height: 20px;
                    color: #3a3a3a;

                    margin-top: 25px;
                    margin-bottom: 25px;

                    b {
                        font-weight: 500;
                    }
                }
            }


        }

        &__buttons {


            @media only screen and (min-width: 951px)  {
                display: flex;
                justify-content: space-between;
                padding: 20px 5% 0px 5%;
                border-top: 1px solid var(--spacer-color);
            }

            margin-left: 2.5%;
            margin-right: 2.5%;


            button {


                @media only screen and (max-width: 950px)  {
                    width: 100%;
                    padding: 12px 0px 7px 0px;
                    margin-top: 20px;
                }
            
                @media only screen and (min-width: 951px)  {
                    padding: 10px 14px 5px 20px;
                }

                border-radius: var(--panel-radius);
                border: 2px solid var(--highlited-color);
                display: flex;
                justify-content: center;
                cursor: pointer;

                font-size: 16px;

                white-space: nowrap;

                font-weight: 500;
                background-color: var(--yellow-color);
                border-color: var(--yellow-color);

                &:hover {
                    background-color: var(--yellow-hover-color);
                    border-color: var(--yellow-hover-color);
                }

                &:active {
                    transform: scale(0.98);
                }

                .material-symbols-outlined {
                    font-variation-settings:
                    'FILL' 0,
                    'wght' 400,
                    'GRAD' 0,
                    'opsz' 48;

                    position: relative;
                    top: -2px;
                    left: -3px;
                    margin-right: 8px;
                }
            }
        }

        &__img-wrapper {
            // display: flex;
            // justify-content: center;

            img {
                border-radius: 10px;
                width: 200px;
            }
        }
        
    }
}