.data-panel {


    &__all-notes {


        &__container {
            position: relative;
        }

        &__downloads {
            margin-bottom: 0px;
            
            &__headline {
                margin: 30px 2.5% 25px 2.5%;

                font-weight: 600;
                font-size: 18px;

                .material-symbols-outlined {
                    

                    font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 48;
                    position: relative;
        

                    transition: top 0.3s ease-in-out, transform 0.3s ease-in-out;


                    &.inactive {
                        top: 0px;
                        transform: rotate(-90deg);
                    }

                    &.active {
                        top: 12px;
                        transform: rotate(90deg);
                    }

                    margin-right: 10px;

                    cursor: pointer;
                }

            }

            &__data {
                margin: 0px 2.5%;

                &__row {
                    justify-content: space-between;
                    padding-top: 10px;
                    padding-bottom: 10px;

                    color: #c0c0c0;

                    &--mobile-hidden {
                        display: none;
                    }

                    display: flex;
                   
                    border-right: 1px solid var(--spacer-color);
                    border-left: 2px solid #c0c0c0;
                   
                    
                    &__description {
                        // color: #3a3a3a;
                        
                        line-height: 22px;

                        padding-right: 12.5%;
                        padding-left: 15px;

                        width: 100%;

                    }

                    &__button {
                        display: flex;
                        justify-content: center;

                        white-space: nowrap;
                                              
                        margin-top: auto;
                        margin-bottom: auto;

                        font-weight: 600;

                        @media only screen and (max-width: 950px)  {
                            margin-right: 30px;
                        }
                        
                        @media only screen and (min-width: 951px)  {
                            margin-right: 4.5%;
                        }

                        cursor: pointer;
        
                        -webkit-user-select: none; /* Safari */        
                        -moz-user-select: none; /* Firefox */
                        -ms-user-select: none; /* IE10+/Edge */
                        user-select: none; /* Standard */

                        &:hover {
                            // border-color: #2c8894;
                        }
        
                        &:active {
                            // transform: scale(0.98);
                        }

                        .material-symbols-outlined {
                            position: relative;
                            bottom: 4px;
                            left: 15px;                            
                            font-weight: 600;

                            // color: var(--highlited-color);

                            color: #c0c0c0;

                            font-variation-settings:
                            'FILL' 0,
                            'wght' 500,
                            'GRAD' 0,
                            'opsz' 48;
                        }
                    }
                }

                &__spacer {
                    margin: 10px 2.5% 10px 5%;
                    border-bottom: 1px solid var(--spacer-color);
                }
            }
        }
    }
}