.skeleton__text {
    width: 100%;
    height: 1rem;
    margin-bottom: 11px;
    &.large {
      height: 40px;
    }
}

.skeleton {
    background-color: #eee;
    border-radius: 10px;
    color: #eee;
    animation: pulse 1s infinite ease-in-out;
}

.skeleton-chapter-navigation-lesson {
    height: 27px;
}

.skeleton__text--narrow {
    width: 20%;
}

.skeleton__text--medium {
    width: 35%;
}

.skeleton__text--wide {
    width: 97%;
}
  
.skeleton__video {
    width: 95%;
    height: 10px;
    border-radius: 10px;
}