.data-panel {

    &__empty-user-panel {

        &__description {
            display: flex;
            padding-top: 40px;
            margin-bottom: 50px;

            h4 {
                margin: 0px;
                margin-left: 2.5%;

                font-size: 18px;
                font-weight: 600;

                position: relative;

                &::before{
                    content: '';
                    position: absolute;
                    left: 0;
                    bottom: -10px;
                    background-color: var(--highlited-color);
                    height: 2px;
                    box-sizing: border-box;
                    width: 20%;
                }

                span {
                    color: var(--highlited-color);
                }
            }
        }

        &__wrapper {

            @media only screen and (max-width: 1019px) {
                margin: 0px 3% 0px 3%;
            }

            @media only screen and (min-width: 951px) {
                display: flex;

                margin-top: 20px;
            }

            @media only screen and (max-width: 950px) {
                margin-top: 60px;
            }
        }

        &__courses {
            width: min-content;
            position: relative;

            @media only screen and (max-width: 1019px) and (min-width: 993px) {
                margin-top: 5px;
            }

            @media only screen and (max-width: 992px) {
                margin-top: 15px;
            }

            @media only screen and (max-width: 950px) {
                margin-top: 50px;

                margin-left: calc(50% - 0.5*300px);
                margin-right: calc(50% - 0.5*300px);

                &__text {
                    margin-bottom: 20px !important;
                }
            }
            
            @media only screen and (min-width: 951px) {
                margin-left: 2.5%;
                padding-right: 20px;

                &::before{
                    content: '';
                    position: absolute;
                    right: 0;
                    bottom: 20px;
                    background-color: var(--spacer-color);
                    height: calc(100% - 40px);
                    box-sizing: border-box;
                    width: 1px;
                }
            }

            &--desktop {
                @media only screen and (max-width: 950px) {
                    display: none;
                }
            }

            &--mobile {
                @media only screen and (min-width: 951px) {
                    display: none;
                }
            }

            &__course {
                display: flex;

                margin-bottom: 15px;
                border-radius: calc(var(--panel-radius)*1.5);
                background-color: #0d0d2b;

                -webkit-box-shadow: 8px 8px 24px -16px rgba(66, 68, 90, 1);
                -moz-box-shadow: 8px 8px 24px -16px rgba(66, 68, 90, 1);
                box-shadow: 8px 8px 24px -16px rgba(66, 68, 90, 1);

                cursor: pointer;
        
                -webkit-user-select: none; /* Safari */        
                -moz-user-select: none; /* Firefox */
                -ms-user-select: none; /* IE10+/Edge */
                user-select: none; /* Standard */
        
                &:active {
                    transform: scale(0.98);
                }

                @media only screen and (min-width: 1090px)  {
                    width: 334px;
                    img {
                        margin: 5px 0px 0px 10px;
                        max-width: 120px;
                    }
                }

                @media only screen and (min-width: 989px) and (max-width: 1089px)  {
                    width: 300px;
                    img {
                        margin: 5px 0px 0px 10px;
                        max-width: 100px;
                    }
                }

                @media only screen and (min-width: 1020px) and (max-width: 990px) {
                    width: 300px;
                    img {
                        margin: 5px 0px 0px 10px;
                        max-width: 100px;
                    }
                }

                @media only screen and (min-width: 951px) and (max-width: 1019px) {
                    width: min-content;
                    &__img {
                        padding: 5px 5px 5px 5px;

                        img {
                            margin: 0px;
                            max-width: 100px;
                        }
                    }
                }

                @media only screen and (max-width: 950px)  {
                    width: 300px;
                    img {
                        margin: 5px 0px 0px 10px;
                        max-width: 100px;
                    }
                }


                &--bottom {
                    margin-bottom: 0px !important;
                }

                &--additional {
                    margin-top: 15px;

                    @media only screen and (min-width: 1090px) {
                        display: none;
                    }

                    @media only screen and (max-width: 1019px) {
                        display: none;
                    }
                }

                &__img {    
                    object-fit: contain;
                    height: auto;
                }

                &__wrapper {
                    width: 100%;
                    display: flex;
                    justify-content: left;

                    &__text {
                        margin: auto 0px auto 30px;

                        @media only screen and (min-width: 1090px)  {
                            margin: auto 0px auto 30px;
                        }
        
                        @media only screen and (min-width: 989px) and (max-width: 1089px)  {
                            margin: auto 0px auto 20px;
                        }
        
                        @media only screen and (min-width: 1020px) and (max-width: 990px) {
                            margin: auto 0px auto 20px;
                        }

                        @media only screen and (min-width: 951px) and (max-width: 1019px) {
                            display: none;
                        }

                        @media only screen and (max-width: 950px) {
                            margin: auto 0px auto 20px;
                        }
    
                        &__element {
                            position: relative;
                            display: block;
    
                            color: #ffffff;
                            font-size: 22px;
                            font-weight: 600;
    
                            &--first {
                                margin-bottom: 5px;
                            }
    
                            &--second {
    
                            }
                        }
                    }
                }
                
            }

            &__text {
                display: flex;
                justify-content: center;

                font-size: 18px;
                margin-bottom: 15px;

                span {
                    display: block;
                    font-weight: 600;
                }

                @media only screen and (min-width: 951px) and (max-width: 1019px) {
                    display: none;
                }
            }
        }

        &__offer {

            &__wrapper {

                &__headline {
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 22px;
                    
                    margin: 0px 20px 0px 20px;
                }
    
                &__text {
                    margin: 20px 20px 20px 20px;    
                    color: #3a3a3a;
                    line-height: 22px;
                }
    
                &__button-wrapper {
                    margin: 0px 20px 0px 20px;
                    padding: 30px 0px 30px 0px;
                    border-bottom: 1px solid var(--spacer-color);
                    border-top: 1px solid var(--spacer-color);

                    display: flex;
                    justify-content: center;
    
                    &__button {
                        border-radius: var(--panel-radius);
                        padding: 13px 23px 13px 23px;
    
                        --yellow-button: #ffca43;
                        border: 2px solid var(--yellow-button);
                        background-color: var(--yellow-button);
    
                        font-weight: 500;

                        cursor: pointer;
        
                        -webkit-user-select: none; /* Safari */        
                        -moz-user-select: none; /* Firefox */
                        -ms-user-select: none; /* IE10+/Edge */
                        user-select: none; /* Standard */
        
                        &:hover {
                            background-color: #fac130 !important;
                            border-color: #fac130 !important;
                        }
        
                        &:active {
                            transform: scale(0.98);
                        }
                    }
                }
            }

            &__pros {
                margin: 20px 20px 0px 20px;

                &__headline {
                    font-weight: 600;
                    margin-bottom: 20px;
                    display: block;
                }

                &__element {
                    display: flex;
                    margin: 14px 0px;

                    &--last {
                        margin-bottom: 0px !important;
                    }

                    &__dot {
                        height: 6px;
                        width: 6px;

                        min-width: 6px;
                        min-height: 6px;

                        border-radius: 50px;
                        border: 3px solid #ffca43;

                        position: relative;
                        top: 2px;

                        margin-right: 10px;
                    }
                }
            }

        }
    }
}