.course-panel {
    &__blank-panel {

        box-shadow: none !important;
        border: 0px !important;

        @media only screen and (max-width: 990px) {
            display: none;
        }

        &__filler {
            color: #ffff !important;
        
            -webkit-user-select: none; /* Safari */
            -ms-user-select: none; /* IE 10 and IE 11 */
            user-select: none; /* Standard syntax */

            cursor: default;
        }
    }
}