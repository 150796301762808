.nav {
    display: block;
    width: 100%;
    z-index: 4;

    &--not-scrollable {
        position: relative;
        margin-bottom: 0px;
    }

    &--scrollable {
        position:fixed;
        transition: transform 450ms ease-in-out;
        top: 0;
        margin-bottom: 20px;
    }

    &__main {

        display: flex;
        justify-content: space-between;
    
        height: 71px;

        border-bottom: 1px solid var(--spacer-color);

    }


    &__promo {

        width: 100%; 
        overflow: hidden;

        background-color: #1a1a21;
        border-bottom: 2px solid #36363e;


        height: 38px;

        &__left-side {
                      

            @media only screen and (max-width: 950px)  {
                width: 100%;
            }
    
            @media only screen and (min-width: 951px)  {
                width: calc(100% - 275px);
            }

            color: #ffff;
            position: relative;

            b {
               
                font-weight: 500;
            }

            .yellow-bold {
                color: #ffbe1a;
            }

            .white-bold {
                color: #ffbe1a;
                padding-left: 30px;
                padding-right: 30px;

                opacity: 0.4;
            }

            &__scroll-text {

                text-wrap: nowrap;


                margin-top: 6px;
                position: absolute;
                z-index: 1;

                width: 100%;
                display: inline-block;

                @media only screen and (max-width: 950px)  {
                    animation: scroll-horizontal 18s linear infinite;
                }
        
                @media only screen and (min-width: 951px)  {
                    animation: scroll-horizontal 27s linear infinite;
                }

            }
    
            @keyframes scroll-horizontal {
                from {
                  transform: translateX(100%);
                }
                to {
                  transform: translateX(-100%);
                }
            }
        }

        &__right-side {
            position: absolute;

          

            @media only screen and (max-width: 950px)  {

            }
    
            @media only screen and (min-width: 951px)  {
                width: 275px;
            }

            right: 0px;

            z-index: 2;

            height: 38px;

            background-color: #1a1a21;

            border-left: 2px solid #ffff;
            
            display: flex;
            justify-content: center;



            button {

                @media only screen and (max-width: 950px)  {
                    // margin-left: 10px;
                }
        
                @media only screen and (min-width: 951px)  {
                    margin-left: 40px;
                    margin-right: 20px;
                }


                margin-top: 5px;

                padding: 5px 15px 6px 15px;
                border: 0px;

                background-color: #1a1a21;

                color: #ffff;
                font-size: 16px;

                cursor: pointer;

                display: flex;

                span {
                
                    position: relative;

                    @media only screen and (max-width: 950px)  {
                        display: none;
                    }
            
                    @media only screen and (min-width: 951px)  {
                    
                    }
                }

                svg {
                    margin-left: 10px;
                    width: 20px;
                    height: 20px;
                    fill: #ffff;
                }
            }
        }
        

    }


    &__sub-header {

        color: #ffff;



        font-weight: 500;
        user-select: none;



        justify-content: space-between;
        display: flex;

        &--blue-baner {
            background-color: #2a818d;

            border-top: 2px solid #669ea5;
            border-bottom: 2px solid #21707a;
        }

        &--green-baner {
            background-color: #4a9f35;
            border-bottom: 2px solid #3a852d;
        }

        display: flex;
        justify-content: center;

        span {
            font-size: 16px;
            font-weight: 400;
        }


        div {

            a {
                position: relative;
                top: 4px;

                margin-right: 18px;


                .facebook {
                    transform: scale(1.1) !important;
                    top: -1px;
                }

                svg {
                    width: 16px;
                    height: 16px;

                    transform: scale(1.2);

                    fill: #ffff;
                }
            }
        }

        @media only screen and (max-width: 950px)  {
            padding: 4px 0px 6px 10px;
        }

        @media only screen and (min-width: 951px)  {
            padding: 1px 0px 3px 0px;
        }


        &--inactive {
            display: none;
        }

        &__close {
            position: relative;
            top: 6px;
            margin-right: 17px;

            cursor: pointer;

            svg {
                position: relative;
                top: -2px;           

                opacity: 0.5;
                fill: #3a3a3a;

                @media only screen and (max-width: 950px)  {
                    display: none;
                }
            }

        }
    }


    width: 100%;
    background-color: rgb(255, 255, 255);

    --offset: 0px;

    &--notifications-disabled {
        --offset: 0px;
    }

    &--notifications-enabled {
        --offset: 32px;
    }

    &--hidden {
        transform: translateY(calc(-100% + var(--offset)));        
    }

    &__logo-wrapper {
        width: 182px;
        cursor: pointer;

    }

    .rolledUserMenu {
        @media only screen and (min-width: 951px)  {
            display: none;
        }
    }

    &__buttons {

        display: flex;

        &__button {
            @media only screen and (max-width: 950px)  {
                display: none;
            }

            border-radius: var(--panel-radius);
            margin: 15px 0px;
            margin-right: 20px;
            padding: 10px 20px;
            border: 2px solid var(--yellow-color);

            font-weight: 500;
            cursor: pointer;
            -webkit-user-select: none;
            user-select: none;

            &--left {
                // color: var(--yellow-color);

                transition: background-color 0.3s ease;
  
                -webkit-transition: color 0.4s; /* For Safari 3.0 to 6.0 */
                transition: color 0.4s; /* For modern browsers */

                border: 2px solid #1a1a21;

                &:hover {
                    background-color: #ffbe1a;
                    border-color: #ffbe1a;
                    
                    color: #1a1a21;
                }

                &:active {
                    transform: scale(0.98);
                }
            }

            &--right {
                background-color: var(--yellow-color);
            }
        }
    }

    &__burger-landing {
        border-left: 1px solid var(--spacer-color);
        padding-left: 20px;
        padding-right: 35px;
        width: 40px;

        @media only screen and (min-width: 951px)  {
            display: none;
        }

    }
}
