@import "../../../components/shared/components/fallbacks/skeleton/skeleton-mixin";

.chapter__name {
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    padding: 15px 0px;

    div {
        &.skeleton {
            display: inline-block;
            height: 22px;
            width: 30%;

        }
    }

    span {
        &.skeleton {
            display: inline-block;
            height: 16px;
            width: 50%;

            position: relative;
            top: 3px;
        }
    }

    &--with-icon {
        display: flex;
        justify-content: center;

        padding-bottom: 12px;

        .material-symbols-outlined {
            position: relative;
            bottom: 3px;
            margin-left: 20px;

            transform: scale(1.1);
        }
    }

    &--username {
        margin-bottom: 25px;
        padding: 0px;

        &__icon {
            z-index: 2;

            width: min-content;
            margin-left: auto;
            margin-right: auto;
            padding-bottom: 0px;

            position: relative;

            &--img, svg-highlited {
                img, svg {
                    background-color: #ffffff;
                
                    -webkit-box-shadow: 8px 8px 24px -14px rgba(66, 68, 90, 1);
                    -moz-box-shadow: 8px 8px 24px -14px rgba(66, 68, 90, 1);
                    box-shadow: 8px 8px 24px -14px rgba(66, 68, 90, 1);
                }
            }

            &--priority-layer {
                z-index: 4 !important;
            }

            &--01 {
                img, svg {
                    border-color: var(--highlited-color) !important;
                }
            }
            &--02 {
                img, svg {
                    border-color: #a8971d !important;
                }
            }
            &--03 {
                img, svg {
                    border-color: #c4508c !important;
                }
            }
            &--04 {
                img, svg {
                    border-color: #661e43 !important;
                }
            }
            &--05 {
                img, svg {
                    border-color: var(--highlited-color) !important;
                }
            }
            &--06 {
                img, svg {
                    border-color: #71224b !important;
                }
            }
            &--07 {
                img, svg {
                    border-color: #c4508c !important;
                }
            }
            &--08 {
                img, svg {
                    border-color:  #b8a723 !important;
                }
            }

            img, svg {
                height: 108px;
                width: 104px;

                border-radius: 50%;
                padding: 2px;
                border: 2px solid;

                margin: 20px;
            }

            &__change-icon {
                user-select: none;
                -moz-user-select: none;
                -khtml-user-select: none;
                -webkit-user-select: none;
                -o-user-select: none;

                padding: 2px;
                position: absolute;
                border-radius: 90%;
                background-color: #ffffff;
            
                bottom: 24px;
                right: 21px;

                cursor: pointer;

                .material-symbols-outlined {
                    display: block;

                    // color: #4c4502;
                    color: #ffffff;
                    background-color: #ffca43;
                    border-radius: 90%;
                    padding: 3px;

                    font-variation-settings:
                    'FILL' 0,
                    'wght' 400,
                    'GRAD' 0,
                    'opsz' 40;
                }

                &:hover {
                    .material-symbols-outlined {
                        background-color: #fac130;
                    }
                }

                &:active {
                    transform: scale(0.98);
                }
            }
        }            
    }

    &--clickable {
        cursor: pointer;
    }

}

.skeleton--circle {
    width: 120%;
    height: 120%;
    border-radius: 100% !important;
}