.checkout-fallback {
    // height: 520px !important;

    &__headline {
        display: flex;
        
        h4:first-of-type {
            margin-right: 15px;
        }
    }

    &__text {
        border-top: 1px solid var(--spacer-color);
        border-bottom: 1px solid var(--spacer-color);

        padding-top: 15px;
        padding-bottom: 5px;
        
        margin-bottom: 20px;
    }

    &__payment-boxes {
        display: flex;
        justify-content: space-between;

        &__box {
            width: calc(31.5% - 60px);

            border: 1px solid var(--spacer-color);
            border-radius: var(--panel-radius);

            padding-top: 35px;
            padding-bottom: 5px;

            padding-left: 10px;
            padding-right: 50px;
        }
    }

    &__payment-input {
        margin-top: 30px;

        .skeleton {
            width: 30%;
            margin-bottom: 10px;
        }

        &__box {
            width: 100%;
            border-radius: 5px;
            height: 45px;

            border: 1px solid var(--spacer-color);
        }
    }

    &__submit-button-wrapper {
        margin-top: 30px;
        padding-top: 20px;
        border-top: 1px solid var(--spacer-color);

        &__button {
            width: 90%;
            border-radius: 5px;

            padding-top: 14px;
            padding-bottom: 2px;
            // padding-bottom: 12px;

            border: 1px solid var(--spacer-color);

            margin-left: 5%;
            margin-right: 5%;

            .skeleton {
                
                margin-left: 20%;
                width: 60% !important;
            }
        }
    }
}