.main-page {


    &__intro-message {

        margin-bottom: 60px;

        @media only screen and (max-width: 950px)  {
            display: none;
        }

        &__text-main {
            width: 100%;
            
            span {
                display: block;
                text-align: center;
            }

            &__main {
                color: rgb(26, 26, 33);;

                font-weight: 500;

                b {
                    font-weight: 500;

                    @media only screen and (max-width: 950px)  {
                        font-size: 22px;
                    }
                
                    @media only screen and (min-width: 951px)  {
                        font-size: 26px;
                    }
                }

                @media only screen and (max-width: 950px)  {
                    font-size: 32px;
                }
            
                @media only screen and (min-width: 951px)  {
                    font-size: 42px;        
                }
            }

        }

        &__text-bottom {
            display: flex;
            justify-content: center;


            span {
                max-width: 700px;
                display: block;
                text-align: center;
                
                padding-top: 20px;
                max-width: 650px;

                @media only screen and (max-width: 950px)  {
                    font-size: 18px;
                    line-height: 24px;
                }
            
                @media only screen and (min-width: 951px)  {
                    font-size: 20px;
                    line-height: 28px;
                }
            }

            @media only screen and (max-width: 950px)  {
                margin-top: 5px;
                margin-left: 5%;
                margin-right: 5%; 
            }
        
            @media only screen and (min-width: 951px)  {
                margin-top: 5px;      
                width: 100%;
            }
        }

    }

    &__new-feature-section {

        @media only screen and (max-width: 950px)  {
            display: none;
        }

        margin-left: calc(var(--main-panel-margin) + 5%);
        margin-right: calc(var(--main-panel-margin) + 5%);
    

        margin-bottom: 140px;

        
        &__navigation {

            margin-left: auto;
            margin-right: auto;
                            
            @media only screen and (min-width: 951px)  {
                max-width: 1195px;
            }

            display: grid;

            grid-template-columns: 1fr 1fr 1fr;
            grid-template-rows: 1fr 1fr;
            gap: 15px;


            &__panel {

                cursor: pointer;
                display: flex;

                border-radius: 10px;

                font-weight: 500;
                font-size: 18px;
                
                -webkit-box-shadow: 8px 8px 24px -24px rgba(66, 68, 90, 1);
                -moz-box-shadow: 8px 8px 24px -24px rgba(66, 68, 90, 1);
                box-shadow: 8px 8px 24px -24px rgba(66, 68, 90, 1);

                padding: 24px;

                &--active {
                    border: 1px solid #202020;
                    background-color: var(--yellow-color);
                }

                &--inactive {
                    border: 1px solid var(--spacer-color);
                    background-color: #ffff;
                }

                svg {
                    width: 25px;
                    height: 25px;
                    margin-right: 15px;

                    fill: #1a1a1a;
                }

                span {
                    display: block;
                    top: 3px;
                    position: relative;
                }
                
            }
        }

        &__data {
            margin-top: 75px;
            position: relative;

            display: flex;


            @media only screen and (max-width: 1584px)  {

            }

            @media only screen and (min-width: 1585px)  {
                max-width: 1321px;

                margin-left: auto;
                margin-right: auto;
            }

        
            &__left {
                overflow: hidden !important;
                width: 55%;
                background-color: rgba(215, 232, 234, 0.528);
                border-radius: var(--panel-radius);

                position: relative;
                
                svg {

                    position: absolute;
                    
                }
    

                .shadowless {

                }

                .shadowed {
                    box-shadow: 8px 8px 24px -13px #42445a;
                }

                img {

                    margin-left: 6%;
                    margin-right: 6%;

                    margin-top: 35px;
                    margin-bottom: 35px;

                    width: 88%;

                    border-radius: 20px;
                    height: auto;
                    position: relative;
                    top: 0;
      
     
                }
            }

            &__right {
                margin-left: 50px;
                width: calc(45% - 50px);

                h2 {
                    font-size: 40px;
                    line-height: 47px;
                    font-weight: 500;

                    margin-bottom: 30px;
                }

                span {
                    font-size: 20px;

                    line-height: 28px;
                }

                &__features {
                    display: flex;
                    margin-top: 40px;

                    --sub-color: #6b6b6b;
                    color: var(--sub-color);

                    &__left {

                        width: 50%;

                        margin-right: 20px;
                    }

                    &__right {

                        width: 50%;


                    }

                    &__left, &__right {

                        svg {
                            position: relative;
                            left: -3px;

                            width: 24px;
                            height: 24px;
                            margin-bottom: 4px;

                            // fill: var(--sub-color);
                        }

                        &__headline {
                            font-weight: 450;

                            span {
                                display: block;
                                font-size: 20px !important;
                            }

                            color: #1a1a1a;

                            margin-bottom: 13px;
                        }

                        span {
                            font-size: 18px;
                        }

                    }


                }
            }

        }


    }
}