.chapter__data-space__course-icon-change {
    margin-left: auto;
    margin-right: auto;
    height: 320px;
    width: 90%;

    position: relative;
    
    &__overlay {
        position: fixed;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 3;

        background-color: rgba(0, 0, 0, 0.405);
        cursor: default;

        backdrop-filter: blur(2px);
    }
    
    &__panel {

        z-index: 4;
        position: absolute;
        width: 100%;
        background-color: #ffffff;

        @media only screen and (max-width: 950px)  {
            height: auto;
            position: relative !important;
            top: -35px;

            &__icons {
                top: -13px !important;
            }
        }

        @media only screen and (min-width: 951px) and (max-width: 1197px) {
            height: 288px;
            bottom: 75px;
        }

        @media only screen and (min-width: 1198px) {
            height: 364px;
            bottom: 0px;
        }

        border-radius: var(--panel-radius);

        -webkit-box-shadow: 8px 8px 24px -14px rgba(66, 68, 90, 1);
        -moz-box-shadow: 8px 8px 24px -14px rgba(66, 68, 90, 1);
        box-shadow: 8px 8px 24px -14px rgba(66, 68, 90, 1);

        &__triangle {
            --height: 30px;
            --width: 60px;
            position: relative;
            bottom: calc(var(--height) - 1px);
            margin-left: auto;
            margin-right: auto;

            width: 0; 
            height: 0; 
            border-left: var(--width) solid transparent;
            border-right: var(--width) solid transparent;
            
            border-bottom: var(--height) solid rgb(255, 255, 255);
        }

        &__icons {
            position: relative;
            top: -22px;

            padding: 0px 5px;
            display: grid;
            grid-template-columns: repeat(3, 1fr);

            &__icon {

                overflow: hidden;

                border-radius: 15px !important;

                width: auto;
                padding: 3px;
                cursor: pointer;

                &:hover {
                    svg {
                        opacity: 1.0;
                    }
                }


                svg {
                    border-radius: var(--panel-radius);
                    opacity: 0.70;                    
                }

                &--active {
                    border: 3px solid #ffca43;
                    height: calc(100% - 10px);
                    svg {

                        opacity: 1.0 !important;
                        width: calc(100% + 1px);
                    }

                    padding: 0px;
                    margin: 0px;

                }
            }

            &__escape {
                width: auto;
                height: calc(100% - 14px);
                margin: 3px;
                padding: 0px;

                border: 2px solid var(--spacer-color);
                border-radius: var(--panel-radius);

                display: flex;

                cursor: pointer;

                .material-symbols-outlined {
                    display: block;
                    margin: auto auto;

                    color: #d0d0d0;

                    font-variation-settings:
                    'FILL' 0,
                    'wght' 200,
                    'GRAD' 0,
                    'opsz' 48;

                    transform: scale(1.4);
                }

                &:hover {
                    background-color: var(--spacer-color);
                    border-color: #d0d0d0;

                    .material-symbols-outlined {
                        color: #b6b6b6;
                    }
                }

                &:active {
                    transform: scale(0.98);
                }
            }

        }
    }
}