.main-page {

    &__discord-invitation {
        
     



        @media only screen and (max-width: 950px)  {
            margin-left: 5%;
            margin-right: 5%;

            margin-bottom: 75px;
        }
     
        @media only screen and (min-width: 951px)  {
            display: flex;     
            margin-left: calc( var(--main-panel-margin) + 5% );
            margin-right: calc( var(--main-panel-margin) + 5% );
            margin-top: 50px;

            margin-bottom: 150px;
        }


        &__left-section {
            background-color: #5a65ea;

            border-bottom: 2px solid rgb(68, 79, 199);

            width: min-content;
            border-radius: 10px;

            -webkit-box-shadow: 8px 8px 24px -13px rgba(66, 68, 90, 1);
            -moz-box-shadow: 8px 8px 24px -13px rgba(66, 68, 90, 1);
            box-shadow: 8px 8px 24px -13px rgba(66, 68, 90, 1);


        

           
            @media only screen and (max-width: 950px)  {
                padding: 10px 12px 5px 12px;
                margin-bottom: 10px;

                margin-right: 15px;
            }
         
            @media only screen and (min-width: 951px)  {
                padding: 10px 15px 8px 15px;
            }

            &.mobile-hidden {
                @media only screen and (max-width: 950px)  {
                    display: none;
                }
            }

            &.desktop-hidden {
                @media only screen and (min-width: 951px)  {
                    display: none;
                }
            }


                svg {

                    position: relative;

                    @media only screen and (max-width: 950px)  {
                        width: 30px;
                        height: 30px;
                        top: 1px;

                    }
                 
                    @media only screen and (min-width: 951px)  {
                        width: 40px;
                        height: 40px;
                        top: 3px;

                    }
                }
            
        }

        &__right-section {

            @media only screen and (max-width: 950px)  {
                padding: 15px 15px 20px 15px;
            }
         
            @media only screen and (min-width: 951px)  {
                margin-left: 20px; 
                display: flex;
                width: 100%;
            }
    

            -webkit-box-shadow: 8px 8px 24px -13px rgba(66, 68, 90, 1);
            -moz-box-shadow: 8px 8px 24px -13px rgba(66, 68, 90, 1);
            box-shadow: 8px 8px 24px -13px rgba(66, 68, 90, 1);

            border-radius: 10px;
            border-bottom: 2px solid #2a2b30;


          
            // background-color: #1e1f22;
        
            background-color: #313338;

            overflow: hidden;


            &__icon {
                color: #ffff;

                font-weight: 500;        

                span {
                    display: block;
                    margin-top: 5px;
                    margin-right: 10px;
                }

                @media only screen and (max-width: 950px)  {
                    display: flex;
                }

                &__hash {

                    font-size: 45px;

                    @media only screen and (max-width: 950px)  {
                        display: none !important;
                    }
                    
                    @media only screen and (min-width: 951px)  {
    
                    }
                }

                &__text {

                    font-size: 19px;
                    margin-bottom: 3px;
                    position: relative;

                    top: -2px;

                    b {
                        font-size: 16px;
                        color: #2e9540;
                        font-weight: 400;
                    }

                    @media only screen and (max-width: 950px)  {
                  
                    }
                    
                    @media only screen and (min-width: 951px)  {
                        display: none !important;
                    }
                }
            }

            &__separator {
                background-color: #ffff;

                margin-top: 8px;
                margin-bottom: 8px;

                width: 7px;

                border-left: 1px solid #e1e1e1;

                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;
            
                margin-right: 30px;
            }

            &__text { 
                color: #ffff;

                margin-top: auto;
                margin-bottom: auto;

                span {
                    display: block;
                }

                .text-1 {
                    font-size: 19px;
                    margin-bottom: 3px;

                    @media only screen and (max-width: 950px)  {
                        display: none !important;
                    }
                    
                    @media only screen and (min-width: 951px)  {
    
                    }

                    b {
                        font-size: 16px;
                        color: #2e9540;
                        font-weight: 400;
                    }
                }

                .text-2 {

                    color: #c3c8ce;
                }
            }
            
            &__button {
                margin-top: 16px;

                a {
                    text-decoration: none;
                    margin-right: 0px;
                    margin-left: auto;
    
                    white-space: nowrap;
    
                    margin-top: 16px;
                    color: #ffff;
                    font-size: 18px;
    
                    margin-right: 30px;
                    background-color: #2e9540;
    
                    cursor: pointer;
    
                    &:hover {
                        background-color: #237c32;
                    }
    
                    border: 0px solid;
    
                    height: 31px;
    
                    border-radius: 5px;
    
                    padding: 5px 8px 5px 8px;
    
                    
                }
            }

        }
    }


}