.main-page { 
    &__bundle-table-of-contents {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-bottom: 35px;

        &__course-card {
            display: flex;
            flex-direction: column;
            background-color: #ffff;
            border: 1px solid var(--spacer-color);
            border-radius: var(--panel-radius);
            overflow: hidden;
            -webkit-box-shadow: 8px 8px 24px -20px rgba(66, 68, 90, 1);
            -moz-box-shadow: 8px 8px 24px -20px rgba(66, 68, 90, 1);
            box-shadow: 8px 8px 24px -20px rgba(66, 68, 90, 1);
            position: relative;
            cursor: pointer;

            &__main-container {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
            }

            @media only screen and (min-width: 951px) {
                width: 88%;
            }

            --image-size: 70px;
            &__image {
                width: var(--image-size);
                min-width: var(--image-size);
                height: var(--image-size);
                overflow: hidden;
                margin-right: 15px;
                padding-right: 5px;
                background-color: var(--dark-background-color);

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: var(--panel-radius) 0 0 0;
                }
            }

            &__content {
                h3 {
                    margin-top: 15px;
                    font-size: 16px;
                    font-weight: 500;
                }
            }

            &__expand-btn {
                margin-left: auto;
                background: none;
                border: none;
                cursor: pointer;
                padding: 8px;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: transform 0.3s ease;

                svg {
                    width: 24px;
                    height: 24px;
                    color: #8f2c5f;
                }

                &.expanded {
                    transform: rotate(180deg);
                }

                &:hover {
                    opacity: 0.8;
                }
            }

            &__chapters {
                width: 100%;
                height: 0;
                overflow: hidden;
                transition: height 0.3s ease;
                margin-top: 0;

                &.expanded {

                }

                &.hidden {

                }

                .content {
                    padding: 15px;
                    border-top: 1px solid var(--spacer-color);
                }
            }

            @media only screen and (max-width: 950px) {
                margin-left: 20px;
                margin-right: 20px;
            }
        }
    }
}